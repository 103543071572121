export const SINGLE_VISION = 'single_vision';
export const MULTIFOCAL = 'progressive';
export const CONTACTS = 'contacts';
export const GLASSES = 'glasses';
export const SPH_MIN_VALUE = -18.00;
export const SPH_MIN_LABEL = '-18.00';
export const SPH_MAX_VALUE = 10.00;
export const SPH_MAX_LABEL = '+10.00';
export const SPH_MAX_OLD_VALUE = 6.00;
export const SPH_MAX_OLD_LABEL = '+6.00';
export const CYL_MIN_VALUE = -6.00;
export const CYL_MIN_LABEL = '-6.00';
export const CYL_MAX_VALUE = 6.00;
export const CYL_MAX_LABEL = '+6.00';
export const COMBINED_SPH_CYL_MIN_VALUE = -18.00;
export const COMBINED_SPH_CYL_MIN_LABEL = '-18.00';
export const COMBINED_SPH_CYL_MAX_VALUE = 10.00;
export const COMBINED_SPH_CYL_MAX_LABEL = '+10.00';
export const COMBINED_SPH_CYL_MAX_OLD_VALUE = 6.00;
export const COMBINED_SPH_CYL_MAX_OLD_LABEL = '+6.00';
