import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, ArrowLeft24Icon } from '@warbyparker/retail-design-system';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ROUTE_ROOT } from '../../routes';
import { formatDate, isSame } from '../../utilities/dates';
import { DATE_FORMAT } from './constants';
import DateSelector from './DateSelector';
import { useAppSelector } from '../../hooks';
import { updateDate } from '../../redux/actions/pmp';
import header from '../../assets/styles/header';
import HeaderDateLabel from './HeaderDateLabel';

const Header = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch(ROUTE_ROOT);
  const history = useHistory();

  const isRootRoute = Boolean(routeMatch && routeMatch.isExact);
  const date = useAppSelector(state => state.pmp?.date) as Date;
  const dateToDisplay = formatDate(date, DATE_FORMAT);
  const formattedToday = formatDate(new Date(), 'yyyy-MM-dd');
  const formattedDate = formatDate(date, 'yyyy-MM-dd');
  const poeRoute = `https://${process.env.POE_DOMAIN || ''}`;

  const isToday = isSame(formattedToday, formattedDate, 'day');
  const canDisplayToday = !isToday && isRootRoute;

  const onNavigateToAppointments = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isRootRoute) return;

    e.preventDefault();
    history.push(ROUTE_ROOT);
  };

  return (
    <header css={header.wrapper}>
      <nav css={header.widthLimiter}>
        <a css={header.link} href={poeRoute} onClick={onNavigateToAppointments}>
          <ArrowLeft24Icon />
        </a>
        {
          isRootRoute
            ? <DateSelector date={date} />
            : <HeaderDateLabel text={dateToDisplay} />}
        <div css={header.buttonWrapper}>
          <Button
            css={header.button({ visible: canDisplayToday })}
            size="small"
            color="clear-smoke"
            onClick={() => dispatch(updateDate(new Date()))}
          >
            Today
          </Button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
