import { contactsRx } from './rxTypes';
import tableUtils from './table';

import { convertPrismBase } from './createRx';
import { multifocalContacts } from './rxTable';

const doubleCheckModalHeader = 'Final prescription review';

const editButtonCopy = 'Back to edit';
const sendButtonCopy = 'Send to Warby Parker';

const validateValue = (val) => {
  if (!val || parseFloat(val) === 0) {
    return true;
  }
  if (isNaN(val)) {
    return false;
  }
};

const rxValueLookUp = (rx, key) => {
  const value = rx[key];
  if (typeof value === 'object' && value !== null) {
    return `${value.prism} ${convertPrismBase(value.base, true)}`;
  }

  if (rx && key === multifocalContacts) {
    const { contacts } = rx;
    return contacts[key];
  }

  return value;
};

const combinedHeaders = (rxType, showPrisms, canDisplayMultifocalContacts) => {
  if (rxType.includes(contactsRx)) {
    return tableUtils.contactsInputHeaders.concat(
      tableUtils.contactsDropdownHeaders(canDisplayMultifocalContacts),
    );
  }
  if (showPrisms) {
    return tableUtils.glassesHeaders.concat(tableUtils.glassesPrismHeaders);
  }
  return tableUtils.glassesHeaders;
};

export default {
  combinedHeaders,
  doubleCheckModalHeader,
  editButtonCopy,
  rxValueLookUp,
  sendButtonCopy,
  validateValue,
};
