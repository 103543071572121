import React, { Fragment } from 'react';
import tableUtils from '../../utilities/table';
import tableStyles from '../../assets/styles/table';
import { DisabledCell, DropdownCell, InputCell } from './Cell';
import { useAppSelector } from '../../hooks';
import { MULTIFOCAL_CONTACTS_FEATURE_FLAG } from '../../utilities/features';
import auth from '../../redux/selectors/AuthSelectors';


export class HeaderColumn extends React.PureComponent {
  render() {
    const { column } = this.props;
    return (
      <th css={tableStyles.thCol}>
        <span css={tableStyles.headerTitle}>{ column.eye }</span>
        <span css={tableStyles.headerLabel}>{ column.label }</span>
      </th>
    );
  }
}

const Inputs = ({ headers, eye, rxState, rxVariants = {}, connectedUpdateRxValues, isContacts = true }) => {
  let singleEyeRx;
  let variants;

  if (rxState) {
    singleEyeRx = eye === tableUtils.OD ? rxState.od : rxState.os;
    variants = eye === tableUtils.OD ? rxVariants.od : rxVariants.os;
    if (!variants && isContacts) {
      return headers.map((header, headerIndex) => <DisabledCell key={headerIndex} />);
    }
  }
  return headers.map(header => (
    <InputCell
      key={`${eye}-${header.headerLabel}`}
      header={header}
      eye={eye}
      singleEyeRx={singleEyeRx}
      connectedUpdateRxValues={connectedUpdateRxValues}
    />
  ));
};


const GlassesInputs = ({ eye, showPrisms, connectedUpdateRxValues, rxState, rxVariants }) => (
  <Fragment>
    <Inputs
      headers={tableUtils.glassesHeaders}
      eye={eye}
      rxState={rxState}
      rxVariants={rxVariants}
      connectedUpdateRxValues={connectedUpdateRxValues}
      isContacts={false}
    />
    {
      showPrisms
      && (
        <Inputs
          headers={tableUtils.glassesPrismHeaders}
          eye={eye}
          rxState={rxState}
          rxVariants={rxVariants}
          connectedUpdateRxValues={connectedUpdateRxValues}
          isContacts={false}
        />
      )
    }
  </Fragment>
);

const ContactsInputs = ({ eye, connectedUpdateRxValues, rxState, rxVariants }) => (
  <Inputs
    headers={tableUtils.contactsInputHeaders}
    eye={eye}
    rxState={rxState}
    rxVariants={rxVariants}
    connectedUpdateRxValues={connectedUpdateRxValues}
  />
);

const ContactsDropdowns = ({ eye, connectedUpdateRxValues, rxVariants, rxState }) => {
  const canDisplayMultifocalContacts = useAppSelector(auth.featureSelector(MULTIFOCAL_CONTACTS_FEATURE_FLAG));
  const variants = eye === tableUtils.OD ? rxVariants.od : rxVariants.os;
  const singleEyeRx = eye === tableUtils.OD ? rxState.od : rxState.os;

  return tableUtils.contactsDropdownHeaders(canDisplayMultifocalContacts)
    .map((header, headerIndex) => (
      <DropdownCell
        key={headerIndex}
        index={headerIndex}
        header={header}
        variants={variants}
        eye={eye}
        singleEyeRx={singleEyeRx}
        connectedUpdateRxValues={connectedUpdateRxValues}
      />
    ));
};

export const Row = ({ column, isContacts, showPrisms, connectedUpdateRxValues, rxVariants, rxState }) => (
  <tr css={tableStyles.trCol} key={`${column.eye}-${column.label}`}>
    <HeaderColumn column={column} />
    {
      !isContacts
        ? (
          <GlassesInputs
            eye={column.eye}
            showPrisms={showPrisms}
            connectedUpdateRxValues={connectedUpdateRxValues}
            rxState={rxState}
            rxVariants={rxVariants}
          />
        ) : (
          <Fragment>
            <ContactsInputs
              eye={column.eye}
              connectedUpdateRxValues={connectedUpdateRxValues}
              rxState={rxState}
              rxVariants={rxVariants}
            />
            <ContactsDropdowns
              eye={column.eye}
              connectedUpdateRxValues={connectedUpdateRxValues}
              rxVariants={rxVariants}
              rxState={rxState}
            />
          </Fragment>
        )
    }
  </tr>
);
