import {
  FETCH_DOCTOR_APPOINTMENTS_PENDING,
  FETCH_DOCTOR_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_APPOINTMENTS_FAIL,
  FETCH_APPOINTMENT_TYPES_PENDING,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  FETCH_APPOINTMENT_TYPES_FAIL,
  GET_DOCTOR_UID_PENDING,
  GET_DOCTOR_UID_SUCCESS,
  GET_DOCTOR_UID_FAIL,
  SAVE_RX_PENDING,
  SAVE_RX_SUCCESS,
  SAVE_RX_FAIL,
  SEND_EMAIL_TO_PATIENT_PENDING,
  SEND_EMAIL_TO_PATIENT_SUCCESS,
  SEND_EMAIL_TO_PATIENT_FAIL,
  SEND_TO_HELIOS_PENDING,
  SEND_TO_HELIOS_SUCCESS,
  SEND_TO_HELIOS_FAIL,
  CLEAR_SESSION,
  ADD_ANOTHER_RX,
  UPDATE_ADD,
  UPDATE_AXIS,
  UPDATE_BASE_CURVE,
  UPDATE_COLOR,
  UPDATE_CYLINDER,
  UPDATE_DIAMETER,
  UPDATE_PRISMS,
  UPDATE_SPHERE,
  UPDATE_PD,
  TOGGLE_PRESCRIPTION_TYPE,
  UPDATE_DATE,
  GENERATE_RX_PAD_PDF_FAIL,
  GENERATE_RX_PAD_PDF_SUCCESS,
  UPDATE_DN,
} from '../action-types';

import {
  getSlotsFromAllAppointments,
  getAppointmentProfileTypes,
  getUID,
} from '../utils/reducer.utils';

const initialState = {
  appointmentTypes: null,
  date: new Date(),
  doctorUID: null,
  error: null,
  facilityCountry: null,
  facilityRegion: null,
  patientList: null,
  pending: false,
  pendingAppointmentTypes: false,
  rxSaved: false,
  rxSaveError: null,
  rxSendToHeliosError: null,
  rxSentToHelios: false,
  rxSendEmailToPatientError: null,
  rxSentEmailToPatient: false,
  rxUID: null,
  printingRx: false,
  savingRx: false,
  sendingRxToHelios: false,
  sendingRxEmailToPatient: false,
};

function pmpReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_DATE:
    return {
      ...state,
      date: payload,
    };
  case FETCH_DOCTOR_APPOINTMENTS_PENDING:
    return {
      ...state,
      pending: true,
    };
  case FETCH_DOCTOR_APPOINTMENTS_SUCCESS:
    return {
      ...state,
      pending: false,
      patientList: getSlotsFromAllAppointments(payload),
    };
  case FETCH_DOCTOR_APPOINTMENTS_FAIL:
    return {
      ...state,
      error: payload,
      pending: false,
    };
  case FETCH_APPOINTMENT_TYPES_PENDING:
    return {
      ...state,
      pendingAppointmentTypes: true,
    };
  case FETCH_APPOINTMENT_TYPES_SUCCESS:
    return {
      ...state,
      facilityCountry: payload.country,
      facilityRegion: payload.state,
      pendingAppointmentTypes: false,
      appointmentTypes: getAppointmentProfileTypes(payload),
    };
  case FETCH_APPOINTMENT_TYPES_FAIL:
    return {
      ...state,
      error: payload,
      pendingAppointmentTypes: false,
    };
  case GET_DOCTOR_UID_PENDING:
    return {
      ...state,
      pending: true,
    };
  case GET_DOCTOR_UID_SUCCESS:
    return {
      ...state,
      doctorUID: getUID(payload),
    };
  case GET_DOCTOR_UID_FAIL:
    return {
      ...state,
      error: payload,
      pending: false,
    };

  case SAVE_RX_PENDING:
    return {
      ...state,
      savingRx: true,
    };
  case SAVE_RX_SUCCESS:
    return {
      ...state,
      savingRx: false,
      rxSaved: true,
      rxUID: getUID(payload),
    };
  case SAVE_RX_FAIL:
    return {
      ...state,
      rxSaveError: payload,
      savingRx: false,
    };
  case GENERATE_RX_PAD_PDF_SUCCESS:
    return {
      ...state,
      printingRx: false,
    };
  case GENERATE_RX_PAD_PDF_FAIL:
    return {
      ...state,
      rxPrintError: payload,
      printingRx: false,
    };
  case SEND_TO_HELIOS_PENDING:
    return {
      ...state,
      sendingRxToHelios: true,
    };
  case SEND_TO_HELIOS_SUCCESS:
    return {
      ...state,
      sendingRxToHelios: false,
      rxSentToHelios: true,
    };
  case SEND_TO_HELIOS_FAIL:
    return {
      ...state,
      rxSendToHeliosError: payload,
      sendingRxToHelios: false,
    };
  case SEND_EMAIL_TO_PATIENT_PENDING:
    return {
      ...state,
      sendingRxEmailToPatient: true,
    };
  case SEND_EMAIL_TO_PATIENT_SUCCESS:
    return {
      ...state,
      sendingRxEmailToPatient: false,
      rxEmailSentToPatient: true,
    };
  case SEND_EMAIL_TO_PATIENT_FAIL:
    return {
      ...state,
      rxSendEmailToPatientError: payload,
      sendingRxEmailToPatient: false,
    };
  case ADD_ANOTHER_RX:
    return {
      ...state,
      rxSaved: false,
      rxSaveError: null,
      rxSendToHeliosError: null,
      rxSentToHelios: false,
      rxUID: null,
      savingRx: false,
      sendingRxToHelios: false,
    };
  case TOGGLE_PRESCRIPTION_TYPE:
  case UPDATE_ADD:
  case UPDATE_AXIS:
  case UPDATE_BASE_CURVE:
  case UPDATE_COLOR:
  case UPDATE_CYLINDER:
  case UPDATE_DIAMETER:
  case UPDATE_DN:
  case UPDATE_PRISMS:
  case UPDATE_SPHERE:
  case UPDATE_PD:
    return {
      ...state,
      rxSaveError: null,
      rxSendToHeliosError: null,
    };
  case CLEAR_SESSION:
    return initialState;
  default:
    return state;
  }
}

export default pmpReducer;
