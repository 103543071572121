export const disclaimerCopy = 'By clicking "Send Prescription" below, I represent and warrant that I have obtained all necessary consent from the above named patient before (1) sending this prescription to the patient via unencrypted email and (2) sharing this prescription with JAND, Inc. d/b/a Warby Parker.';

export const prescriptionNotes = {
  prescriptionNotes: 'Anything else to know about this prescription?',
};

export const OD = 'OD';
export const OS = 'OS';

export const contactsHeaders = [`${OD} - Right Eye`, `${OS} - Left Eye`];

const eyes = {
  od: OD,
  os: OS,
};

export const eyeArray = [eyes.od, eyes.os];

export const pdTypes = {
  monocular: 'Monocular PD',
  binocular: 'Binocular PD',
};

export const biString = 'bi';

export const multifocalContacts = 'dn';

export const rxValueLookup = (rx, key) => {
  if (rx && key === multifocalContacts) {
    const { contacts } = rx;
    return contacts[key];
  }

  return rx[key] || '--';
};
