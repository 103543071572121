import { css } from '@emotion/react';

import COLORS from './colors';

const modalContainer = css`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100vh;
  width: 100vw;
  bottom: 0%;
  overflow: auto;
`;

const modal = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  left: 50%;
  padding: 48px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 460px;

  h1 {
    color: ${COLORS.CHARCOAL};
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  p {
    align-items: center;
    color: ${COLORS.STONE};
    display: flex;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
  }
`;

const imgContainer = css`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const widthSetter = css`
  display: flex;
  width: 360px;
  margin: 0 auto;
`;

export default {
  buttonContainer,
  imgContainer,
  modal,
  modalContainer,
  widthSetter,
};
