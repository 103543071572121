/* eslint-disable no-undef */
import { logger } from '.';

const injectLoggerIntoGlobal = () => {
  if (Reflect.has(globalThis, 'logger')) return;

  Reflect.defineProperty(globalThis, 'logger', {
    configurable: false,
    enumerable: false,
    value: logger,
    writable: false,
  });
};

export { injectLoggerIntoGlobal };
