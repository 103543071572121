import React from 'react';
import { createPortal } from 'react-dom';
import { PropTypes } from 'prop-types';

import confirmationModalStyles from '../assets/styles/confirmationModal';
import checkLarge from '../assets/img/checkLarge.svg';

import Button from '../shared-components/Button';
import PillButton from '../shared-components/PillButton';
import toModalRoot from '../utilities/toModalRoot';

export const InlineConfirmationModal = ({
  connectedAddAnother,
  connectedClearSession,
  patientName,
}) => {
  const ConfirmationModalButtonDone = () => (
    <Button onClick={connectedClearSession}>Done</Button>
  );
  const ConfirmationModalButtonAddAnother = () => (
    <PillButton text="Add another" onClick={() => connectedAddAnother()} />
  );
  return (
    <div css={confirmationModalStyles.modalContainer}>
      <div css={confirmationModalStyles.modal}>
        {/* TODO: Accessibility error */}
        <img css={confirmationModalStyles.imgContainer} src={checkLarge} />
        <h1>Measurement saved</h1>
        <p>{`Reading has been successfully saved for ${patientName}.`}</p>
        <div css={confirmationModalStyles.buttonContainer}>
          <div css={confirmationModalStyles.widthSetter}>
            <ConfirmationModalButtonAddAnother />
            <ConfirmationModalButtonDone />
          </div>
        </div>
      </div>
    </div>
  );
};

InlineConfirmationModal.propTypes = {
  connectedAddAnother: PropTypes.func,
  connectedClearSession: PropTypes.func,
  patientName: PropTypes.string,
};

export default props =>
  createPortal(<InlineConfirmationModal {...props} />, toModalRoot());
