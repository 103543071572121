import React from 'react';
import { PropTypes } from 'prop-types';

import { prismButton } from '../assets/styles/button';
import plus from '../assets/img/plus.svg';

const PrismButton = ({ onClick }) => (
  <button css={prismButton} onClick={onClick}>
    <img src={plus} />
    <span>Prism</span>
  </button>
);

PrismButton.propTypes = {
  onClick: PropTypes.func,
};

export default PrismButton;
