import { createSelector } from 'reselect';
import { getOhmState } from './getStates';


const ohmStateSelector = createSelector(
  getOhmState,
  ohm => ohm,
);

const errorSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.error,
);

const rxVariantsSelector = createSelector(
  ohmStateSelector,
  ohm => ({
    os: ohm.osRxVariants,
    od: ohm.odRxVariants,
  }),
);

const rxVerificationPdMsgsSelector = createSelector(
  ohmStateSelector,
  ohm => ({
    errors: (ohm.errorRxVerification && ohm.errorRxVerification.pdErrors) || null,
    warnings: (ohm.errorRxVerification && ohm.errorRxVerification.pdWarnings) || null,
  }),
);

const contactsDataPendingSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.contactsDataPending,
);

const pendingContactsRxSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.pendingContactsRx,
);

const errorRxVerificationSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.errorRxVerification,
);

const contactsDataSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.contactsData || [],
);

const pcidSelector = createSelector(
  ohmStateSelector,
  ohm => ({
    od: ohm.odPCIDs,
    os: ohm.osPCIDs,
  }),
);

const rxVerifiedSelector = createSelector(
  ohmStateSelector,
  ohm => ohm.rxVerified,
);


const ohm = {
  ohmStateSelector,
  errorSelector,
  rxVariantsSelector,
  rxVerificationPdMsgsSelector,
  contactsDataPendingSelector,
  pendingContactsRxSelector,
  errorRxVerificationSelector,
  contactsDataSelector,
  pcidSelector,
  rxVerifiedSelector,
};

export default ohm;
