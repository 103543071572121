import { css } from '@emotion/react';

export const table = css`
  background-color: white;
  border-collapse: collapse;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
`;

export const noAppointments = css`
  margin: 0 auto;
  display: block;
  text-align: center;
`;
