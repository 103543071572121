import { createSelector } from 'reselect';
import { getTopConState } from './getStates';

const topConStateSelector = createSelector(
  getTopConState,
  topCon => topCon,
);

const patientSelector = createSelector(
  topConStateSelector,
  topCon => topCon.patient,
);

const topCon = {
  topConStateSelector,
  patientSelector,
};

export default topCon;
