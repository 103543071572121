// These styles are used to allow the "Prescription Sent" Modal to look more
// like it did before the migration to the RDS Modal.
// TODO(matthew.snyder): Fix all this, make it part of the Modal API.

import styled from '@emotion/styled';
import { ModalActions } from '@warbyparker/retail-design-system';

const RxUploadButtons = styled(ModalActions)`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-left: 24px;
  margin-right: 24px;
`;

// TODO(matthew.snyder): Add a Check24Icon to RDS and use it here instead
const CheckIconContainer = styled.h1`
  font-size: 48px;
  margin: 0;
`;

const SmallerMarginHeader = styled.h1`
  margin-top: 4px;
`;

export {
  RxUploadButtons,
  CheckIconContainer,
  SmallerMarginHeader,
};
