/* eslint-disable class-methods-use-this */
import console from 'console';
import parseErrorToJSON from '../utils/parseErrorToJSON';
import { ILogger } from '../types';

export class ConsoleLogger implements ILogger {
  public debug(args: any, message: string): void {
    console.debug('%s\n%O', message, parseErrorToJSON(args));
  }

  public error(args: any, message: string): void {
    console.error('%s\n%O', message, parseErrorToJSON(args));
  }

  public info(args: any, message: string): void {
    console.log('%s\n%O', message, parseErrorToJSON(args));
  }

  public warn(args: any, message: string): void {
    console.warn('%s\n%O', message, parseErrorToJSON(args));
  }
}
