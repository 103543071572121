import { css } from '@emotion/react';

// TODO(msnyder): Separate spin animation from container properties so it can be
// used in more places.
export const loading = css`
  align-items: center;
  background: transparent;
  border-radius: 1px;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  width: 100%;

  img {
    animation-name: spin;
    animation-timing-function: cubic-bezier(0.38, 0.05, 0.31, 0.9);
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg) scaleX(-1);
    }
    to {
      transform: rotate(360deg) scaleX(-1);
    }
  }
`;
