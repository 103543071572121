export const get24hourTime = (time) => {
  if (!time) {
    const now = new Date();
    const h = now.getHours();
    let m = now.getMinutes();

    if (m < 10) m = `0${m}`;
    return `${h}${m}`;
  }

  const strippedDate = stripDate(time).split(':');
  return `${strippedDate[0]}${strippedDate[1]}`;
};

const stripDate = (time) => {
  if (!time) return;
  return time.split('T')[1];
};

export const convertToRegularTime = (time) => {
  let timeValue;
  // Time comes in as "2020-02-28T17:00:00"
  const splitTime = stripDate(time).split(':');
  const hours = Number(splitTime[0]);
  const minutes = Number(splitTime[1]);

  if (hours > 0 && hours <= 12) {
    timeValue = `${hours}`;
  } else if (hours > 12) {
    timeValue = `${hours - 12}`;
  } else if (hours === 0) {
    timeValue = '12';
  }
  timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`; // get minutes
  timeValue += hours >= 12 ? 'pm' : 'am'; // get AM/PM
  return timeValue;
};

// This function converts strings with underscores to English
export const humanize = (str) => {
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i += 1) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

// I couldn't think of a better name
export const unCamelCase = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
