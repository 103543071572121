import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Label } from '../shared-components/Label';
import { disabled, section } from '../assets/styles/colorSelector';

const ColorSelector = (props) => {
  const { eye, selectedColor, connectedUpdateRxValues, colors } = props;
  // sometimes colors come in as [null] from PIM
  const hasNoColors = !colors || (colors.length === 1 && colors[0] === null);
  const hasOneColor = colors && colors.length === 1 && colors[0] !== null;

  useEffect(() => {
    if (hasOneColor) {
      if (!selectedColor) {
        connectedUpdateRxValues(colors[0], `${eye}-color`);
      }
    }

    return () => {};
  }, [colors, connectedUpdateRxValues, eye, hasOneColor, selectedColor]);

  if (hasNoColors) {
    return <section css={section} />;
  }

  if (hasOneColor && selectedColor) {
    return (
      <section css={section}>
        <Label text={`${eye} - Contacts Color`} />
        <span css={disabled}>{colors[0]}</span>
      </section>
    );
  }

  return (
    <section css={section}>
      <Label text={`${eye} - Contacts Color`} />
      <span>
        <select
          onChange={e => connectedUpdateRxValues(e.target.value, `${eye}-color`)}
          value={selectedColor || '--'}
        >
          <option key="-od-" value="--">
            Select a color...
          </option>
          {colors.map(color => (
            <option key={`${eye}-${color}`} value={color}>
              {color}
            </option>
          ))}
        </select>
      </span>
    </section>
  );
};

ColorSelector.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  connectedUpdateRxValues: PropTypes.func,
  eye: PropTypes.string,
  selectedColor: PropTypes.string,
};

export default ColorSelector;
