import OhmClient, { Service } from '@warbyparker/ohmsdk';
import {
  FETCH_CONTACTS_DATA_SUCCESS,
  FETCH_CONTACTS_DATA_PENDING,
  FETCH_CONTACTS_DATA_FAIL,
  FETCH_CONTACTS_RX_PENDING,
  FETCH_CONTACTS_RX_SUCCESS,
  FETCH_CONTACTS_RX_FAIL,
  FETCH_CONTACTS_PRODUCTS_PENDING,
  FETCH_CONTACTS_PRODUCTS_SUCCESS,
  FETCH_CONTACTS_PRODUCTS_FAIL,
  VERIFY_RX_PENDING,
  VERIFY_RX_SUCCESS,
  VERIFY_RX_FAIL,
  VERIFY_RX_API_FAIL,
} from '../action-types';

import fetchAndParse from './response-parser';

import { stripWarnings } from '../utils/actions.utils';
import Wapi from '../../service-clients/wapi';

export function getContactLensDetailedProducts(token) {
  return async function action(dispatch) {
    const client = new OhmClient(Service.PIM, token);
    dispatch({
      type: FETCH_CONTACTS_DATA_PENDING,
    });
    try {
      const res = await client.get(
        'v1/category/primary/CONTACT_LENS_PACK/detailed-products?format=nest',
      );
      dispatch({
        type: FETCH_CONTACTS_DATA_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CONTACTS_DATA_FAIL,
        payload: error.message,
      });
    }
  };
}

export function getContactLensRx(pcProductIds, token, eye) {
  return async function action(dispatch) {
    dispatch({
      type: FETCH_CONTACTS_RX_PENDING,
      payload: { pcProductIds, eye },
    });
    // Any given contact lens pack may have missing SKU data in the WP inventory
    // catalog. If we attempt to get the list of available Rx ranges from one of
    // these packs, we will fail.
    //
    // To prevent this, we fire off a request for prescription ranges for all of
    // the PC product IDs we receive, and merge all values into one
    // list of Rx ranges is the one that is used to populate the Rx grid.
    //
    // This is non-deterministic but the process that was handing us empty Rx
    // ranges also is, so this actually doesn't change the ultimate expectations
    // for this action.
    Promise.all(
      pcProductIds.map(
        id => Wapi.getProductContactLensPackRxs(id).then((res) => {
          const values = res;
          return values && values.length > 0 ? values : [];
        }),
      ),
    ).then(values => dispatch({
      type: FETCH_CONTACTS_RX_SUCCESS,
      payload: { res: values, eye },
    })).catch(err => dispatch({
      type: FETCH_CONTACTS_RX_FAIL,
      payload: err.message,
    }));
  };
}

export function getContactsProductsByCategoryId(product_line_id, token, eye) {
  return async function action(dispatch) {
    const client = new OhmClient(Service.PIM, token);
    dispatch({
      type: FETCH_CONTACTS_PRODUCTS_PENDING,
    });
    try {
      const res = await client.get(
        `v1/category/CL_PL_SP_${product_line_id}/products`,
      );
      dispatch({
        type: FETCH_CONTACTS_PRODUCTS_SUCCESS,
        payload: { res: res.data, eye },
      });
    } catch (error) {
      dispatch({
        type: FETCH_CONTACTS_PRODUCTS_FAIL,
        payload: error.message,
      });
    }
  };
}

export function verifyRx(rx, correctionType) {
  return async function action(dispatch) {
    dispatch({
      type: VERIFY_RX_PENDING,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.RX_VERIFICATION}/api/prescription/verify`,
        method: 'POST',
        body: rx,
        headers: {},
      });

      let strippedRes;
      const { category } = rx;
      if (res.data) {
        // if contacts, manually strip away any PD warnings/errors
        // if glasses and single vision, manually strip ADD warnings
        // allocate PD warnings/errors to specified keys
        strippedRes = stripWarnings(res.data, category, correctionType);
        const hasErrorsAndOrWarnings = strippedRes.errors.length > 0
          || strippedRes.warnings.length > 0
          || (strippedRes.pdErrors && strippedRes.pdErrors.length > 0)
          || (strippedRes.pdWarnings && strippedRes.pdWarnings.length > 0);
        if (hasErrorsAndOrWarnings) {
          dispatch({
            type: VERIFY_RX_FAIL,
            payload: strippedRes,
          });
        } else {
          dispatch({
            type: VERIFY_RX_SUCCESS,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: VERIFY_RX_API_FAIL,
        payload: error.message,
      });
    }
  };
}
