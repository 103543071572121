import { css } from '@emotion/react';

import COLORS from './colors';

export const button = css`
  background: ${COLORS.BLUE};
  border-radius: 100px;
  border: none;
  color: white;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 24px;
  padding: 12px 0px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background: #e1e5e6;
    color: ${COLORS.SMOKE};
    cursor: not-allowed;
  }
`;

export const cancelButton = css`
  background: ${COLORS.WARNING_RED};
  border-radius: 100px;
  border: none;
  color: white;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 24px;
  padding: 12px 0px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background: #e1e5e6;
    color: ${COLORS.SMOKE};
    cursor: not-allowed;
  }
`;

export const pillButton = css`
  background: white;
  border-radius: 100px;
  border-radius: 100px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: ${COLORS.BLUE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 24px;
  margin-right: 15px;
  padding: 12px 0px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background: #e1e5e6;
    color: ${COLORS.SMOKE};
    cursor: not-allowed;
  }
`;

export const prismButton = css`
  background: transparent;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 18px;
  display: inline-block;
  width: 160px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  span {
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: ${COLORS.BLUE};
    vertical-align: middle;
  }
`;

export const loadingContainer = css`
  @keyframes loading {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }

  div {
    background-color: white;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    width: 12px;
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  div:nth-of-type(2) {
    animation-delay: 0.2s;
    margin-left: 10px;
  }
  div:nth-of-type(3) {
    animation-delay: 0.4s;
    margin-left: 10px;
  }
`;

export const bluePendingButton = css`
  background: ${COLORS.BLUE};
  border-radius: 100px;
  border: none;
  color: white;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 24px;
  padding: 12px 0px;
  width: 100%;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    color: ${COLORS.SMOKE};
    cursor: not-allowed;
  }
`;
