import React from 'react';
import { PropTypes } from 'prop-types';
import { borderFix, card, checkbox, selectedCard } from '../assets/styles/card';
import glasses from '../assets/img/glasses.svg';
import contacts from '../assets/img/contacts.svg';

const images = {
  'Glasses Rx': glasses,
  'Contacts Rx': contacts,
};

const Card = ({ isSelected, text, contents, onClick, disabled }) => {
  // TODO(msnyder): text is a string, contents is a node, they should be the
  // same property
  const src = images[text];
  return (
    <div css={borderFix}>
      <button
        disabled={disabled}
        css={isSelected ? selectedCard : card}
        onClick={onClick}
      >
        <input
          disabled={disabled}
          css={checkbox}
          type="radio"
          checked={isSelected}
          readOnly
        />
        {src && <img src={src} alt="card" />}
        <span>{text}</span>
        {contents && <div>{contents}</div>}
      </button>
    </div>
  );
};

Card.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  text: PropTypes.string,
};

export default Card;
