import { css } from '@emotion/react';

import COLORS from './colors';

export const rxTypeContainer = css`
  max-width: 748px;
  margin: 0 auto;
  width: 100%;
`;

export const measurementContainer = css`
  padding: 15px 0;
`;

export const rxTypeDiv = css`
  max-width: 748px;
`;

export const buttonContainer = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const expirationCheckboxContainer = css`
  max-width: 748px;
  position: relative;
  height: 33px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    color: ${COLORS.STONE};
    margin-left: 40px;
  }
`;

export const expirationCheckbox = css`
  -webkit-appearance: none;
  background-size: cover;
  background: #fff;
  border: 1px solid #e1e5e6;
  border-radius: 6px;
  cursor: pointer;
  height: 24px;
  margin: 0 5px;
  position: absolute;
  top: 4px;
  transition: all 0.2s ease;
  transition: border 0.2s ease;
  width: 24px;

  &:checked {
    background: ${COLORS.BLUE} url(/src/assets/img/check.svg) center no-repeat;
    border: 1px solid ${COLORS.BLUE};
    border-radius: 6px;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background: ${COLORS.SILK};
    border: 1px solid #e1e5e6;
    border-radius: 6px;
    box-sizing: border-box;
    color: ${COLORS.SMOKE};
  }
`;

export const expirationInputContainer = css`
  display: block;
  max-width: 342px;
  input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }
`;

export const expirationInput = css`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: ${COLORS.CHARCOAL};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 21px 24px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
  }
  &::placeholder {
    color: ${COLORS.SMOKE};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: ${COLORS.SILK};
    cursor: not-allowed;
  }
`;
