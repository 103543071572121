/* eslint-disable react/no-multi-comp */
import React, { useEffect, useMemo } from 'react';
import tableStyles from '../../assets/styles/table';
import { underscore } from '../../redux/utils/actions.utils';
import { convertPrismBase } from '../../utilities/createRx';
import { rxValueLookup } from '../../utilities/rxTable';

export const DisabledCell = props => <td css={tableStyles.disabledCell} {...props} />;

export const InputCell = (props) => {
  const { header, singleEyeRx, eye, connectedUpdateRxValues } = props;
  const currKey = underscore(header.headerLabel.toLowerCase());
  let value;
  if (currKey.includes('prism')) {
    value = singleEyeRx && singleEyeRx[currKey]
      ? `${singleEyeRx[currKey].prism} ${convertPrismBase(singleEyeRx[currKey].base, true)}`
      : '';
  } else {
    value = singleEyeRx ? singleEyeRx[currKey] : '';
  }

  const maxLength = 6;
  return (
    <td css={tableStyles.td}>
      <input
        maxLength={maxLength}
        onBlur={e => connectedUpdateRxValues(e.target.value, e.target.id)}
        css={tableStyles.input}
        id={`${eye}-${header.headerLabel}`}
        defaultValue={value}
      />
    </td>
  );
};

export const DropdownCell = (props) => {
  const { index, header, variants, eye, singleEyeRx, connectedUpdateRxValues } = props;
  const currKey = underscore(header.headerLabel.toLowerCase());
  const options = useMemo(() => (variants && variants[currKey]) || [], [currKey, variants]);
  const valueSpecs = `${eye}-${header.headerLabel}`;

  useEffect(() => {
    if (options.length === 1 && singleEyeRx[currKey] === null) {
      connectedUpdateRxValues(options[0], valueSpecs);
    }

    return () => {};
  }, [connectedUpdateRxValues, currKey, options, singleEyeRx, valueSpecs]);

  if (Array.isArray(options) && options.length === 0) {
    return <DisabledCell key={index} />;
  }

  if (options.length === 1) {
    return <DisabledCell>{options[0]}</DisabledCell>;
  }

  const isStringArray = options.every(option => Number.isNaN(option));
  return (
    <td css={tableStyles.td}>
      <select
        css={
          isStringArray
            ? tableStyles.dropdownStringFont
            : tableStyles.dropdown
        }
        id={valueSpecs}
        onChange={e => connectedUpdateRxValues(e.target.value, e.target.id)}
        value={rxValueLookup(singleEyeRx, currKey)}
      >
        <option key="---default" value="--" id={`${eye}`} />
        {options.map((val, i) => (
          <option key={`${val}-${i}`} value={val} id={valueSpecs}>
            {val}
          </option>
        ))}
      </select>
    </td>
  );
};
