import { NewRelicLogger } from './loggers/NewRelicLogger';
import { ConsoleLogger } from './loggers/ConsoleLogger';
import { ILogger } from './types';
import isDevBuildEnv from './utils/isDevBuildEnv';

class Logger {
  logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  getLogger() {
    return this.logger;
  }
}

const consoleLogger = new ConsoleLogger();
const newRelicLogger = new NewRelicLogger();

export const logger = new Logger(isDevBuildEnv() ? consoleLogger : newRelicLogger).getLogger();
