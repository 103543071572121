/* eslint-disable no-undef */
const parseError = async (response) => {
  const body = await response.json();
  const error = body.error || {};
  const msg500 = 'Internal Server Error.';
  const msg = error.message || msg500;

  // TODO: format json error messages
  return new Error(msg);
};

const parseResponse = async ({ response }) => {
  switch (response.status) {
  case 201:
  case 200:
    return response.json();
  case 204:
    return {};
  case 400:
  case 502:
    throw new Error((await response.json()).message || 'We’ve done something wrong');
  case 500:
    throw await parseError(response);
  default:
    throw new Error('Something went wrong. 😢');
  }
};

const headers = ({ token }) => ({
  Authorization: `bearer ${token}`,
});

const API = {
  async getUserJwt() {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/jwt`;
    const response = await fetch(url, {
      credentials: 'include',
    }).catch((e) => {
      const message = 'Failed to fetch auth token.';
      logger.error(e, message);
      throw new Error(message);
    });

    // two separate cases:
    let data;
    switch (response.status) {
    case 200:
      data = await response.json();
      return data.token;
    case 403:
      // missing credentials, needs to log in
      return null;
    default:
      return parseResponse({ response });
    }
  },

  async getUserMe({ token }) {
    const url = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/me`;
    const request = new window.Request(url, {
      headers: headers({ token }),
      credentials: 'include',
    });
    const response = await fetch(request);
    return parseResponse({ response });
  },

};

export default API;
