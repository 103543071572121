import React from 'react';

import { CaretRight12Icon, LocationIcon } from '@warbyparker/retail-design-system';
import { useAppSelector } from '../hooks';
import storeLocator from '../assets/styles/storeLocator';

const params = new URLSearchParams({
  backUrl: `${window.location.origin}`,
});

const StoreLocator = () => {
  const facilityName = useAppSelector(state => state.auth?.me?.facility?.name);

  return (
    <div css={storeLocator.wrapper}>
      <a
        css={storeLocator.link}
        href={`https://${process.env.POE_DOMAIN || ''}/profile?${params.toString()}`}
      >
        <span css={storeLocator.locationIcon}>
          <LocationIcon />
        </span>
        <span css={storeLocator.storeName}>
          {facilityName}
        </span>
        <span css={storeLocator.caretRightIcon}>
          <CaretRight12Icon />
        </span>
      </a>
    </div>
  );
};

export default StoreLocator;
