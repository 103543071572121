import {
  ADD_ANOTHER_RX,
  CLEAR_SESSION,
  SAVE_RX_SUCCESS,
  SELECT_CONTACTS_BRAND,
  SELECT_CONTACTS_PRODUCTLINE,
  SELECT_PATIENT,
  SHOW_PRISMS,
  TOGGLE_CORRECTION_TYPE,
  TOGGLE_DISCARD_MODAL,
  TOGGLE_DOUBLE_CHECK_MODAL,
  TOGGLE_EXPIRATION,
  TOGGLE_CONFIRMATION_MODAL,
  TOGGLE_PRESCRIPTION_TYPE,
  TOGGLE_PRESCRIPTION_USAGE,
  TOGGLE_PROGRESSIVE_RANGES,
  UPDATE_ADD,
  UPDATE_AXIS,
  UPDATE_BASE_CURVE,
  UPDATE_COLOR,
  UPDATE_CYLINDER,
  UPDATE_DIAMETER,
  UPDATE_NOTES,
  UPDATE_PRISMS,
  UPDATE_SPHERE,
  UPDATE_PD,
  TOGGLE_INPUT_EXPIRATION,
  UPDATE_EXPIRATION_INPUT,
  UPDATE_DN,
} from '../action-types';

import {
  emptyStringToNull,
  getExpirationDate,
  objectifyPrism,
  splitProductlineAndID,
} from '../utils/reducer.utils';

import { OD } from '../../utilities/rxTable';

const initialState = {
  patient: null,
  prescriptionType: null,
  correctionType: null,
  distance: null,
  notes: null,
  progressiveRanges: null,
  expiration: '',
  expirationDate: null,
  inputExpiration: '',
  showConfirmationModal: false,
  showDiscardModal: false,
  showDoubleCheckModal: false,
  showPrisms: false,
  rx_os: {
    sphere: null,
    axis: null,
    cylinder: null,
    add_power: null,
    prisms: null,
    base_curve: null,
    diameter: null,
    color: null,
    contacts: {
      product_line: null,
      brand_name: null,
      product_name: null,
      dn: null,
    },
  },
  rx_od: {
    sphere: null,
    axis: null,
    cylinder: null,
    add_power: null,
    prisms: null,
    base_curve: null,
    diameter: null,
    color: null,
    contacts: {
      product_line: null,
      brand_name: null,
      product_name: null,
      dn: null,
    },
  },
  pd: {
    os: null,
    od: null,
    bi: null,
  },
  useInputExpiration: false,
};

function rxEntryReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_EXPIRATION_INPUT:
    return {
      ...state,
      inputExpiration: payload,
    };
  case TOGGLE_INPUT_EXPIRATION:
    if (state.useInputExpiration === false) {
      return {
        ...state,
        expiration: '',
        useInputExpiration: !state.useInputExpiration,
      };
    }
    return {
      ...state,
      inputExpiration: '',
      useInputExpiration: !state.useInputExpiration,
    };
  case SHOW_PRISMS:
    return {
      ...state,
      showPrisms: true,
    };
  case UPDATE_NOTES:
    return {
      ...state,
      notes: payload,
    };
  case SELECT_PATIENT:
    return {
      ...state,
      patient: payload,
    };
  case SELECT_CONTACTS_PRODUCTLINE:
    if (payload.eye === OD) {
      return {
        ...state,
        rx_od: {
          sphere: null,
          axis: null,
          cylinder: null,
          add_power: null,
          prisms: null,
          base_curve: null,
          diameter: null,
          color: null,
          contacts: {
            ...state.rx_od.contacts,
            ...splitProductlineAndID(payload.productLine),
            dn: null,
          },
        },
      };
    }
    return {
      ...state,
      rx_os: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prisms: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          ...state.rx_os.contacts,
          ...splitProductlineAndID(payload.productLine),
          dn: null,
        },
      },
    };
  case SELECT_CONTACTS_BRAND:
    if (payload.eye === OD) {
      return {
        ...state,
        rx_od: {
          sphere: null,
          axis: null,
          cylinder: null,
          add_power: null,
          prisms: null,
          base_curve: null,
          diameter: null,
          color: null,
          contacts: {
            brand_name: payload.brand,
            product_line: null,
            product_name: null,
            dn: null,
          },
        },
      };
    }
    return {
      ...state,
      rx_os: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prisms: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          brand_name: payload.brand,
          product_line: null,
          product_name: null,
          dn: null,
        },
      },
    };
  case TOGGLE_EXPIRATION:
    return {
      ...state,
      expiration: payload,
    };
  case TOGGLE_CORRECTION_TYPE:
    return {
      ...state,
      correctionType: payload,
    };
  case TOGGLE_PRESCRIPTION_TYPE:
    return {
      ...state,
      correctionType: null,
      distance: null,
      expiration: '',
      expirationDate: null,
      inputExpiration: '',
      notes: null,
      prescriptionType: payload,
      progressiveRanges: null,
      showConfirmationModal: false,
      showPrisms: false,
      rx_os: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prism_1: null,
        prism_2: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          product_line: null,
          brand_name: null,
          product_name: null,
          dn: null,
        },
      },
      rx_od: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prism_1: null,
        prism_2: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          product_line: null,
          brand_name: null,
          product_name: null,
          dn: null,
        },
      },
      useInputExpiration: false,
    };
  case UPDATE_BASE_CURVE:
  case UPDATE_DIAMETER:
  case UPDATE_COLOR:
  case UPDATE_SPHERE:
  case UPDATE_CYLINDER:
  case UPDATE_AXIS:
  case UPDATE_ADD:
    if (payload.eye.toLowerCase() === 'od') {
      return {
        ...state,
        rx_od: {
          ...state.rx_od,
          [payload.valueType]: emptyStringToNull(payload.val),
        },
      };
    }
    return {
      ...state,
      rx_os: {
        ...state.rx_os,
        [payload.valueType]: emptyStringToNull(payload.val),
      },
    };
  case UPDATE_DN:
    if (payload.eye.toLowerCase() === 'od') {
      return {
        ...state,
        rx_od: {
          ...state.rx_od,
          contacts: {
            ...state.rx_od.contacts,
            [payload.valueType]: emptyStringToNull(payload.val),
          },
        },
      };
    }
    return {
      ...state,
      rx_os: {
        ...state.rx_os,
        contacts: {
          ...state.rx_os.contacts,
          [payload.valueType]: emptyStringToNull(payload.val),
        },
      },
    };
  case UPDATE_PRISMS:
    if (payload.eye.toLowerCase() === 'od') {
      return {
        ...state,
        rx_od: {
          ...state.rx_od,
          [payload.valueType]: objectifyPrism(payload.val),
        },
      };
    }
    return {
      ...state,
      rx_os: {
        ...state.rx_os,
        [payload.valueType]: objectifyPrism(payload.val),
      },
    };
  case UPDATE_PD:
    return {
      ...state,
      pd: {
        ...state.pd,
        [payload.type]: payload.val,
      },
    };
  case TOGGLE_PRESCRIPTION_USAGE:
    return {
      ...state,
      distance: payload,
    };
  case TOGGLE_PROGRESSIVE_RANGES:
    return {
      ...state,
      progressiveRanges: payload,
    };
  case TOGGLE_CONFIRMATION_MODAL:
    return {
      ...state,
      showConfirmationModal: payload,
    };
  case TOGGLE_DISCARD_MODAL:
    return {
      ...state,
      showDiscardModal: payload,
    };
  case TOGGLE_DOUBLE_CHECK_MODAL:
    return {
      ...state,
      showDoubleCheckModal: payload,
    };
  case SAVE_RX_SUCCESS:
    return {
      ...state,
      expirationDate: getExpirationDate(payload),
    };
  case ADD_ANOTHER_RX:
    return {
      ...state,
      prescriptionType: null,
      correctionType: null,
      distance: null,
      progressiveRanges: null,
      expiration: '',
      expirationDate: null,
      inputExpiration: '',
      showConfirmationModal: false,
      showDoubleCheckModal: false,
      showPrisms: false,
      rx_os: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prisms: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          product_line: null,
          brand_name: null,
          product_name: null,
          dn: null,
        },
      },
      rx_od: {
        sphere: null,
        axis: null,
        cylinder: null,
        add_power: null,
        prisms: null,
        base_curve: null,
        diameter: null,
        color: null,
        contacts: {
          product_line: null,
          brand_name: null,
          product_name: null,
          dn: null,
        },
      },
      pd: {
        os: null,
        od: null,
        bi: null,
      },
      useInputExpiration: false,
    };
  case CLEAR_SESSION:
    return initialState;
  default:
    return state;
  }
}

export default rxEntryReducer;
