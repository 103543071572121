import { css } from '@emotion/react';

import COLORS from './colors';

export const section = css`
  width: 50%;
  box-sizing: border-box;
  &:first-of-type {
    margin-right: 6px;
  }
  span {
    display: block;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    background-image: url(/src/assets/img/downArrow.svg);
    background-position: right 18px top 50%;
    background-repeat: no-repeat;
    background-repeat: none;
    background-size: 18px auto;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin: 0 0 18px 0;
    outline: none;
    padding: 18px 48px 18px 18px;
    width: 100%;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }
  select[disabled] {
    background-color: ${COLORS.SILK};
    background-image: none;
  }
  button {
    margin: 0 6px 6px 0;
    padding: 8px;
    min-width: 50px;
    display: inline-block;
    background: none;
    border-radius: 4px;
    border: 1px solid #ccc;

    &.selected {
      color: ${COLORS.BLUE};
      border-color: ${COLORS.BLUE};
    }
  }
  p {
    margin: 0;
    padding: 0 0 18px 0;
  }
  h3 {
    display: block;
    color: ${COLORS.STONE};
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 18px 0;
    margin: 0;
  }
`;
