import moment, { MomentInput, unitOfTime } from 'moment';
import { format } from 'date-fns';

export const formatDate = (date: Date, dateFormat: string): string => format(date, dateFormat);
export const isSame = (
  firstDate: MomentInput,
  secondDate: MomentInput,
  unit: unitOfTime.StartOf = 'milliseconds',
): boolean => moment(firstDate).isSame(secondDate, unit);

export default {
  formatDate,
  isSame,
};
