import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import auth from './auth';
import rxEntryReducer from './reducers/rxEntry';
import pmpReducer from './reducers/pmp';
import ohmReducer from './reducers/ohm';
import topconReducer from './reducers/topcon';

const rootReducer = combineReducers({
  auth: auth.reducer,
  rxEntry: rxEntryReducer,
  pmp: pmpReducer,
  ohm: ohmReducer,
  topcon: topconReducer,
});

export const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

export default function configureStore(initialState) {
  const composeEnhancers = composeWithDevTools({ trace: true });

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(auth.saga);

  return store;
}
