import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Card from '../shared-components/Card';
import { getOneReading } from '../redux/actions/topcon';

const ReadingCard = ({
  features,
  connectedGetOneReading,
  contents,
  disabled,
  examRoom,
  isSelected,
  measurementName,
  onClick,
  shortName,
  text,
  token,
}) => {
  useEffect(() => {
    connectedGetOneReading(token, shortName, examRoom, measurementName, features);
  }, []);

  return (
    <Card
      disabled={disabled}
      onClick={onClick}
      isSelected={isSelected}
      text={text}
      contents={contents}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      connectedGetOneReading: getOneReading,
    },
    dispatch,
  ),
});

export const mapStateToProps = (state = {}) => {
  // Defaulting as these properties can possible be null.
  const {
    topcon,
  } = state || {};

  const {
    patient,
  } = topcon || {
    patient: {},
  };

  const {
    examRoom,
  } = patient || {};

  return {
    examRoom,
    patient,
  };
};

ReadingCard.propTypes = {
  connectedGetOneReading: PropTypes.func,
  contents: PropTypes.shape({}),
  examRoom: PropTypes.string,
  measurementName: PropTypes.string,
  onClick: PropTypes.func,
  shortName: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  token: PropTypes.string,
  isSelected: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadingCard);
