import { css } from '@emotion/react';
import COLORS from './colors';

const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 0px 4px rgba(65, 75, 86, 0.12);
`;

const link = css`
  display: flex;
  margin: 0 46px 0 24px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: ${COLORS.BLUES_BLUE};
  font-size: 24px;
`;

const widthLimiter = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 768px;
  width: 100%;
`;

const heading = css`
  color: ${COLORS.CHARCOAL};
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0;
`;

const buttonWrapper = css`
  margin-right: 24px; 
`;

const button = ({ visible } : { visible: boolean }) => css`
  visibility: ${visible ? 'visible' : 'hidden'} ; 
`;

export default {
  button,
  buttonWrapper,
  heading,
  link,
  widthLimiter,
  wrapper,
};
