import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import rxTableStyles from '../../assets/styles/rxTable';
import { eyeArray, OD } from '../../utilities/rxTable';
import ContactsSelection from '../ContactsSelection';
import ColorSelector from '../ColorSelector';
import { useAppSelector } from '../../hooks';
import ohm from '../../redux/selectors/OhmSelectors';
import rxEntry from '../../redux/selectors/RxEntrySelectors';
import { selectContactsBrand, selectContactsProductline } from '../../redux/actions/rxEntry';
import useActions from '../../hooks/useActions';
import { getContactLensDetailedProducts } from '../../redux/actions/ohm';

export const ContactsSelector = () => {
  const token = useAppSelector(state => state.auth?.jwt);

  const rxState = useAppSelector(rxEntry.rxStateSelector);
  const odSelectedContactsProductLine = useAppSelector(rxEntry.odSelectedContactsProductLineSelector);
  const osSelectedContactsProductLine = useAppSelector(rxEntry.osSelectedContactsProductLineSelector);
  const contactsData = useAppSelector(ohm.contactsDataSelector);
  const pending = useAppSelector(ohm.contactsDataPendingSelector);

  const {
    connectedSelectContactsBrand,
    connectedSelectContactsProductline,
    connectedGetContactsData,
  } = useActions({
    connectedSelectContactsBrand: selectContactsBrand,
    connectedSelectContactsProductline: selectContactsProductline,
    connectedGetContactsData: getContactLensDetailedProducts,
  });

  useEffect(() => {
    connectedGetContactsData(token);
  }, [connectedGetContactsData, token]);

  return (
    <div css={rxTableStyles.contactsSelectorContainer}>
      {eyeArray.map((eye) => {
        const isOD = eye === OD;
        const selectedContactsBrand = isOD
          ? rxState.od.contacts.brand_name
          : rxState.os.contacts.brand_name;
        const selectedContactsProductLine = isOD
          ? odSelectedContactsProductLine
          : osSelectedContactsProductLine;
        return (
          <ContactsSelection
            key={eye}
            connectedSelectContactsBrand={connectedSelectContactsBrand}
            connectedSelectContactsProductline={connectedSelectContactsProductline}
            contactsData={contactsData}
            pending={pending}
            eye={eye}
            selectedContactsBrand={selectedContactsBrand}
            selectedContactsProductLine={selectedContactsProductLine}
          />
        );
      })}
    </div>
  );
};

export const ColorSelectors = (props) => {
  const { variants, rxState, connectedUpdateRxValues } = props;
  return (
    <div css={rxTableStyles.contactsSelectorContainer}>
      {eyeArray.map((eye) => {
        const isOD = eye === OD;
        const colors = isOD
          ? variants.od && variants.od.color
          : variants.os && variants.os.color;
        const selectedColor = isOD ? rxState.od.color : rxState.os.color;
        return (
          <ColorSelector
            key={eye}
            eye={eye}
            connectedUpdateRxValues={connectedUpdateRxValues}
            colors={colors}
            selectedColor={selectedColor}
          />
        );
      })}
    </div>
  );
};

ColorSelectors.propTypes = {
  connectedUpdateRxValues: PropTypes.func.isRequired,
  rxState: PropTypes.shape({
    od: PropTypes.shape({
      color: PropTypes.string,
    }),
    os: PropTypes.shape({
      color: PropTypes.string,
    }),
  }).isRequired,
  variants: PropTypes.shape({
    od: PropTypes.shape({
      color: PropTypes.arrayOf(PropTypes.string),
    }),
    os: PropTypes.shape({
      color: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};
