import { createSelector } from 'reselect';
import { parseJSON } from 'date-fns';
import { getPmpState } from './getStates';

const pmpStateSelector = createSelector(
  getPmpState,
  pmpState => pmpState,
);

const currentDateSelector = createSelector(
  pmpStateSelector,
  pmp => pmp.date,
);

const currentParsedDateSelector = createSelector(
  currentDateSelector,
  date => parseJSON(date),
);

const facilityRegionAndCountrySelector = createSelector(
  pmpStateSelector,
  pmp => ({
    country: pmp.facilityCountry,
    region: pmp.facilityRegion,
  }),
);

const rxStatusSelector = createSelector(
  pmpStateSelector,
  pmp => ({
    saved: pmp.rxSaved,
    sentToHelios: pmp.rxSentToHelios,
    uid: pmp.rxUID,
  }),
);

const appointmentListSelector = createSelector(
  pmpStateSelector,
  /**
   * @returns {{
   *    appointments: import('../../components/AppointmentModal').AppointmentModalProps['slot'][],
   *    appointmentTypes: import('../../types').AppointmentType[],
   *    doctorUID: string,
   *    error: string,
   *    pending: boolean,
   * }}
   */
  pmp => ({
    appointments: pmp.patientList,
    appointmentTypes: pmp.appointmentTypes,
    doctorUID: pmp.doctorUID,
    error: pmp.error,
    pending: pmp.pending || pmp.pendingAppointmentTypes,
  }),
);

const errorSelector = createSelector(
  pmpStateSelector,
  pmp => pmp.rxSaveError || pmp.rxSendToHeliosError || pmp.rxSendEmailToPatientError,
);

const pmp = {
  pmpStateSelector,
  currentDateSelector,
  currentParsedDateSelector,
  facilityRegionAndCountrySelector,
  rxStatusSelector,
  appointmentListSelector,
  errorSelector,
};

export default pmp;
