export const prescriptionType = {
  prescriptionType: ['Glasses Rx', 'Contacts Rx'],
};

export const glassesRx = prescriptionType.prescriptionType[0];
export const contactsRx = prescriptionType.prescriptionType[1];

export const correctionType = {
  correctionType: ['Single vision', 'Progressive'],
};

const singleVisionCorrection = {
  distance: ['Distance', 'Computer', 'Reading'],
};

const progressiveCorrection = {
  progressiveRanges: [
    'Distance & Reading',
    'Computer & Reading',
    'Distance & Computer',
    'Reading',
  ],
};

export const expiration = {
  expiration: ['1 Year', '2 Years', '3 Years', '5 Years'],
};

export const getRxUse = (correctionType) => {
  switch (correctionType) {
  case 'Single vision':
    return singleVisionCorrection;
  case 'Progressive':
  case 'Bifocals':
    return progressiveCorrection;
  default:
    return [];
  }
};

export const verifyStringDate = (dateString) => {
  const dateReg = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  return dateString.match(dateReg);
};
