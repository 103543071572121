const contactsInputHeaders = [
  {
    headerTitle: 'SPH',
    headerLabel: 'Sphere',
  },
];

const contactsDropdownHeadersBase = [
  {
    headerTitle: 'CYL',
    headerLabel: 'Cylinder',
  },
  {
    headerTitle: 'AXIS',
    headerLabel: 'Axis',
  },
  {
    headerTitle: 'ADD',
    headerLabel: 'Add Power',
  },
  {
    headerTitle: 'BC',
    headerLabel: 'Base Curve',
  },
  {
    headerTitle: 'DIA',
    headerLabel: 'Diameter',
  },
];

const multifocalContactsDropdownHeaders = [
  {
    headerTitle: 'D/N',
    headerLabel: 'DN',
  },
];

const contactsDropdownHeaders = canDisplayMultifocalContacts => (
  canDisplayMultifocalContacts
    ? contactsDropdownHeadersBase.concat(multifocalContactsDropdownHeaders)
    : contactsDropdownHeadersBase
);

const glassesHeaders = [
  {
    headerTitle: 'SPH',
    headerLabel: 'Sphere',
  },
  {
    headerTitle: 'CYL',
    headerLabel: 'Cylinder',
  },
  {
    headerTitle: 'AXIS',
    headerLabel: 'Axis',
  },
  {
    headerTitle: 'ADD',
    headerLabel: 'Add Power',
  },
];

const glassesPrismHeaders = [
  {
    headerTitle: 'P1',
    headerLabel: 'Prism 1',
  },
  {
    headerTitle: 'P2',
    headerLabel: 'Prism 2',
  },
];

const OD = 'OD';
const OS = 'OS';

const columns = [
  { eye: OD, label: '(R)' },
  { eye: OS, label: '(L)' },
];

export default {
  contactsDropdownHeaders,
  contactsInputHeaders,
  glassesHeaders,
  glassesPrismHeaders,
  columns,
  OD,
  OS,
};
