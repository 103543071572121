import { css } from '@emotion/react';
import COLORS from './colors';

const wrapper = css`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0;
`;

// basic styles for date input
const dateValue = css`
  font-style: normal;
  font-weight: normal;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
`;

const caretIcon = css`
  color: ${COLORS.BLUES_BLUE};
  margin-left: 12px;
  font-size: 16px;
  display: flex;
`;

export default {
  caretIcon,
  dateValue,
  wrapper,
};
