import { css } from '@emotion/react';

import COLORS from './colors';

export const msgContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  p {
    margin: 5px;
  }
  p:first-of-type {
    font-weight: 600;
  }
`;

export const warning = css`
  color: ${COLORS.WARNING_ORANGE};
`;

export const error = css`
  color: ${COLORS.WARNING_RED};
`;
