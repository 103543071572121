import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './src/app';
import configureStore from './src/redux/store';
import Header from './src/components/Header';
import { initNewRelic } from './src/new-relic';
import { injectLoggerIntoGlobal } from './src/providers/logger/injectLoggerIntoGlobal';

initNewRelic();
injectLoggerIntoGlobal();

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Header />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app'),
);
