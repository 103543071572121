import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import Label from '../shared-components/Label';
import { section } from '../assets/styles/contactsSelection';

const brandToOptions = brand => (
  <option key={brand} value={brand}>
    {brand}
  </option>
);

const productLineToOption = ([productLineName, productLineID]) => (
  <option
    key={`${productLineID}-${productLineName}`}
    value={`${productLineID}-${productLineName}`}
  >
    {productLineName}
  </option>
);

const ContactsSelection = ({
  connectedSelectContactsBrand,
  connectedSelectContactsProductline,
  contactsData,
  eye,
  pending,
  selectedContactsBrand,
  selectedContactsProductLine,
}) => {
  const [
    selectedBrandProductLinesArray,
    setSelectedBrandProductLinesArray,
  ] = useState([]);

  useEffect(() => {
    if (!contactsData) return;
    const selectedBrandIndex = contactsData.findIndex(
      brandName => brandName[0] === selectedContactsBrand,
    );
    if (selectedBrandIndex === -1) {
      connectedSelectContactsBrand(null);
      setSelectedBrandProductLinesArray([]);
      return;
    }
    const brandMatchedProductLines = contactsData[selectedBrandIndex][1];
    setSelectedBrandProductLinesArray(brandMatchedProductLines);
  }, [selectedContactsBrand]);

  if (pending) {
    return (
      <section css={section}>
        <p>Loading contact lens options...</p>
      </section>
    );
  }

  const brands = contactsData.map(group => group[0]);

  return (
    <section css={section}>
      <label>
        <Label text={`${eye} - Contacts brand`} />
        <span>
          <select
            onChange={e => connectedSelectContactsBrand(e.target.value, eye)}
            value={selectedContactsBrand || '--'}
          >
            <option key="-od-" value="--">
              Select a brand...
            </option>
            {brands.map(brandToOptions)}
          </select>
        </span>
      </label>
      <label>
        <Label text={`${eye} - Contacts product line`} />
        <span>
          <select
            disabled={!selectedContactsBrand}
            onChange={e => connectedSelectContactsProductline(e.target.value, eye)
            }
            value={selectedContactsProductLine || '--'}
          >
            <option key="-os-" value="--">
              Select a product line...
            </option>
            {selectedBrandProductLinesArray.map(productLineToOption)}
          </select>
        </span>
      </label>
    </section>
  );
};

ContactsSelection.propTypes = {
  connectedSelectContactsBrand: PropTypes.func,
  connectedSelectContactsProductline: PropTypes.func,
  contactsData: PropTypes.array,
  eye: PropTypes.string,
  pending: PropTypes.bool,
  selectedContactsBrand: PropTypes.string,
  selectedContactsProductLine: PropTypes.string,
};

export default ContactsSelection;
