import { ClassNames } from '@emotion/react';
import React from 'react';
import header from '../../assets/styles/header';

type Props = {
  text: string;
};

const HeaderDateLabel: React.FC<Props> = (props: Props) => {
  const { text } = props;
  return (
    <ClassNames>
      {({ css }) => (<span className={css(header.heading)}>{text}</span>)}
    </ClassNames>
  );
};
export default HeaderDateLabel;
