export type LogData = object | null;

export type LogMessage = string | null;

export enum LogLevel {
  DEBUG = 'debug',
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
}

export interface ILogger {
  error: (args: any, message: string) => void
  info: (args: any, message: string) => void
  warn: (args: any, message: string) => void
  debug: (args: any, message: string) => void
}
