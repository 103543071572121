import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from './Loading';
import { bluePendingButton, button } from '../assets/styles/button';

/**
 * @typedef {import('react').ComponentProps<'button'>} ButtonProps
 *
 *
 * @type {import('react').FunctionComponent<import('react').PropsWithChildren<ButtonProps & { pending?: boolean }>}
 */
const Button = ({
  pending = false,
  children,
  ...rest
}) => (
  <button
    css={pending ? bluePendingButton : button}
    {...rest}
  >
    {pending ? <Loading /> : children}
  </button>
);

Button.propTypes = {
  pending: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
