function getFormattedErrorMessage(response) {
  const {
    error: { fields, message },
  } = response;

  let errorMessage = message;
  if (fields) {
    const errorMessages = [];
    Object.keys(fields).forEach((fieldName) => {
      errorMessages.push(`${fieldName}: ${fields[fieldName]}`);
    });
    const withNewlines = errorMessages.join('\r\n');
    errorMessage = withNewlines;
  }

  return errorMessage;
}

async function fetchAndParse({ url, method = 'GET', body, headers = {} }) {
  const response = await fetch(url, {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    headers,
  });

  const json = await response.json();
  if (!response.ok) {
    throw Error(getFormattedErrorMessage(json));
  } else {
    return json;
  }
}

export default fetchAndParse;
