import { createSelector } from 'reselect';
import { getOhmState, getPmpState } from './getStates';
import rxEntry from './RxEntrySelectors';
import pmp from './PmpSelectors';
import topCon from './TopconSelectors';

export const pendingSaveRxStatusSelector = createSelector(
  [getPmpState, getOhmState],
  (pmpOjb, ohmObj) => (
    pmpOjb.sendingRxToHelios
      || pmpOjb.sendingRxEmailToPatient
      || pmpOjb.savingRx
      || ohmObj.pendingRxVerify
  ),
);

export const saveRxSelector = createSelector(
  [pmp.facilityRegionAndCountrySelector, rxEntry.patientSelector, rxEntry.rxEntryStateSelector],
  (facilityRegionAndCountry, patient, rxEntry) => ({
    appointmentID: patient && patient.appointmentID,
    doctorID: patient && patient.doctorID,
    patientID: patient && patient.patientID,
    facilityRegion: facilityRegionAndCountry.region,
    facilityCountry: facilityRegionAndCountry.country,
    rxEntry: Object.assign(rxEntry),
  }),
);

export const componentStatesSelector = createSelector(
  [
    rxEntry.rxTypeStatusSelector,
    topCon.patientSelector,
    rxEntry.patientSelector,
    rxEntry.rxEntryStateSelector,
  ],
  (allCardsSelected, addReadingSelected, patient, rxEntry) => ({
    showDoubleCheckModal: rxEntry.showDoubleCheckModal,
    showDiscardModal: rxEntry.showDiscardModal,
    showConfirmationModal: rxEntry.showConfirmationModal,
    showAppointmentList: !patient && !addReadingSelected,
    showUploadReading: addReadingSelected,
    showRxTable: !!(patient && allCardsSelected),
    showRxType: !!patient,
  }),
);
