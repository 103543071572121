import { format, addYears } from 'date-fns';
import { underscore } from '../redux/utils/actions.utils';

export const convertPrismBase = (baseString, isAbbreviation = false) => {
  const baseVal = baseString && baseString.toLowerCase();
  const baseValMapping = {
    bu: 'up',
    bd: 'down',
    bi: 'in',
    bo: 'out',
  };
  return isAbbreviation
    ? Object.keys(baseValMapping).find(
      key => baseValMapping[key] === baseVal,
    ) || baseVal
    : baseValMapping[baseVal] || baseVal;
};

const reformatValues = (rx) => {
  const { prism_1, prism_2 } = rx;

  const prisms = [];

  if (prism_1) {
    const p1Copy = Object.assign(prism_1);
    p1Copy.base = p1Copy && convertPrismBase(p1Copy.base);
    prisms.push(p1Copy);
  }
  if (prism_2) {
    const p2Copy = Object.assign(prism_2);
    p2Copy.base = p2Copy && convertPrismBase(p2Copy.base);
    prisms.push(p2Copy);
  }

  const newRx = {
    ...rx,
    prisms,
    contacts: {
      ...rx.contacts,
      base_curve: rx.base_curve,
      diameter: rx.diameter,
      color: rx.color,
    },
  };
  delete newRx.prism_1;
  delete newRx.prism_2;
  delete newRx.base_curve;
  delete newRx.diameter;
  delete newRx.color;
  return newRx;
};

export const stripRxString = (rxDateString) => {
  if (!rxDateString) return '';
  const [textString] = rxDateString.split(' ');
  return textString.toLowerCase();
};

export const clean = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === null
      || obj[propName] === undefined
      || obj[propName] === NaN
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const checkPropertiesForNull = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '') return false;
  }
  return true;
};

export const returnIntegerIfNumber = (val) => {
  if (isNaN(val) || val === null) {
    return val;
  }
  return parseFloat(val);
};

const removeContactsKeyIfNotContacts = (rxObj) => {
  if (!rxObj.contacts.base_curve) {
    for (const propName in rxObj) {
      if (propName === 'contacts') {
        delete rxObj[propName];
      }
    }
  }
  return rxObj;
};

const removeRxKeyIfNullSphere = (rxObj) => {
  const { rx_od, rx_os } = rxObj;
  const rx = {};
  if (rx_os.sphere) rx.rx_os = removeContactsKeyIfNotContacts(rx_os);
  if (rx_od.sphere) rx.rx_od = removeContactsKeyIfNotContacts(rx_od);
  return rx;
};

export const createRx = (rxEntryReduxState, region, country, date) => {
  const type = rxEntryReduxState.correctionType
    ? underscore(rxEntryReduxState.correctionType.toLowerCase())
    : 'single_vision';
  const progressiveDistanceTypes = rxEntryReduxState.progressiveRanges
    && rxEntryReduxState.progressiveRanges
      .toLowerCase()
      .replace(/\s/g, '')
      .split('&');
  const singleVisionDistanceTypes = rxEntryReduxState.distance && [
    rxEntryReduxState.distance.toLowerCase(),
  ];

  const distanceTypes = rxEntryReduxState.progressiveRanges
    ? progressiveDistanceTypes
    : singleVisionDistanceTypes;

  const isContacts = stripRxString(rxEntryReduxState.prescriptionType).includes(
    'contacts',
  );
  const strippedExpiration = rxEntryReduxState.expiration.replace(
    /years|year/gi,
    '',
  );
  const issue_date = format(date, 'yyyy-MM-dd');
  const expiration_date = rxEntryReduxState.useInputExpiration
    ? rxEntryReduxState.inputExpiration
    : format(addYears(date, strippedExpiration), 'yyyy-MM-dd');
  const rx_type = stripRxString(rxEntryReduxState.prescriptionType);
  const rx_os = reformatValues(rxEntryReduxState.rx_os);
  const rx_od = reformatValues(rxEntryReduxState.rx_od);
  const correction = {
    type,
    distance_types: isContacts ? ['distance'] : distanceTypes,
  };
  const rx_data = removeRxKeyIfNullSphere({ rx_os, rx_od });
  const pdObj = checkPropertiesForNull(rxEntryReduxState.pd)
    ? null
    : rxEntryReduxState.pd;
  const cleanedRxData = clean({ ...rx_data, pd: pdObj });

  return {
    issue_date,
    issue_country: country,
    issue_region: region,
    expiration_date,
    rx_type,
    correction,
    rx_data: cleanedRxData,
    notes: rxEntryReduxState.notes,
  };
};
