import { css } from '@emotion/react';

import COLORS from './colors';

export const row = css`
  height: 72px;
  td {
    padding: 0 9px;
    line-height: 24px;
    &:hover {
      cursor: default;
    }
  }
`;

export const grayRow = css`
  height: 72px;
  background: ${COLORS.SILK};
  td {
    padding: 0 9px;
    line-height: 24px;
    &:hover {
      cursor: default;
    }
  }
`;

export const flexRow = css`
  display: flex;
  padding: 0 4.5px;
`;

export const buttonCell = css`
  height: 77px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 162px;
  padding: 0 4.5px;
`;

export const patientName = css`
  color: ${COLORS.BLUE};
`;
