import { css } from '@emotion/react';

import COLORS from './colors';

const th = css`
  background: rgb(248, 248, 248);
  border: none;
  border-top: 1px solid rgb(225, 229, 230);
  border-bottom: 1px solid rgb(225, 229, 230);
  border-right: 1px solid rgb(225, 229, 230);
  &:first-of-type {
    border-left: 1px solid rgb(225, 229, 230);
  }
  padding: 17px 6px;
`;

const thFake = css`
  border: none;
  border-right: 1px solid rgb(225, 229, 230);
`;

const trCol = css`
  border-left: 1px solid rgb(225, 229, 230);
  border-bottom: 1px solid rgb(225, 229, 230);
`;

const thCol = css`
  background: rgb(248, 248, 248);
  padding: 16px 38px;
`;

const headerTitle = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: block;
  text-align: center;
`;

const headerLabel = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: block;
  text-align: center;
`;

const tableBody = css`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;

const table = css`
  border-collapse: collapse;
  font-family: proxima-nova, sans-serif;
  border-spacing: 0px;
  table-layout: fixed;
  width: 100%;
`;

const tableContainer = css`
  width: inherit;
  max-width: 748px;
  width: 100%;
  display: table;
  margin: 0px auto;
`;

const td = css`
  height: 120px;
  background: transparent;
  border: none;
  border-right: 1px solid rgb(225, 229, 230);
  &:first-of-type {
    border-left: 1px solid rgb(225, 229, 230);
  }
`;

const input = css`
  height: 100%;
  border: 3px solid transparent;
  width: 100%;
  width: -webkit-fill-available;
  text-align: center;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    border: 3px solid ${COLORS.BLUE};
    box-sizing: border-box;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-weight: 600;
    color: ${COLORS.BLUE};
  }
`;

const tr = css`
  border-bottom: 1px solid rgb(225, 229, 230);
`;

const dropdown = css`
  background: white;
  border: 3px solid transparent;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  height: -webkit-fill-available;
  line-height: 30px;
  text-align: center;
  width: 100%;
  width: -webkit-fill-available;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  text-align-last: center;
  white-space: pre-line;
  &:focus {
    outline: none;
    border: 3px solid ${COLORS.BLUE};
    box-sizing: border-box;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-weight: 600;
    color: ${COLORS.BLUE};
  }
`;

const dropdownStringFont = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  background: white;
  border: 3px solid transparent;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  height: -webkit-fill-available;
  padding: 5px;
  text-align-last: center;
  text-align: center;
  text-align: center;
  white-space: pre-line;
  width: 100%;
  width: -webkit-fill-available;
  &:focus {
    outline: none;
    border: 3px solid ${COLORS.BLUE};
    box-sizing: border-box;
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-weight: 600;
    color: ${COLORS.BLUE};
  }
`;

const disabledCell = css`
  height: 120px;
  background: rgb(248, 248, 248);
  border: none;
  border-right: 1px solid rgb(225, 229, 230);
  text-align: center;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: ${COLORS.SMOKE};
  &:first-of-type {
    border-left: 1px solid rgb(225, 229, 230);
  }
`;

const noVariantsFound = css`
  color: ${COLORS.WARNING_RED};
  span {
    font-weight: 600;
  }
`;

export default {
  dropdown,
  dropdownStringFont,
  headerTitle,
  headerLabel,
  input,
  disabledCell,
  table,
  tableBody,
  tableContainer,
  td,
  th,
  thCol,
  thFake,
  tr,
  trCol,
  noVariantsFound,
};
