import React from 'react';
import { PropTypes } from 'prop-types';
import { pillButton } from '../assets/styles/button';

const PillButton = ({ disabled, text, onClick }) => (
  <button css={pillButton} disabled={disabled} onClick={onClick}>
    {text}
  </button>
);

PillButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default PillButton;
