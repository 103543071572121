import React from 'react';

import tableUtils from '../../utilities/table';
import tableStyles from '../../assets/styles/table';
import { Headers } from './Header';
import { NoVariantsFound } from './Warning';
import { Row } from './Row';
import { contactsRx } from '../../utilities/rxTypes';

const Table = ({ connectedUpdateRxValues, rxType, showPrisms, rxVariants, rxState }) => {
  const odProductLine = rxState.od.contacts && rxState.od.contacts.product_line;
  const osProductLine = rxState.os.contacts && rxState.os.contacts.product_line;
  const odProductName = rxState.od.contacts && rxState.od.contacts.product_name;
  const osProductName = rxState.os.contacts && rxState.os.contacts.product_name;

  const isContacts = rxType.includes(contactsRx);
  return (
    <div css={tableStyles.tableContainer}>
      <table css={tableStyles.table}>
        <tbody css={tableStyles.tableBody}>
          <Headers isContacts={isContacts} showPrisms={showPrisms} />
          {tableUtils.columns.map((column, columnIndex) => (
            <Row
              key={columnIndex}
              column={column}
              isContacts={isContacts}
              showPrisms={showPrisms}
              connectedUpdateRxValues={connectedUpdateRxValues}
              rxVariants={rxVariants}
              rxState={rxState}
            />
          ))}
        </tbody>
      </table>
      {!rxVariants.od && odProductLine && (
        <NoVariantsFound
          selectedProductLine={odProductName}
          eye={tableUtils.OD}
        />
      )}
      {!rxVariants.os && osProductLine && (
        <NoVariantsFound
          selectedProductLine={osProductName}
          eye={tableUtils.OS}
        />
      )}
    </div>
  );
};

export default Table;
