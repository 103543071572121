import React from 'react';
import { PropTypes } from 'prop-types';
import { label, labelContainer, modalLabel } from '../assets/styles/label';

export class Label extends React.PureComponent {
  render() {
    const { text, secondaryText = '', isModal = false } = this.props;
    return (
      <div css={labelContainer}>
        <p css={isModal ? modalLabel : label}>{text}</p>
        {secondaryText && <p css={label}>{secondaryText}</p>}
      </div>
    );
  }
}

Label.propTypes = {
  text: PropTypes.string,
};

export default Label;
