import { convertToFormattedArray } from '../utilities/calculateContactVariants';

export interface ContactLensItem {
  id: string;
  productName: string;
  manufacturer: string;
  brand: string;
  productLine: string;
  featureVariants: string[];
  features: {
    adds: string[];
    colors: string[];
    spheres: string[];
    cylinders: string[];
    diameters: string[];
    axises: string[];
    baseCurves: string[];
    dns: string[];
    stockTypes: string[];
    membership: string;
  };
}

export interface PackFeatures {
  features: {
    spheres: string[];
    cylinders: string[];
    axises: string[];
    adds: string[];
    baseCurves: string[];
    colors: string[];
    diameters: string[];
    dns: string[];
    stockTypes: string[];
  };
}

export interface ContactVariantsUnpacked {
  head: string[][];
  body: (string | any | string[])[][];
}

export interface ContactLensVariantSelection {
  add: string;
  axis: string;
  base_curve: string;
  color: string;
  cyl: string;
  diameter: string;
  dn: string;
  power: string;
  variant: number[];
  unpacked_membership: number[][];
  purchase_allow: string;
}

export class ContactVariants {
  private contactVariants: ContactVariantsUnpacked | null;
  private readonly contactLensItem: ContactLensItem;
  private readonly packFeatures: PackFeatures;

  constructor(contactLensItem: ContactLensItem) {
    this.contactVariants = null;
    this.contactLensItem = contactLensItem;

    this.packFeatures = {
      features: {
        spheres: this.contactLensItem.features.spheres,
        cylinders: this.contactLensItem.features.cylinders,
        axises: this.contactLensItem.features.axises,
        adds: this.contactLensItem.features.adds,
        baseCurves: this.contactLensItem.features.baseCurves,
        colors: this.contactLensItem.features.colors,
        diameters: this.contactLensItem.features.diameters,
        dns: this.contactLensItem.features.dns,
        stockTypes: this.contactLensItem.features.stockTypes,
      },
    };
  }

  async toFormattedArray() {
    this.contactVariants = await convertToFormattedArray(
      this.contactLensItem,
      this.packFeatures,
      this.contactLensItem.id,
    );

    return this.contactVariants;
  }

  get variants(): ContactVariantsUnpacked | null {
    return this.contactVariants;
  }
}
