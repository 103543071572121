import isRecord from './isRecord';

const parseErrorToJSON = (value: any) => {
  if (isRecord(value)) {
    return Object.keys(value).reduce((acc, cur) => ({
      ...acc,
      [cur]: parseErrorToJSON(value[cur]),
    }), {});
  }

  return (value instanceof Error ? {
    message: value.message,
    stack: value.stack || '',
    name: value.name,
  } : value);
};

export default parseErrorToJSON;
