import React from 'react';
import { PropTypes } from 'prop-types';
import { msgContainer, warning, error } from '../assets/styles/errorMsg';

const Messages = (msgCategories, msg, msgType) => msgCategories.map((type, typeIndex) => (
  <div key={`${msg}-${msgType}-${typeIndex}`} css={msgContainer}>
    <p>
      {msgType}
      :
      {' '}
      {type.toUpperCase()}
    </p>
    {' '}
    <p>{msg}</p>
  </div>
));

const ErrorMsg = ({ rxVerificationMsgs }) => {
  const { errors, warnings } = rxVerificationMsgs;
  return (
    <div>
      {errors
        && errors.map((msg, msgIndex) => (
          <div css={error} key={msgIndex}>{Messages(msg.keys, msg.message, 'Error')}</div>
        ))}
      {warnings
        && warnings.map((msg, msgIndex) => (
          <div css={warning} key={msgIndex}>{Messages(msg.keys, msg.message, 'Warning')}</div>
        ))}
    </div>
  );
};

ErrorMsg.propTypes = {
  text: PropTypes.string,
};

export default ErrorMsg;
