import { css } from '@emotion/react';

import COLORS from './colors';

export const label = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 24px 0 6px;
`;

export const modalLabel = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 15px 0 6px;
`;

export const labelContainer = css`
  display: flex;
  justify-content: space-between;
`;
