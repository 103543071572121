import React from 'react';

import { loadingContainer } from '../assets/styles/button';

export const Loading = () => (
  <div css={loadingContainer}>
    <div />
    <div />
    <div />
  </div>
);
