import {
  FETCH_ALL_READINGS_SUCCESS,
  FETCH_ALL_READINGS_FAIL,
  FETCH_READING_SUCCESS,
  FETCH_READING_FAIL,
  SELECT_ADD_READING,
  ADD_ANOTHER_READING,
  SET_SELECTED_CARD,
  FETCH_DETAILED_READING_SUCCESS,
} from '../action-types';

import fetchAndParse from './response-parser';
import { nounderscore } from '../utils/actions.utils';
import { FEATURE_DETAILED_READINGCARDS } from '../../utilities/features';

// dispatches from appointments list, saves patient info to topcon store
export function selectAddReading(payload) {
  return function action(dispatch) {
    dispatch({
      type: SELECT_ADD_READING,
      payload,
    });
  };
}

// get all readings
export function getTopconReadings(token, shortName, examRoom, date) {
  // TODO(RETAILAPPS-69): Pass date to API call once Topcon API supports date
  // filtering on the backend.
  return async function action(dispatch) {
    try {
      const res = await fetchAndParse({
        url: `${process.env.TOPCON_API}/offices/${shortName}/exam-rooms/${examRoom}/measurements`,
        method: 'GET',
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      dispatch({
        type: FETCH_ALL_READINGS_SUCCESS,
        payload: {
          measurements: res.measurements,
          date,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_READINGS_FAIL,
        payload: error.message,
      });
    }
  };
}

// get one reading
export function getOneReading(token, shortName, examRoom, measurementName, features = []) {
  return async function action(dispatch) {
    try {
      const res = await fetchAndParse({
        url: `${process.env.TOPCON_API}/offices/${shortName}/exam-rooms/${examRoom}/measurements/${measurementName}`,
        method: 'GET',
        headers: {
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });

      const signal = features.includes(FEATURE_DETAILED_READINGCARDS)
        ? FETCH_DETAILED_READING_SUCCESS
        : FETCH_READING_SUCCESS;

      dispatch({
        type: signal,
        payload: {
          measurement: res,
          measurementName,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_READING_FAIL,
        payload: error.message,
      });
    }
  };
}

// clears previous measurement if user wants to add another topcon reading for same patient
export function addAnotherReading() {
  return function action(dispatch) {
    dispatch({
      type: ADD_ANOTHER_READING,
    });
  };
}

// delete reading (not an action!)
export function deleteReading(token, shortName, examRoom, measurementName) {
  fetch(`${process.env.TOPCON_API}/offices/${shortName}/exam-rooms/${examRoom}/measurements/${measurementName}`, {
    method: 'DELETE',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
}

export function setSelected(s) {
  return function action(dispatch) {
    dispatch({
      type: SET_SELECTED_CARD,
      payload: s,
    });
  };
}
