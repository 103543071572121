import React from 'react';
import rxTableStyles from '../../assets/styles/rxTable';
import Label from '../../shared-components/Label';
import { biString, eyeArray, OD, pdTypes } from '../../utilities/rxTable';

export class PupillaryDistance extends React.PureComponent {
  render() {
    const { connectedUpdatePdValues, pdValues } = this.props;
    const { od, os, bi } = pdValues;
    const hasMonocularPdValues = od || os;
    const hasBinocularPdValues = bi;
    return (
      <div>
        <div css={rxTableStyles.headerContainer}>
          <h2 css={rxTableStyles.rxTableHeader}>Pupillary Distance</h2>
        </div>
        <div css={rxTableStyles.pdInputsContainer}>
          <div>
            <Label text={pdTypes.monocular} />
            {eyeArray.map(eye => (
              <div
                key={eye}
                css={
                  eye === OD
                    ? rxTableStyles.pdMonocularOdStaticText
                    : rxTableStyles.pdMonocularOsStaticText
                }
              >
                <input
                  defaultValue={eye === OD ? od : os}
                  placeholder="00"
                  type="number"
                  step="0.1"
                  min="0"
                  max="99"
                  maxLength="4"
                  css={rxTableStyles.pdMonocularInputs}
                  onChange={e => connectedUpdatePdValues(e.target.value, eye.toLowerCase())
                  }
                  disabled={hasBinocularPdValues}
                />
              </div>
            ))}
          </div>
          <div css={rxTableStyles.pdOrText}>- or -</div>
          <div css={rxTableStyles.marginLeft}>
            <Label text={pdTypes.binocular} />
            <div css={rxTableStyles.pdBinocularStaticText}>
              <input
                defaultValue={bi || ''}
                maxLength="4"
                type="number"
                step="0.1"
                min="0"
                max="99"
                placeholder="00"
                css={rxTableStyles.pdBinocularInputs}
                onChange={e => connectedUpdatePdValues(e.target.value, biString)
                }
                disabled={hasMonocularPdValues}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
