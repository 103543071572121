import React, { Fragment } from 'react';
import tableStyles from '../../assets/styles/table';
import tableUtils from '../../utilities/table';
import { useAppSelector } from '../../hooks';
import { MULTIFOCAL_CONTACTS_FEATURE_FLAG } from '../../utilities/features';
import auth from '../../redux/selectors/AuthSelectors';

export class Header extends React.PureComponent {
  render() {
    const { header } = this.props;
    return (
      <th
        key={`${header.headerLabel}-${header.headerTitle}`}
        css={tableStyles.th}
      >
        <span css={tableStyles.headerTitle}>{header.headerTitle}</span>
        <span css={tableStyles.headerLabel}>{header.headerLabel}</span>
      </th>
    );
  }
}

const HeaderList = ({ headers }) => headers.map((header, headerIndex) => (
  <Header key={headerIndex} header={header} />));

const ContactsHeaders = () => {
  const canDisplayMultifocalContacts = useAppSelector(auth.featureSelector(MULTIFOCAL_CONTACTS_FEATURE_FLAG));

  return (
    <Fragment>
      <HeaderList key="contacts-input-headers" headers={tableUtils.contactsInputHeaders} />
      <HeaderList
        key="contacts-dropdown-headers"
        headers={tableUtils.contactsDropdownHeaders(canDisplayMultifocalContacts)}
      />
    </Fragment>
  );
};

const GlassesHeaders = ({ showPrisms }) => (
  <Fragment>
    <HeaderList key="glasses-input-headers" headers={tableUtils.glassesHeaders} />
    {showPrisms && <HeaderList key="glasses-prism-headers" headers={tableUtils.glassesPrismHeaders} />}
  </Fragment>
);

export class Headers extends React.PureComponent {
  render() {
    const { isContacts, showPrisms } = this.props;
    return (
      <tr css={tableStyles.tr}>
        <th css={tableStyles.thFake} />
        {isContacts && <ContactsHeaders />}
        {!isContacts && <GlassesHeaders showPrisms={showPrisms} />}
      </tr>
    );
  }
}
