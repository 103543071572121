export const getSlotsFromAllAppointments = res => res.appointments;

export const getAppointmentProfileTypes = res => res.appointment_profiles;

export const getUID = res => res.uid;

export const getExpirationDate = res => res.expiration_date;

const sort = map =>
  Array.from(map.entries()).sort((a, b) => a[0].localeCompare(b[0]));

const nestMapsToSortedArrays = map =>
  sort(map).map(([key, child]) => [key, sort(child)]);

const parseContactsProduct = (product) => {
  const product_line_cat =
    product.categories.find(i => i.type_id === 'CONTACT_SPEC_LINE') || {};
  const product_line_good =
    product.good_ids.find(i => i.type_id === 'PC_SPECIFIC_PL_ID') || {};
  const product_line_good_id = parseInt(product_line_good.id_value, 10) || null;

  const brand_name = product.brand_name || '';
  const product_line_name = product_line_cat.name || '';
  const product_line_id = product_line_good_id;

  if (!brand_name && !product_line_name) {
    console.error('Found some bad contacts data', product);
  }

  return { brand_name, product_line_name, product_line_id };
};

const prioritizeWP = (arr) => {
  // find WP brand in array of sorted brands and return a new array with WP first in list
  const idx = arr.findIndex(brand => brand[0] === 'Warby Parker');
  if (idx === -1) return arr;
  const nonWPBrands = [...arr.slice(0, idx), ...arr.slice(idx + 1)];
  return [arr[idx], ...nonWPBrands];
};

export const groupByBrand = (products) => {
  const brands = new Map();
  products.forEach((product) => {
    const { brand_name, product_line_id, product_line_name } =
      parseContactsProduct(product);
    if (!brand_name || !product_line_name) return;
    const brand = brands.get(brand_name) || new Map();
    brand.set(product_line_name, product_line_id);
    brands.set(brand_name, brand);
  });

  const sortedBrands = nestMapsToSortedArrays(brands);
  return prioritizeWP(sortedBrands);
};

export const splitProductlineAndID = (productLine) => {
  const splitString = productLine.split('-');
  // We are splitting productLineName by "-"
  // However, some productLineNames come through as:
  // ex: 3138-1-Day-Acuvue
  if (splitString.length > 2) {
    let productLineRebuilt = '';
    for (let i = 1; i < splitString.length; i += 1) {
      productLineRebuilt += i === 1 ? `${splitString[i]}` : `-${splitString[i]}`;
    }
    return {
      product_line: splitString[0],
      product_name: productLineRebuilt,
    };
  }
  const [product_line, product_name] = splitString;
  return { product_line, product_name };
};

export const objectifyPrism = (prismVals) => {
  if (prismVals) {
    const strippedVals = prismVals.replace(/\s/g, '');
    const [prism, base] = strippedVals.match(/[a-z]+|[^a-z]+/gi);
    return { prism, base };
  }
  return null;
};

export const getPCIDs = (productArray) => {
  if (productArray.length === 0) {
    return undefined;
  }

  return productArray.map(x => x.pc_product_id);
};

// checks if all object keys are empty arrays
export const allEmpty = obj =>
  Object.keys(obj).every(key => obj[key].length === 0);

// this function will take an array of variant objects
// e.g. [{ diameter: "+1.25", color: "blue"...}, { diameter: "+1.50", color: "blue"}]
// and organize all possible variations of an rx to an object
// each variant type will have an array of values tied to that type
export const arrToObj = (arr) => {
  const ADD = 'add';
  const ADD_POWER = 'add_power';
  const CYL = 'cyl';
  const CYLINDER = 'cylinder';
  const y = {
    diameter: [],
    base_curve: [],
    add_power: [],
    axis: [],
    color: [],
    cylinder: [],
    dn: [],
  };
  arr.forEach((obj) => {
    if (Array.isArray(obj)) {
      const newObject = arrToObj(obj);
      y.diameter = [...new Set(y.diameter.concat(newObject.diameter))];
      y.base_curve = [...new Set(y.base_curve.concat(newObject.base_curve))];
      y.add_power = [...new Set(y.add_power.concat(newObject.add_power))];
      y.axis = [...new Set(y.axis.concat(newObject.axis))];
      y.color = [...new Set(y.color.concat(newObject.color))];
      y.cylinder = [...new Set(y.cylinder.concat(newObject.cylinder))];
      y.dn = [...new Set(y.dn.concat(newObject.dn))].filter(value => value);
    } else {
      Object.keys(obj).forEach((key) => {
        if (key === ADD) {
          if (y[ADD_POWER].length === 0) {
            y[ADD_POWER] = [obj[key]];
          } else if (y[ADD_POWER].indexOf(obj[key]) < 0) {
            y[ADD_POWER] = y[ADD_POWER].concat([obj[key]]);
          }
        }
        if (key === CYL) {
          if (y[CYLINDER].length === 0) {
            y[CYLINDER] = [obj[key]];
          } else if (y[CYLINDER].indexOf(obj[key]) < 0) {
            y[CYLINDER] = y[CYLINDER].concat([obj[key]]);
          }
        }
        if (Object.prototype.hasOwnProperty.call(y, key)) {
          if (y[key].length === 0) {
            y[key] = [obj[key]];
          } else if (y[key].indexOf(obj[key]) < 0) {
            y[key] = y[key].concat([obj[key]]);
          }
        }
      });
    }
  });
  if (allEmpty(y)) return null;
  return y;
};

export const emptyStringToNull = (string) => {
  if (string === '') {
    return null;
  }
  return string;
};
