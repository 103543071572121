/* eslint-disable @typescript-eslint/unbound-method */
import createValidator from './create-validator';
import * as rules from './rules';
import { Rule } from './types';

export const WARN = 'WARN';
export const ERROR = 'ERROR';

// Rules which are mostly detached from internal business logic or must be valid
// for most cases.
// Rules listed here must agree with the follow criteria:
// 1. It's not related to internal business logic
// 2. It validates fields which are mandatory to other parts of the application
// 3. It won't break the already existing RX inputs.
const primitiveRxRules: Rule[] = [
  // Expiration Date, used by other criteria and internal parts of app
  [ERROR, rules.hasExpirationDate],
  [ERROR, rules.expirationDateIsNotInThePast],
  // General RX rules, which are common for prescriptions and other rx inputs.
  [ERROR, rules.axisIsNotGreaterThan180],
  [ERROR, rules.basePresentIfPrismPresent],
  [ERROR, rules.base2PresentIfPrism2Present],
  [ERROR, rules.prismPresentIfBasePresent],
  [ERROR, rules.prism2PresentIfBase2Present],
];

// Rules which might change time-to-time due to internal business logic.
// Still in progress to validate all the rules.
const rxRules: Rule[] = [
  [ERROR, rules.sphPresent],
  [ERROR, rules.sphFormat],
  [ERROR, rules.cylFormat],
  [ERROR, rules.cylOpposing],
  [ERROR, rules.cylPresentIfAxisPresent],
  [ERROR, rules.cylMinorIsQuarted],
  [ERROR, rules.axisPresentIfCylPresent],
  [ERROR, rules.axisIsGreaterThanZeroIfCyl],
  [ERROR, rules.addFormat],
  [ERROR, rules.addRange],
  [ERROR, rules.multiFocalHasAdd],
  [ERROR, rules.multifocalDioptersSumLessThanFive],
  [ERROR, rules.multifocalDioptersSumGreaterThanZero],
  [ERROR, rules.singleVisionDioptersSumLessThanSix],
  [ERROR, rules.singleVisionDioptersSumGreaterThanZero],
  [ERROR, rules.baseInOut],
  [ERROR, rules.baseUpDown],
  [ERROR, rules.baseSameBothEyes],
  [ERROR, rules.contactsBrandPresent],
  [ERROR, rules.contactsDiameterPresent],
  [ERROR, rules.contactsBaseCurvePresent],
  [ERROR, rules.cylinderRequiredIfManyVariants],
  [ERROR, rules.axisRequiredIfManyVariants],
  [ERROR, rules.addPowerRequiredIfManyVariants],
  [ERROR, rules.colorRequiredIfManyVariants],
  [ERROR, rules.dnRequiredIfManyVariants],
  [WARN, rules.sphereMinThreshold],
  [WARN, rules.sphereMaxThreshold],
  [WARN, rules.cylRange],
  [WARN, rules.combinedSphCylRange],
  [WARN, rules.sphOpposing],
  [WARN, rules.sphVariability],
  [WARN, rules.cylVariability],
  [WARN, rules.onlyOneAdd],
  [WARN, rules.matchingAdd],
  ...primitiveRxRules,
];

const validator = createValidator(
  [ERROR, rules.bothMonoPdAdded],
  [ERROR, rules.monoPdInRange],
  [ERROR, rules.biPdInRange],
  [WARN, rules.shouldHavePd],
  [WARN, rules.pdVariability],
  ...rxRules,
);

export default validator.validate;
