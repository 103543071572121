import {
  RxFrontendValidationMessages,
  RxToValidate,
  RxToVerify,
  RxVariants,
  RxVariantsToValidate,
  RxVerificationMessages,
  ValidationResult,
} from './types';
import { ERROR, WARN } from './index';

export const getRxForValidation = (
  rxToVerify: RxToVerify,
  features: string[],
  rxVariants: RxVariants,
): RxToValidate => {
  const rxVariantsToValidate: RxVariantsToValidate = {
    od: {
      axis: rxVariants?.od?.axis || [],
      cylinder: rxVariants?.od?.cylinder || [],
      addPower: rxVariants?.od?.add_power || [],
      color: rxVariants?.od?.color || [],
      dn: rxVariants?.od?.dn || [],
      baseCurve: rxVariants?.od?.base_curve || [],
      diameter: rxVariants?.od?.diameter || [],
    },
    os: {
      axis: rxVariants?.os?.axis || [],
      cylinder: rxVariants?.os?.cylinder || [],
      addPower: rxVariants?.os?.add_power || [],
      color: rxVariants?.os?.color || [],
      dn: rxVariants?.os?.dn || [],
      baseCurve: rxVariants?.os?.base_curve || [],
      diameter: rxVariants?.os?.diameter || [],
    },
  };

  return {
    category: rxToVerify.category,
    biPd: rxToVerify.bi_pd,
    odPd: rxToVerify.od_pd,
    osPd: rxToVerify.os_pd,
    expirationDate: rxToVerify.expiration_date,
    correctionType: rxToVerify.correction_type,
    odAdd: rxToVerify.od_add,
    osAdd: rxToVerify.os_add,
    odSphere: rxToVerify.od_sphere,
    osSphere: rxToVerify.os_sphere,
    odCylinder: rxToVerify.od_cylinder,
    osCylinder: rxToVerify.os_cylinder,
    odAxis: rxToVerify.od_axis,
    osAxis: rxToVerify.os_axis,
    odPrismBase: rxToVerify.od_prism_direction,
    osPrismBase: rxToVerify.os_prism_direction,
    odPrism: rxToVerify.od_prism_value,
    osPrism: rxToVerify.os_prism_value,
    odPrismBase2: rxToVerify.od_prism_co_direction,
    osPrismBase2: rxToVerify.os_prism_co_direction,
    odPrism2: rxToVerify.od_prism_co_value,
    osPrism2: rxToVerify.os_prism_co_value,
    odContactProduct: rxToVerify.od_contact_product,
    osContactProduct: rxToVerify.os_contact_product,
    odContactDiameter: rxToVerify.od_contact_diameter,
    osContactDiameter: rxToVerify.os_contact_diameter,
    odContactBaseCurve: rxToVerify.od_contact_base_curve,
    osContactBaseCurve: rxToVerify.os_contact_base_curve,
    odContactColor: rxToVerify.od_contact_color,
    osContactColor: rxToVerify.os_contact_color,
    odContactDn: rxToVerify.od_contact_dn,
    osContactDn: rxToVerify.os_contact_dn,
    features,
    rxVariants: rxVariantsToValidate,
  };
};

export const getErrorMessageForRxValidation = (results: ValidationResult): RxFrontendValidationMessages => ({
  errors: results.messages.filter(item => item.type === ERROR).map(item => (
    {
      keys: [''],
      message: item.msg,
    }
  )),
  warnings: results.messages.filter(item => item.type === WARN).map(item => (
    {
      keys: [''],
      message: item.msg,
    }
  )),
});

export const canShowRxFrontendValidationMessage = (
  isRxValidOnFrontend: boolean,
  rxVerificationMsgs: RxVerificationMessages | null,
  rxFrontendValidationMessages: RxFrontendValidationMessages,
) => {
  if (!isRxValidOnFrontend) {
    if (!rxVerificationMsgs) {
      return true;
    }

    return !(rxVerificationMsgs.errors?.length || rxVerificationMsgs.pdErrors?.length);
  }

  return rxFrontendValidationMessages.warnings?.length > 0;
};
