import { Base64 } from 'js-base64';
import {
  ContactLensItem,
  ContactVariantsUnpacked,
  PackFeatures,
} from '../types/pim-contact-variants';

const featureOrder = [
  'adds',
  'colors',
  'spheres',
  'cylinders',
  'diameters',
  'axises',
  'baseCurves',
  'stockTypes',
  'dns',
];

function getCombinedFeatures(pack: PackFeatures) {
  const { features } = pack;
  return featureOrder.map(featureName => features[featureName]);
}

function base64ToBooleanArray(
  membership: string,
  totalNumberOfCombinations: number,
): boolean[][] {
  const decodedData = Base64.atob(membership);
  const mask = Array.from({ length: 8 }, (_, i) => 2 ** i);
  const boolArrayToCompare = new Array(totalNumberOfCombinations).fill(true);

  let i = 0;

  Array.from(decodedData).forEach((line) => {
    const byte = line.charCodeAt(0);
    for (let j = 0; j < mask.length; j += 1) {
      // eslint-disable-next-line no-bitwise
      boolArrayToCompare[i + j] = (byte & mask[j]) === mask[j];
    }
    i += mask.length;
  });

  return boolArrayToCompare;
}

function getIndicesLengthArray(pack: PackFeatures): number[] {
  const combinedFeatures = getCombinedFeatures(pack);
  return combinedFeatures.map(({ length }) => length);
}

export function calcIndex(
  variant: number[],
  indicesLengthArray: number[],
): number {
  return variant.reduce(
    // eslint-disable-next-line no-mixed-operators
    (acc, curr, idx) => acc * indicesLengthArray[idx] + curr,
    0,
  );
}

function generateArrayOfIndicesByFeature(
  pack: PackFeatures,
): { arrayOfIndicesByFeature: number[][], indicesLengthArray: number[] } {
  const indicesLengthArray = getIndicesLengthArray(pack);
  const arrayOfIndicesByFeature = indicesLengthArray.map(val => Array.from(
    Array(val).keys(),
    index => index,
  ));

  return { arrayOfIndicesByFeature, indicesLengthArray };
}

function allVariants(arrOfIndicesByFeature: number[][]): number[][] {
  return arrOfIndicesByFeature.reduce<number[][]>(
    (result, featureOptions) =>
      result
        .map(prevCombinations =>
          featureOptions.map(option => prevCombinations.concat([option])),
        )
        .flatMap(v => v),
    [[]],
  );
}

function getFeatureIndex(name: string) {
  return featureOrder.indexOf(name);
}

function mapVariantIndexToVariantValues(
  variants: number[][],
  pack: PackFeatures,
  virtualPcProductId: string,
  indicesLengthArray: number[],
): ContactVariantsUnpacked {
  const { features } = pack;

  const { spheres, cylinders, axises, adds, baseCurves, colors, diameters, dns } = features;
  const head = [
    ['virtual_pc_product_id', 'int4'],
    ['power', 'varchar'],
    ['cyl', 'varchar'],
    ['axis', 'int4'],
    ['dn', 'varchar'],
    ['add', 'varchar'],
    ['base_curve', 'varchar'],
    ['diameter', 'varchar'],
    ['color', 'varchar'],
    ['purchase_allow', 'varchar[]'],
    ['variant_index', 'int4'],
  ];

  const colorsFeatureValue = variant => (
    colors[variant[getFeatureIndex('colors')]] === 'Clear'
      ? null
      : colors[variant[getFeatureIndex('colors')]]
  );

  const dnFeatureValue = variant => (
    dns[variant[getFeatureIndex('dns')]] === 'U'
      ? null
      : dns[variant[getFeatureIndex('dns')]]
  );

  const body = variants.map(variant => [
    Number(virtualPcProductId),
    spheres[variant[getFeatureIndex('spheres')]],
    cylinders[variant[getFeatureIndex('cylinders')]],
    axises[variant[getFeatureIndex('axises')]],
    dnFeatureValue(variant),
    adds[variant[getFeatureIndex('adds')]],
    baseCurves[variant[getFeatureIndex('baseCurves')]],
    diameters[variant[getFeatureIndex('diameters')]],
    colorsFeatureValue(variant),
    ['RETAIL_US_PURC_ALLOW'],
    calcIndex(variant, indicesLengthArray),
  ]);

  return { head, body };
}

export function getTotalNumberOfCombinations(pack: PackFeatures): number {
  return getIndicesLengthArray(pack).reduce(
    (packIdxLen, currIdx) => packIdxLen * currIdx,
    1,
  );
}

export function convertToFormattedArray(
  contactLensItem: ContactLensItem,
  packFeatures: PackFeatures,
  virtualPcProductId: string,
): Promise<ContactVariantsUnpacked> {
  const { membership } = contactLensItem.features;
  const totalNumberOfCombinations = getTotalNumberOfCombinations(packFeatures);
  const boolArray = base64ToBooleanArray(membership, totalNumberOfCombinations);
  const {
    arrayOfIndicesByFeature,
    indicesLengthArray,
  } = generateArrayOfIndicesByFeature(packFeatures);
  const validVariants = allVariants(arrayOfIndicesByFeature).filter((_, i: number) => boolArray[i]);

  return mapVariantIndexToVariantValues(
    validVariants,
    packFeatures,
    virtualPcProductId,
    indicesLengthArray,
  );
}

