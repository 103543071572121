import { ActionCreatorsMapObject, AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

/**
 * Used for refactoring uses of bindActionsCreators with hooks.
 * @see https://react-redux.js.org/api/hooks#recipe-useactions
 * Omitting the case for an array of actions as we do not have this use case.
 */
export default (actions: ActionCreatorsMapObject<unknown>, deps?: unknown[]) => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch],
  );
};
