import { createSelector } from 'reselect';
import { getRxEntryState } from './getStates';
import { contactsRx } from '../../utilities/rxTypes';

const rxEntryStateSelector = createSelector(
  getRxEntryState,
  rxEntry => rxEntry,
);

const expirationSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.expiration,
);

const inputExpirationSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.inputExpiration,
);

const useInputExpirationSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.useInputExpiration,
);

const notesSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.notes,
);

const rxStateSelector = createSelector(rxEntryStateSelector, rxEntry => ({
  os: rxEntry.rx_os,
  od: rxEntry.rx_od,
}));

const patientSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.patient,
);

const rxTypeSelectedSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.prescriptionType,
);

const rxUsageSelectionsSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.distance || rxEntry.progressiveRanges,
);

const expirationLoggedSelector = createSelector(
  [expirationSelector, inputExpirationSelector],
  (expiration, inputExpiration) => expiration || inputExpiration,
);

const rxTypeStatusSelector = createSelector(
  [rxTypeSelectedSelector, rxUsageSelectionsSelector, expirationLoggedSelector],
  (typeSelected, rxUsageSelections, expirationLogged) => {
    let allCardsSelected = null;
    const glassesSelections =
      typeSelected && expirationLogged && rxUsageSelections;
    const contactsSelections = typeSelected && expirationLogged;

    if (typeSelected) {
      allCardsSelected = typeSelected.includes(contactsRx)
        ? contactsSelections
        : glassesSelections;
    }
    return allCardsSelected;
  },
);

const additionalRxDetailsSelector = createSelector(
  [
    expirationSelector,
    inputExpirationSelector,
    rxEntryStateSelector,
    notesSelector,
    useInputExpirationSelector,
  ],
  (expiration, inputExpiration, rxEntry, notes, useInputExpiration) => ({
    expiration,
    expirationDate: rxEntry.expirationDate,
    inputExpiration,
    notes,
    pdValues: rxEntry.pd,
    rxType: rxEntry.prescriptionType,
    showPrisms: rxEntry.showPrisms,
    useInputExpiration,
  }),
);

const rxTypesSelector = createSelector(
  [expirationSelector, rxEntryStateSelector],
  (expiration, rxEntry) => ({
    prescriptionType: rxEntry.prescriptionType,
    correctionType: rxEntry.correctionType,
    distance: rxEntry.distance,
    progressiveRanges: rxEntry.progressiveRanges,
    expiration,
  }),
);

const odSelectedContactsProductLineSelector = createSelector(
  rxEntryStateSelector,
  rxEntry =>
    `${rxEntry.rx_od.contacts.product_line}-${rxEntry.rx_od.contacts.product_name}`,
);

const osSelectedContactsProductLineSelector = createSelector(
  rxEntryStateSelector,
  rxEntry =>
    `${rxEntry.rx_os.contacts.product_line}-${rxEntry.rx_os.contacts.product_name}`,
);

const showPrismsSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.showPrisms,
);

const pdValuesSelector = createSelector(
  rxEntryStateSelector,
  rxEntry => rxEntry.pd,
);

const RxEntry = {
  rxEntryStateSelector,
  expirationSelector,
  inputExpirationSelector,
  useInputExpirationSelector,
  notesSelector,
  patientSelector,
  rxStateSelector,
  rxTypeSelectedSelector,
  rxUsageSelectionsSelector,
  expirationLoggedSelector,
  rxTypeStatusSelector,
  additionalRxDetailsSelector,
  rxTypesSelector,
  odSelectedContactsProductLineSelector,
  osSelectedContactsProductLineSelector,
  showPrismsSelector,
  pdValuesSelector,
};

export default RxEntry;
