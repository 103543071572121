import { css } from '@emotion/react';

import COLORS from './colors';

const modalContainer = css`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100vh;
  width: 100vw;
  bottom: 0%;
  overflow: auto;
`;

const modal = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  left: 50%;
  max-height: 890px;
  padding: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 708px;

  h1 {
    color: ${COLORS.CHARCOAL};
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    text-align: center;
  }
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  width: 100%;
`;

const widthSetter = css`
  display: flex;
  width: 708px;
  margin: 30px auto 0;
`;

const th = css`
  background: rgb(248, 248, 248);
  border: none;
  border-top: 1px solid rgb(225, 229, 230);
  border-bottom: 1px solid rgb(225, 229, 230);
  border-right: 1px solid rgb(225, 229, 230);
  &:first-of-type {
    border-left: 1px solid rgb(225, 229, 230);
  }
  padding: 17px 6px;
`;

const headerTitle = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: block;
  text-align: center;
`;

const thFake = css`
  border: none;
  border-right: 1px solid rgb(225, 229, 230);
`;

const trCol = css`
  border-left: 1px solid rgb(225, 229, 230);
  border-bottom: 1px solid rgb(225, 229, 230);
`;

const thCol = css`
  background: rgb(248, 248, 248);
  height: 90px;
  width: 90px;
`;

const headerLabel = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: block;
  text-align: center;
`;

const tableBody = css`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;

const table = css`
  border-collapse: collapse;
  font-family: proxima-nova, sans-serif;
  border-spacing: 0px;
  width: 100%;
`;

const tableContainer = css`
  width: inherit;
  max-width: 748px;
  width: 100%;
  display: table;
  text-align: center;
  margin: 20px auto 0;
`;

const td = css`
  background: rgb(248, 248, 248);
  border: none;
  border-right: 1px solid rgb(225, 229, 230);
  height: 90px;
  width: 90px;
  font-size: 20px;
  line-height: 28px;
  &:first-of-type {
    border-left: 1px solid rgb(225, 229, 230);
  }
`;

const tr = css`
  border-bottom: 1px solid rgb(225, 229, 230);
`;

const notes = css`
  background: rgb(248, 248, 248);
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin: 5px 0 0 0;
  padding: 21px 24px;
  width: 100%;
`;

const longPill = css`
  background: rgb(248, 248, 248);
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin: 5px 10px 5px 0;
  padding: 21px 24px;
  width: 100%;
`;

const shortPill = css`
  background: rgb(248, 248, 248);
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  margin: 5px 10px 5px 0;
  padding: 21px 24px;
  width: 100%;
`;

const patientInfo = css`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const contactsInfo = css`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const longPillContainer = css`
  width: 318px;
  margin-right: 10px;
`;

const shortPillContainer = css`
  width: 156px;
  margin-right: 10px;
`;

export default {
  buttonContainer,
  contactsInfo,
  headerLabel,
  headerTitle,
  longPill,
  longPillContainer,
  modal,
  modalContainer,
  notes,
  patientInfo,
  shortPill,
  shortPillContainer,
  table,
  tableBody,
  tableContainer,
  td,
  th,
  thCol,
  thFake,
  tr,
  trCol,
  widthSetter,
};
