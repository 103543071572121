import React from 'react';

import rxTableStyles from '../../assets/styles/rxTable';
import { disclaimerCopy } from '../../utilities/rxTable';

export class PrismDisclaimer extends React.PureComponent {
  render() {
    return (
      <div css={rxTableStyles.prismDisclaimer}>
        <p>
          <b>*NOTE:</b>
          {' '}
          We can only accept prism values written in: 0.00 B_
        </p>
        <ul>
          <li>0.00 BU = Base up</li>
          <li>0.00 BD = Base down</li>
          <li>0.00 BI = Base in</li>
          <li>0.00 BO = Base out</li>
        </ul>
      </div>
    );
  }
}

export class Disclaimer extends React.PureComponent {
  render() {
    return <p css={rxTableStyles.disclaimerText}>{disclaimerCopy}</p>;
  }
}
