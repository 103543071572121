import React from 'react';
import { PropTypes } from 'prop-types';
import { cancelButton } from '../assets/styles/button';
import { Loading } from './Loading';

const RedButton = ({ disabled, text, onClick, pending }) => (
  <button css={cancelButton} disabled={disabled} onClick={onClick}>
    {pending ? <Loading /> : text}
  </button>
);

RedButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default RedButton;
