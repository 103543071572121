import { css } from '@emotion/react';

import COLORS from './colors';

const rxTableContainer = css`
  max-width: 748px;
  margin: 0 auto;
`;

const contactsSelectorContainer = css`
  display: flex;
`;

const headerContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
`;

const prismButtonContainer = css`
  display: flex;
  justify-content: flex-end;
  margin: 26px 0;
`;

const rxTableHeader = css`
  color: ${COLORS.CHARCOAL};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
`;

const disclaimerText = css`
  color: ${COLORS.SMOKE};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

const notes = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 21px 24px;
  width: 100%;
  &:placeholder {
    color: ${COLORS.SMOKE};
  }
`;

const pdMonocularInputs = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: ${COLORS.CHARCOAL};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 21px 24px;
  width: 162px;
  &:first-of-type {
    margin-right: 18px;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${COLORS.SMOKE};
  }
  &:disabled {
    background-color: ${COLORS.SILK};
    cursor: not-allowed;
  }
`;

const pdBinocularInputs = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: ${COLORS.CHARCOAL};
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 21px 24px;
  width: 222px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.24);
  }
  &::placeholder {
    color: ${COLORS.SMOKE};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: ${COLORS.SILK};
    cursor: not-allowed;
  }
`;

const pdBinocularStaticText = css`
  position: relative;
  &:after {
    content: "mm (OU)";
    position: absolute;
    right: 24px;
    top: 22px;
    color: ${COLORS.SMOKE};
  }
`;

const pdMonocularOdStaticText = css`
  position: relative;
  display: inline-block;
  &:after {
    content: "mm (OD)";
    position: absolute;
    right: 35px;
    top: 23px;
    color: ${COLORS.SMOKE};
  }
`;

const pdMonocularOsStaticText = css`
  position: relative;
  display: inline-block;
  &:after {
    content: "mm (OS)";
    position: absolute;
    right: 35px;
    top: 23px;
    color: ${COLORS.SMOKE};
  }
`;

const buttonContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const readingButton = css`
  margin: 0 0.5em;
  width: 30%;
`;

const pdInputsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const pdOrText = css`
  height: 100px;
  line-height: 150px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: ${COLORS.SMOKE};
`;

const marginLeft = css`
  margin-left: 18px;
`;

const prismDisclaimer = css`
  width: 30%;
  color: #a1a4a6;
  font-family: proxima-nova, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;

  ul {
    list-style: none;
    padding: 0;
  }
`;

export default {
  readingButton,
  buttonContainer,
  notes,
  rxTableContainer,
  contactsSelectorContainer,
  headerContainer,
  prismButtonContainer,
  rxTableHeader,
  disclaimerText,
  pdMonocularInputs,
  pdBinocularInputs,
  pdInputsContainer,
  pdOrText,
  pdBinocularStaticText,
  pdMonocularOsStaticText,
  pdMonocularOdStaticText,
  marginLeft,
  prismDisclaimer,
};
