import { css } from '@emotion/react';

import COLORS from './colors';

export const errorContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: ${COLORS.WARNING_RED};
    font-weight: 600;
  }
`;
