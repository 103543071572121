import { css } from '@emotion/react';

import COLORS from './colors';

export const selectedCard = css`
  background: #fff;
  border: 3px solid ${COLORS.BLUE};
  box-sizing: border-box;
  border-radius: 18px;
  margin: 0 18px 0 0;
  outline: none;
  padding: 33px 16px;
  position: relative;
  width: 100%;
  span {
    color: ${COLORS.BLUE};
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  img {
    display: block;
    margin: 0 auto 18px;
  }
  &:last-of-type {
    margin: 0;
  }
`;

export const borderFix = css`
  border: 3px solid transparent;
  display: inherit;
  position: inherit;
  width: inherit;
  box-sizing: border-box;
`;

export const card = css`
  background: #fff;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  border-radius: 18px;
  margin: 0 18px 0 0;
  outline: none;
  padding: 33px 16px;
  position: relative;
  width: 100%;

  span {
    color: ${COLORS.BLACK};
    font-family: proxima-nova, Helvetica, Verdana, sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
  img {
    display: block;
    margin: 0 auto 18px;
    filter: grayscale(1);
  }

  &:hover {
    transition: all 0.2s ease;
    transition: border 0.2s ease;
    border: 3px solid ${COLORS.BLUE};
    border-radius: 18px;
    span {
      color: ${COLORS.BLUE};
      font-weight: 600;
    }
    img {
      display: block;
      margin: 0 auto 18px;
      filter: grayscale(0);
    }
  }

  &:disabled,
  &:disabled:hover {
    background: ${COLORS.SILK};
    border: 1px solid #e1e5e6;
    color: ${COLORS.SMOKE};
    span {
      color: ${COLORS.SMOKE};
      font-weight: 400;
    }
    img {
      filter: grayscale(1);
    }
  }
  &:last-of-type {
    margin: 0;
  }
`;

export const checkbox = css`
  -webkit-appearance: none;
  background-size: cover;
  background: #fff;
  border: 1px solid #e1e5e6;
  border-radius: 100%;
  cursor: pointer;
  height: 24px;
  margin: 0 5px;
  position: absolute;
  right: -1px;
  top: 4px;
  transition: all 0.2s ease;
  transition: border 0.2s ease;
  width: 24px;

  &:hover:not(disabled),
  &:checked {
    background: ${COLORS.BLUE} url(/src/assets/img/check.svg) center no-repeat;
    border: 1px solid ${COLORS.BLUE};
    border-radius: 100%;
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &:disabled:hover {
    background: ${COLORS.SILK};
    border: 1px solid #e1e5e6;
    border-radius: 100%;
    box-sizing: border-box;
    color: ${COLORS.SMOKE};
  }
`;
