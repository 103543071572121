import React from 'react';
import tableStyles from '../../assets/styles/table';

export class NoVariantsFound extends React.PureComponent {
  render() {
    const { selectedProductLine, eye } = this.props;

    return (
      <p css={tableStyles.noVariantsFound}>
        <span>Warning:</span>
        {` No possible combinations found for ${eye} productline ${selectedProductLine}.`}
      </p>
    );
  }
}
