import { css } from '@emotion/react';
import COLORS from './colors';

const caretRightIcon = css`
  color: ${COLORS.BLUES_BLUE};
  display: flex;
  margin: 0px 6px;
  font-size: 12px;
`;

const locationIcon = css`
  font-size: 24px;
  display: flex;
  color: ${COLORS.SMOKE};
  margin-right: 6px;
`;

const storeName = css`
  color: ${COLORS.CHARCOAL};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;

const wrapper = css`
  display: flex;
  margin: 24px 25px;
  text-decoration: none;
`;

const link = css`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default {
  caretRightIcon,
  link,
  locationIcon,
  storeName,
  wrapper,
};
