const COLORS = {
  BLUE: '#00a2e1',
  CHARCOAL: '#414b56',
  SILK: '#f8f8f8',
  SMOKE: '#a1a4a6',
  STONE: '#676f78',
  WARNING_ORANGE: '#e47c4c',
  WARNING_RED: '#d6003c',
  DARK_BLUE: '#0089BF',
  BLUE_HOVER: '#3DBDEE',
  BLUES_BLUE: '#006EA5',
};

export default COLORS;
