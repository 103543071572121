import React from 'react';
import { PropTypes } from 'prop-types';

import { errorContainer } from '../assets/styles/error';

const Error = ({ error }) => (
  <div css={errorContainer}>
    <p>
      Oops! Something went wrong:
      {' '}
      <span>{error}</span>
    </p>
    <p>Please try again later</p>
  </div>
);

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
