import { createSelector } from 'reselect';
import { getAuthState } from './getStates';

const authSelector = createSelector(
  getAuthState,
  authState => authState,
);

const meSelector = createSelector(
  authSelector,
  authState => authState.me,
);

const tokenSelector = createSelector(
  authSelector,
  authState => authState.jwt,
);

const featuresSelector = createSelector(
  meSelector,
  meState => meState?.features || [],
);

const featureSelector = (feature: string) => createSelector(
  featuresSelector,
  featuresState => (featuresState || []).includes(feature),
);

const auth = {
  authSelector,
  meSelector,
  featuresSelector,
  featureSelector,
  tokenSelector,
};

export default auth;
