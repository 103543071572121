export const nounderscore = text => text.replace(/_/g, 'underscore');

export const underscore = text => text.replace(/ /g, '_');

const arrayToObj = ({ arr, head }) =>
  arr.reduce((acc, cur, idx) => {
    const key = head[idx][0];
    acc[key] = cur;
    return acc;
  }, {});

export const unformatArray = ({ head, body }) =>
  body.map(arr => arrayToObj({ arr, head }));

const pdTypesArray = ['od_pd', 'os_pd', 'bi_pd'];

const stripPdMsgs = msgs =>
  msgs.filter((msg) => {
    const hasPdMsgs = msg.keys.find(key => pdTypesArray.indexOf(key) > -1);
    if (!hasPdMsgs) return msg;
  });

const stripNonPdMsgs = msgs =>
  msgs.filter((msg) => {
    const hasPdMsgs = msg.keys.find(key => pdTypesArray.indexOf(key) > -1);
    if (hasPdMsgs) return msg;
  });

export const stripWarnings = (errorObj, rxType, correctionType) => {
  const { warnings, errors } = errorObj;
  if (warnings.length === 0) return errorObj;
  if (rxType === 'glasses') {
    if (correctionType === 'Single vision') {
      return {
        errors: stripPdMsgs(errors),
        pdErrors: stripNonPdMsgs(errors),
        pdWarnings: stripNonPdMsgs(warnings),
        warnings: stripPdMsgs(
          warnings.filter(
            warning =>
              !warning.keys.includes('od_add') ||
              !warning.keys.includes('os_add'),
          ),
        ),
      };
    }
    return {
      errors: stripPdMsgs(errors),
      pdErrors: stripNonPdMsgs(errors),
      pdWarnings: stripNonPdMsgs(warnings),
      warnings: stripPdMsgs(warnings),
    };
  }

  return {
    errors: stripPdMsgs(errors),
    pdErrors: stripNonPdMsgs(errors),
    pdWarnings: stripNonPdMsgs(warnings),
    warnings: stripPdMsgs(warnings),
  };
};
