type AssertNumberInRangeOptions = {
  value: string;
  min: number;
  max: number;
  canEqual?: boolean;
};

/**
 * Assert `value` is in range of min and max spectrums.
 * The received value can be a stringified number.
 *
 * `max` must be greater or equal to `min`, otherwise it will always return false.
 */
export const assertNumberInRange = ({
  max,
  min,
  value,
  canEqual = false,
}: AssertNumberInRangeOptions) => {
  const numericValue = parseFloat(value);

  if (max - min < 0) return false;

  return !Number.isNaN(numericValue) && canEqual
    ? numericValue >= min && numericValue <= max
    : numericValue > min && numericValue < max;
};

/**
 * Assert value is zero. If the given value is not numeric it will try to parse
 * it as a float.
 */
export const assertIsZero = (value: unknown) => parseFloat(<string>value) === 0;

/**
 * Assert a given value is set.
 *
 * Essentially it will fail for all falsey value but `0`.
 */
export const assertParameterIsSet = (value: unknown) =>
  value !== '' &&
  value !== undefined &&
  value !== null;
