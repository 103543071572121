import React from 'react';
import { PropTypes } from 'prop-types';
import { backButton } from '../assets/styles/backButton';
import leftArrow from '../assets/img/leftArrow.svg';

const BackButton = ({ onClick }) => (
  <button css={backButton} onClick={() => onClick(true)}>
    <img src={leftArrow} />
  </button>
);

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
