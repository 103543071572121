import { css } from '@emotion/react';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { ActionCreator } from 'redux';
import Button from '../shared-components/Button';

import {
  buttonCell,
  row,
  grayRow,
  patientName,
  flexRow,
} from '../assets/styles/appointmentSlot';
import {
  convertToRegularTime,
  get24hourTime,
} from '../utilities/formatters.util';
import { ROUTE_ADD_RX, ROUTE_UPLOAD_READING } from '../routes';
import { AppointmentSlot as AppointmentSlotType, AppointmentType } from '../types';
import { HeliosUserPermissions } from '../utilities/constants';

export const appointmentTypeLookup = (appointmentTypes: AppointmentType[], appointmentID: number | null) => {
  if (!appointmentTypes) return '';
  const match = appointmentTypes.find(examType => examType.id === appointmentID) || { name: '' };
  return match.name;
};

const styledRow = css`
  display: flex;
  align-items: center;
`;

const styledTime = css`
  font-weight: bold;
  flex: 1;
`;

const styledPatient = css`
  flex: 2;
`;

const styledDesc = css`
  flex: 3.5;
`;

export class SelectPatientPayload {
  appointmentID: AppointmentSlotType['id'];
  dob: AppointmentSlotType['patient']['date_of_birth'];
  doctorID: AppointmentSlotType['doctor'];
  email: AppointmentSlotType['patient']['email'];
  first_name: AppointmentSlotType['patient']['first_name'];
  last_name: AppointmentSlotType['patient']['last_name'];
  officeID: AppointmentSlotType['office'];
  patientID: AppointmentSlotType['patient']['id'];
  start_time: AppointmentSlotType['scheduled_time'];
  telephone: AppointmentSlotType['patient']['cell_phone'];

  constructor(slot: AppointmentSlotType) {
    this.appointmentID = slot.id;
    this.dob = slot.patient.date_of_birth;
    this.doctorID = slot.doctor;
    this.email = slot.patient.email;
    this.first_name = slot.patient.first_name;
    this.last_name = slot.patient.last_name;
    this.officeID = slot.office;
    this.patientID = slot.patient.id;
    this.start_time = slot.scheduled_time;
    this.telephone = slot.patient.cell_phone;
  }
}

export class SelectAddReadingPayload {
  appointmentID: AppointmentSlotType['id'];
  doctorID: AppointmentSlotType['doctor'];
  email: AppointmentSlotType['patient']['email'];
  first_name: AppointmentSlotType['patient']['first_name'];
  last_name: AppointmentSlotType['patient']['last_name'];
  patientID: AppointmentSlotType['patient']['id'];
  examRoom: string;

  constructor(slot: AppointmentSlotType) {
    this.appointmentID = slot.id;
    this.doctorID = slot.doctor;
    this.email = slot.patient.email;
    this.first_name = slot.patient.first_name;
    this.last_name = slot.patient.last_name;
    this.patientID = slot.patient.id;
    this.examRoom = slot.exam_room.toString();
  }
}

type Props = {
  slot: AppointmentSlotType,
  connectedSelectPatient: ActionCreator<unknown>,
  connectedSelectAddReading: ActionCreator<unknown>,
  appointmentTypes: AppointmentType[],
  features: string[],
  permissions: string[],
};

const AppointmentSlot = ({
  slot,
  connectedSelectPatient,
  connectedSelectAddReading,
  appointmentTypes,
  features,
  permissions,
}: Props) => {
  const { id: appointmentId } = slot;
  const time = convertToRegularTime(slot.scheduled_time);
  const { patient, duration } = slot;
  const appointmentID = slot.profile;
  const appointmentPassed = get24hourTime() > get24hourTime(slot.scheduled_time);

  const currentlyToday = format(new Date(), 'yyyy-MM-dd')
    === format(new Date(slot.scheduled_time), 'yyyy-MM-dd');

  const selectPatientAction = () =>
    connectedSelectPatient(new SelectPatientPayload(slot));

  const selectAddReadingAction = () =>
    connectedSelectAddReading(new SelectAddReadingPayload(slot));

  if (permissions.includes(HeliosUserPermissions.OptometristRxEntryQuickActions)) {
    return (
      <>
        <div data-appt-key={slot.id} data-cy="appointment-row" css={styledRow} >
          <div css={styledTime}>{time}</div>
          <div css={styledPatient}>{`${patient.first_name} ${patient.last_name}`}</div>
          <div css={styledDesc}>{appointmentTypeLookup(appointmentTypes, appointmentID)}</div>
          <div>{duration /* I guess we assume it's minutes? */}min</div>
        </div>
      </>
    );
  }

  return (
    <tr data-cy="appointment-row" css={currentlyToday && appointmentPassed ? grayRow : row}>
      <td>{time}</td>
      <td
        css={patientName}
      >
        {`${patient.first_name} ${patient.last_name}`}
      </td>
      <td>
        {appointmentTypeLookup(appointmentTypes, appointmentID)}
      </td>
      <td>
        {slot.duration}
      </td>
      <td css={flexRow}>
        <div css={buttonCell}>
          <Link css={css`width: 100%;`} to={`${ROUTE_ADD_RX}/${appointmentId}`}>
            <Button onClick={selectPatientAction} disabled={false}>
              Add Rx
            </Button>
          </Link>
        </div>
        {features.includes('topcon_upload_enabled') && (
          <div css={buttonCell}>
            <Link css={css`width: 100%;`} to={`${ROUTE_UPLOAD_READING}/${appointmentId}`}>
              <Button onClick={selectAddReadingAction} disabled={false}>
                Upload Reading
              </Button>
            </Link>
          </div>
        )}
      </td>
    </tr>
  );
};

export default AppointmentSlot;
