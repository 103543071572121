import { addYears, format } from 'date-fns';
import { any, bool, func, instanceOf, number, object, objectOf, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import doubleCheckModalStyles from '../assets/styles/doubleCheckModal';
import { underscore } from '../redux/utils/actions.utils';
import Button from '../shared-components/Button';
import { Label } from '../shared-components/Label';
import PillButton from '../shared-components/PillButton';
import { createRx } from '../utilities/createRx';
import doubleCheckModalUtils from '../utilities/doubleCheckModal';
import { unCamelCase } from '../utilities/formatters.util';
import { prescriptionNotes } from '../utilities/rxTable';
import { contactsRx } from '../utilities/rxTypes';
import tableUtils from '../utilities/table';
import toModalRoot from '../utilities/toModalRoot';
import { useAppSelector } from '../hooks';
import { MULTIFOCAL_CONTACTS_FEATURE_FLAG } from '../utilities/features';
import auth from '../redux/selectors/AuthSelectors';

const DisabledCell = () => <td css={doubleCheckModalStyles.td} />;

const Patient = ({ name }) => (
  <div css={doubleCheckModalStyles.longPillContainer}>
    <Label text="Patient" isModal />
    <p css={doubleCheckModalStyles.longPill}>{name}</p>
  </div>
);
Patient.propTypes = {
  name: string,
};

const PupillaryDistance = ({ pdValues }) => {
  const { od, os, bi } = pdValues;
  const values = (od && od && `${od}mm / ${os}mm`) || (bi && `${bi}mm`) || '';
  return (
    <div css={doubleCheckModalStyles.longPillContainer}>
      <Label isModal text="Pupillary distance" />
      <p css={doubleCheckModalStyles.longPill}>{values}</p>
    </div>
  );
};
PupillaryDistance.propTypes = {
  pdValues: objectOf(string),
};

const Exp = ({ exp }) => (
  <div css={doubleCheckModalStyles.shortPillContainer}>
    <Label isModal text="Expiration date" />
    <p css={doubleCheckModalStyles.shortPill}>{exp}</p>
  </div>
);
Exp.propTypes = {
  exp: string,
};

const PrescriptionNotes = ({ notes }) =>
  Object.entries(prescriptionNotes).map((set) => {
    const label = set[0];
    return (
      <div key={label}>
        <Label isModal text={unCamelCase(label)} />
        <p css={doubleCheckModalStyles.notes}>{notes}</p>
      </div>
    );
  });

const ContactsInfo = ({ contacts }) => (
  <div css={doubleCheckModalStyles.contactsInfo}>
    {contacts.od.sphere && (
      <div css={doubleCheckModalStyles.longPillContainer}>
        <Label isModal text="Right eye product (OD)" />
        <p css={doubleCheckModalStyles.longPill}>{contacts.od.product}</p>
      </div>
    )}
    {contacts.os.sphere && (
      <div css={doubleCheckModalStyles.longPillContainer}>
        <Label isModal text="Left eye product (OS)" />
        <p css={doubleCheckModalStyles.longPill}>{contacts.os.product}</p>
      </div>
    )}
  </div>
);
ContactsInfo.propTypes = {
  contacts: shape({
    od: shape({
      sphere: string,
      product: string,
    }),
    os: shape({
      sphere: string,
      product: string,
    }),
  }),
};

const renderHeaders = headers =>
  headers.map(header => (
    <th
      key={`${header.headerLabel}-${header.headerTitle}`}
      css={doubleCheckModalStyles.th}
    >
      <span css={doubleCheckModalStyles.headerTitle}>{header.headerTitle}</span>
    </th>
  ));

const renderCells = (eye, rxVariants, rxState, headers, isContacts) => {
  const singleEyeRx = eye === tableUtils.OD ? rxState.od : rxState.os;
  const variants = eye === tableUtils.OD ? rxVariants.od : rxVariants.os;

  // TODO: Fix errors about using array indices as keys
  if (isContacts && !variants) {
    return headers.map((header, headerIndex) => (
      <DisabledCell key={headerIndex} />
    ));
  }
  return headers.map((header, headerIndex) => {
    const currKey = underscore(header.headerLabel.toLowerCase());
    const value =
      singleEyeRx && doubleCheckModalUtils.rxValueLookUp(singleEyeRx, currKey);
    const notValidValue = doubleCheckModalUtils.validateValue(value);
    if (notValidValue || !singleEyeRx.sphere) {
      return <DisabledCell key={headerIndex} />;
    }
    return (
      <td css={doubleCheckModalStyles.td} key={`${eye}-${header.headerLabel}`}>
        {value}
      </td>
    );
  });
};

export const createTable = ({
  rxType,
  showPrisms,
  rxState,
  rxVariants,
  connectedToggleDoubleCheckModal,
  canDisplayMultifocalContacts,
}) => {
  const isContacts = rxType.includes(contactsRx);
  const headers = doubleCheckModalUtils.combinedHeaders(
    rxType,
    showPrisms,
    canDisplayMultifocalContacts,
  );
  return (
    // TODO: Accessibility warning here. Shouldn't use divs as buttons
    <div
      css={doubleCheckModalStyles.tableContainer}
      onClick={() => connectedToggleDoubleCheckModal(false)}
    >
      <table css={doubleCheckModalStyles.table}>
        <tbody css={doubleCheckModalStyles.tableBody}>
          <tr css={doubleCheckModalStyles.tr}>
            <th css={doubleCheckModalStyles.thFake} />
            {renderHeaders(headers)}
          </tr>
          {tableUtils.columns.map(column => (
            <tr
              css={doubleCheckModalStyles.trCol}
              key={`${column.eye}-${column.label}`}
            >
              <th css={doubleCheckModalStyles.thCol}>
                <span css={doubleCheckModalStyles.headerTitle}>
                  {column.eye}
                </span>
                <span css={doubleCheckModalStyles.headerLabel}>
                  {column.label}
                </span>
              </th>
              {renderCells(
                column.eye,
                rxVariants,
                rxState,
                headers,
                isContacts,
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DoubleCheckModal = ({
  additionalRxDetails,
  connectedSavePrescription,
  connectedToggleDoubleCheckModal,
  date,
  officeID,
  patientName,
  pendingSaveRx,
  rxState,
  rxVariants,
  rxToSave,
  strippedExpiration,
  token,
}) => {
  const canDisplayMultifocalContacts = useAppSelector(auth.featureSelector(MULTIFOCAL_CONTACTS_FEATURE_FLAG));

  const tableProps = {
    rxVariants,
    rxState,
    rxType: additionalRxDetails.rxType,
    showPrisms: additionalRxDetails.showPrisms,
    connectedToggleDoubleCheckModal,
  };
  const isContacts = additionalRxDetails.rxType.includes(contactsRx);
  const rxContacts = {
    od: {
      sphere: rxState.od.sphere,
      product: rxState.od.contacts ? rxState.od.contacts.product_name : '',
    },
    os: {
      sphere: rxState.os.sphere,
      product: rxState.os.contacts ? rxState.os.contacts.product_name : '',
    },
  };

  const saveRxObj = {
    appointmentID: rxToSave.appointmentID,
    doctorID: rxToSave.doctorID,
    patientID: rxToSave.patientID,
    payload: createRx(
      rxToSave.rxEntry,
      rxToSave.facilityRegion,
      rxToSave.facilityCountry,
      date,
    ),
  };

  const expirationDateWithSlashes = additionalRxDetails.useInputExpiration
    ? format(
      new Date(`${additionalRxDetails.inputExpiration}T00:00:00`),
      'MM/dd/yyyy',
    )
    : format(addYears(date, strippedExpiration), 'MM/dd/yyyy') || '';

  return (
    <div css={doubleCheckModalStyles.modalContainer}>
      <div css={doubleCheckModalStyles.modal}>
        <h1>{doubleCheckModalUtils.doubleCheckModalHeader}</h1>
        <div css={doubleCheckModalStyles.patientInfo}>
          <Patient name={patientName} />
          <Exp exp={expirationDateWithSlashes} />
        </div>
        {isContacts && <ContactsInfo contacts={rxContacts} />}
        <div>
          {createTable({ ...tableProps, connectedToggleDoubleCheckModal, canDisplayMultifocalContacts })}
        </div>
        {!isContacts && (
          <div css={doubleCheckModalStyles.patientInfo}>
            <PupillaryDistance pdValues={additionalRxDetails.pdValues} />
          </div>
        )}
        {additionalRxDetails.notes && (
          <PrescriptionNotes notes={additionalRxDetails.notes} />
        )}
        <div css={doubleCheckModalStyles.buttonContainer}>
          <div css={doubleCheckModalStyles.widthSetter}>
            <PillButton
              text={doubleCheckModalUtils.editButtonCopy}
              onClick={() => connectedToggleDoubleCheckModal(false)}
            />
            <Button
              pending={pendingSaveRx}
              disabled={pendingSaveRx}
              onClick={() =>
                connectedSavePrescription(saveRxObj, officeID, token)
              }
            >
              {doubleCheckModalUtils.sendButtonCopy}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Some of these actual typings are unknown without literally looking at data.
// Please improve if you come across these values.
DoubleCheckModal.propTypes = {
  additionalRxDetails: shape({
    // eslint-disable-next-line react/forbid-prop-types
    rxType: any,
    // eslint-disable-next-line react/forbid-prop-types
    showPrisms: any,
    // eslint-disable-next-line react/forbid-prop-types
    pdValues: any,
    useInputExpiration: bool,
    inputExpiration: string,
    notes: string,
  }),
  connectedSavePrescription: func,
  connectedToggleDoubleCheckModal: func,
  date: oneOfType([number, instanceOf(Date)]),
  officeID: number,
  patientName: string,
  pendingSaveRx: bool,
  // eslint-disable-next-line react/forbid-prop-types
  rxState: object,
  // eslint-disable-next-line react/forbid-prop-types
  rxToSave: object,
  // eslint-disable-next-line react/forbid-prop-types
  rxVariants: object,
  strippedExpiration: string,
  token: string,
};

export default props =>
  createPortal(<DoubleCheckModal {...props} />, toModalRoot());
