/* stylelint-disable declaration-property-value-blacklist */
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { format } from 'date-fns';

import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { CaretLeft12Icon, CaretDown16Icon, CaretRight12Icon, colors } from '@warbyparker/retail-design-system';
import dateSelector from '../../assets/styles/dateSelector';
import { updateDate } from '../../redux/actions/pmp';
import header from '../../assets/styles/header';
import { DATE_FORMAT } from './constants';

interface CustomDateInputProps {
  value: Date,
  onClick: React.DOMAttributes<HTMLButtonElement>['onClick'],
  className: string,
}

const CustomDateInput = forwardRef<HTMLButtonElement, CustomDateInputProps>(
  ({ value, onClick, className }, ref) => (
    <button onClick={onClick} css={dateSelector.wrapper} ref={ref}>
      <span className={className}>
        {format(new Date(value), DATE_FORMAT)}
      </span>
      <span css={dateSelector.caretIcon}>
        <CaretDown16Icon />
      </span>
    </button>
  ),
);

const DateLabel = styled('span')`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  grid-column: span 5;
  color: ${colors.charcoal};
`;

const NavigationButton = styled('button')`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  color: ${colors.bluesBlue};
`;

const DaySpan = styled('span')`
    position: relative;
  `;

const normalizedGrid = `
  display: grid;
  /* Calculating column width by taking the design dimensions minus the gap in between each column divided by the number of columns */
  grid-template-columns: repeat(7, calc((323px - (32px * 6)) / 7));
  grid-gap: 32px;
`;

const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
  // Linting error from the date picker's types:
  // "Avoid referencing unbound methods which may cause unintentional scoping of `this`.""
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    increaseMonth,
    decreaseMonth,
    monthDate,
  } = props;

  const localeMonthAndYear = monthDate.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={css(normalizedGrid)}
        >
          <NavigationButton
            aria-label="Previous Month"
            onClick={decreaseMonth}
          >
            <CaretLeft12Icon />
          </NavigationButton>
          <DateLabel>
            {localeMonthAndYear}
          </DateLabel>
          <NavigationButton
            aria-label="Next Month"
            onClick={increaseMonth}
          >
            <CaretRight12Icon />
          </NavigationButton>
        </div>
      )}
    </ClassNames>
  );
};

/*
  * I cannot tell how to style any of this components with config.
  * This isn't great because we have to rely on the component's implementation details,
  * but the docs don't show any way to do this through props. I'm certain that to an extent,
  * this component just wants you to use the styles that it gives you.
  *
  * If we come across methods to do this via props, we should look into refactoring this.
  */
const externalCalendarStyles = `
  padding: 24px;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  box-shadow: 0px 1px 4px rgba(228, 230, 232, 0.6), 0px 6px 12px rgba(239, 243, 245, 0.48);

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 7px;
  }

  .react-datepicker__month-container {
    display: grid;
    grid-row-gap: 28px;
  }
  .react-datepicker__header {
    border: none;
    background: none;
    padding: 0px;
    display: grid;
    grid-row-gap: 32px;
  }

  .react-datepicker__month {
    margin: 0px;
    display: grid;
    grid-row-gap: 28px;
  }

  .react-datepicker__week, .react-datepicker__day-names {
    ${normalizedGrid}
  }

  /* Day styles */
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: auto;
    margin: 0px;
    position: relative;
  }

  .react-datepicker__day--keyboard-selected,

  .react-datepicker__day:hover,
  .react-datepicker__day--selected:hover {
    background: none;
  }

  .react-datepicker__day::before {
    content: '';
    width: 29px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100px;
  }

  .react-datepicker__day--keyboard-selected::before,
  .react-datepicker__day:hover::before {
    background-color: ${colors.silk};
  }

  .react-datepicker__day--selected::before {
    background-color: ${colors.bluesBlue} !important;
  }

  .react-datepicker__day--selected {
    color: ${colors.white};
    font-weight: 600;
    position: relative;
  }
`;

interface AppointmentHeaderProps {
  date: Date;
}

// There are a few class names that are derived via functions
// eg. `dayClassName`
const toFunctionBasedClassName = (emotionStyles: string) => () => emotionStyles;

const DateSelector = ({ date }: AppointmentHeaderProps) => {
  const dispatch = useDispatch();

  return (
    <ClassNames>
      {({ css }) => (
        <div>
          <DatePicker
            selected={date}
            onChange={selectedDate => dispatch(updateDate(selectedDate))}
            renderCustomHeader={CustomHeader}
            customInput={React.createElement(CustomDateInput)}
            className={css(
              dateSelector.dateValue,
              header.heading,
            )}
            calendarClassName={`${css(externalCalendarStyles)} font-sans`}
            popperPlacement="bottom"
            /*
            * The component applies it's own marging instead of leveraging popper's offset modifier.
            * This margin reset allows us to properly set the offset via `popperModifiers`
            */
            popperClassName={css`
              margin: initial !important;
            `}
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 7],
                },
              },
            ]}
            weekDayClassName={toFunctionBasedClassName(css`
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: ${colors.charcoal};
            `)}
            dayClassName={toFunctionBasedClassName(css`
              font-size: 14px;
              line-height: 17px;
              /* TODO: Distinguish styles for days with and without appointments */
              /* See https://warbyparker.atlassian.net/browse/RETAILAPPS-517 */
              /* font-weight: normal; */
              /* color: ${colors.stone}; */
              font-weight: 600;
              color: ${colors.bluesBlue};
            `)}
            monthClassName={toFunctionBasedClassName(css`
              display: grid;
              grid-template-columns: repeat(7, 1fr);
            `)}
            showPopperArrow={false}
            renderDayContents={day => (
              <DaySpan>{day}</DaySpan>
            )}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
          />
        </div>
      )}
    </ClassNames>
  );
};

export default DateSelector;
