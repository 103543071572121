import { CheckIcon, Modal } from '@warbyparker/retail-design-system';
import React, { Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import rxTableStyles from '../../assets/styles/rxTable';
import { useAppSelector } from '../../hooks';
import useActions from '../../hooks/useActions';
import { getContactLensRx, getContactsProductsByCategoryId, verifyRx } from '../../redux/actions/ohm';
import { printPrescription, savePrescription, sendEmailToPatient, sendRxToHelios } from '../../redux/actions/pmp';
import {
  addAnotherRx,
  clearSession,
  showPrisms as showPrismsAction,
  toggleDoubleCheckModal,
  updateNotes,
  updatePdValues,
  updateRxValues,
} from '../../redux/actions/rxEntry';
import {
  componentStatesSelector,
  pendingSaveRxStatusSelector,
  saveRxSelector,
} from '../../redux/selectors/AppContainerSelectors';
import ohm from '../../redux/selectors/OhmSelectors';
import pmp from '../../redux/selectors/PmpSelectors';
import rxEntry from '../../redux/selectors/RxEntrySelectors';
import { pendingSaveRxSelector, rxToVerifySelector } from '../../redux/selectors/RxTableSelectors';
import Button from '../../shared-components/Button';
import Error from '../../shared-components/Error';
import ErrorMsg from '../../shared-components/ErrorMsg';
import { FEATURE_ALLOW_PRINT_RX } from '../../utilities/features';
import { OD, OS } from '../../utilities/rxTable';
import { contactsRx } from '../../utilities/rxTypes';
import toModalRoot from '../../utilities/toModalRoot';
import { CheckIconContainer, RxUploadButtons, SmallerMarginHeader } from '../ClassicModal';
import DoubleCheckModal from '../DoubleCheckModal';
import PrismButton from '../PrismButton';
import Table from '../Table/Table';
import { Disclaimer, PrismDisclaimer } from './Disclaimer';
import { Header } from './Header';
import { PrescriptionNotes } from './PrescriptionNotes';
import { PupillaryDistance } from './PupillaryDistance';
import { ColorSelectors, ContactsSelector } from './Selector';
import validate from '../../middleware/validation';
import {
  canShowRxFrontendValidationMessage,
  getErrorMessageForRxValidation,
  getRxForValidation,
} from '../../middleware/validation/utils';
import auth from '../../redux/selectors/AuthSelectors';

const checkIfContactsHaveColors = ({ od, os }) => {
  const hasOdColors = ((od && od.color) || []).length > 0;
  const hasOsColors = ((os && os.color) || []).length > 0;
  return hasOdColors || hasOsColors;
};

const checkIfTableCanRender = (
  expDate,
  isContacts,
  contactsSelected,
  pendingContactsInfo,
) => {
  const renderContactsTable =
    isContacts && contactsSelected && !pendingContactsInfo && expDate;
  const renderGlassesTable = !isContacts && expDate;
  return renderGlassesTable || renderContactsTable;
};

const sendButtonText = (rxVerificationMsgs) => {
  if (!rxVerificationMsgs) {
    return 'Send Prescription';
  }
  const {
    warnings = [],
    errors = [],
    pdWarnings = [],
    pdErrors = [],
  } = rxVerificationMsgs;
  const hasErrors = errors.length > 0 || pdErrors.length > 0;
  const hasWarnings = warnings.length > 0 || pdWarnings.length > 0;
  if (hasErrors) return 'Send Prescription';
  if (hasWarnings && !hasErrors) return 'Ignore warnings and send';
  return '';
};

const DoubleCheckModalWrapper = () => {
  const token = useAppSelector(auth.tokenSelector);
  const features = useAppSelector(auth.featuresSelector);
  const additionalRxDetails = useAppSelector(
    rxEntry.additionalRxDetailsSelector,
  );
  const date = useAppSelector(pmp.currentDateSelector);
  const patient = useAppSelector(rxEntry.patientSelector);
  const pendingSaveRx = useAppSelector(pendingSaveRxStatusSelector);
  const rxState = useAppSelector(rxEntry.rxStateSelector);
  const rxToSave = useAppSelector(saveRxSelector);
  const rxVariants = useAppSelector(ohm.rxVariantsSelector);

  const { connectedSavePrescription, connectedToggleDoubleCheckModal } =
    useActions({
      connectedSavePrescription: savePrescription,
      connectedToggleDoubleCheckModal: toggleDoubleCheckModal,
    });

  const strippedExpiration = additionalRxDetails.expiration.replace(
    /years|year/gi,
    '',
  );

  return (
    <DoubleCheckModal
      features={features}
      token={token}
      additionalRxDetails={additionalRxDetails}
      connectedSavePrescription={connectedSavePrescription}
      connectedToggleDoubleCheckModal={connectedToggleDoubleCheckModal}
      date={date}
      officeID={patient.officeID}
      patientName={`${patient.first_name} ${patient.last_name}`}
      pendingSaveRx={pendingSaveRx}
      rxState={rxState}
      rxToSave={rxToSave}
      rxVariants={rxVariants}
      strippedExpiration={strippedExpiration}
    />
  );
};

const PrescriptionSentConfirmationModal = () => {
  const token = useAppSelector(auth.tokenSelector);
  const features = useAppSelector(auth.featuresSelector);
  const patient = useAppSelector(rxEntry.patientSelector);
  const { expirationDate } = useAppSelector(
    rxEntry.additionalRxDetailsSelector,
  );
  const { uid } = useAppSelector(pmp.rxStatusSelector);

  const { connectedAddAnotherRx, connectedClearSession } = useActions({
    connectedClearSession: clearSession,
    connectedAddAnotherRx: addAnotherRx,
  });

  const [isPrintPending, setIsPrintPending] = useState(false);

  const allowPrintRx = features.includes(FEATURE_ALLOW_PRINT_RX);

  let actions = [
    <Button key="add_another" onClick={connectedAddAnotherRx}>
      Add Another
    </Button>,
  ];

  if (allowPrintRx) {
    const printAction = (
      <Button
        key="print_to_rx_pad"
        loading={isPrintPending}
        onClick={() => {
          // This callback intentionally bypasses the react-redux action/reducer flow.
          // This is because the result of the async function is not data that is meant
          // to be stored in the state and update components, but an action to download
          // and display a PDF in a new browser tab.
          setIsPrintPending(true);
          printPrescription(uid, token).then(() => {
            setIsPrintPending(false);
          });
        }}
      >
        Print to Rx Pad
      </Button>
    );
    actions = [...actions, printAction];
  }

  return createPortal(
    <Modal>
      <CheckIconContainer>
        <CheckIcon color="primary" />
      </CheckIconContainer>
      <SmallerMarginHeader>Prescription sent</SmallerMarginHeader>
      <div>
        {`Prescription for ${patient.email} sent and will be valid until ${expirationDate}.`}
      </div>
      <RxUploadButtons>
        {actions}
        <Button onClick={connectedClearSession}>Done</Button>
      </RxUploadButtons>
    </Modal>,
    toModalRoot(),
  );
};

const RxTable = () => {
  const token = useAppSelector(auth.tokenSelector);

  const [rxFrontendValidation, setRxFrontendValidation] = useState({ isValid: true, messages: [] });
  const [rxFrontendValidationMessages, setRxFrontendValidationMessages] = useState({ errors: [], warnings: [] });

  const { showDoubleCheckModal } = useAppSelector(componentStatesSelector);

  const error = useAppSelector(ohm.errorSelector);
  const expiration = useAppSelector(rxEntry.expirationSelector);
  const inputExpiration = useAppSelector(rxEntry.inputExpirationSelector);
  const notes = useAppSelector(rxEntry.notesSelector);
  const rxStatus = useAppSelector(pmp.rxStatusSelector);
  const rxState = useAppSelector(rxEntry.rxStateSelector);
  const rxToVerify = useAppSelector(rxToVerifySelector);
  const rxType = useAppSelector(rxEntry.rxTypeSelectedSelector);
  const pmpError = useAppSelector(pmp.errorSelector);
  const pendingContactsRx = useAppSelector(ohm.pendingContactsRxSelector);
  const pendingSaveRx = useAppSelector(pendingSaveRxSelector);
  const rxVerificationMsgs = useAppSelector(ohm.errorRxVerificationSelector);
  const rxVerificationPdMsgs = useAppSelector(ohm.rxVerificationPdMsgsSelector);
  const pcid = useAppSelector(ohm.pcidSelector);
  const rxVerified = useAppSelector(ohm.rxVerifiedSelector);
  const rxVariants = useAppSelector(ohm.rxVariantsSelector);
  const showPrisms = useAppSelector(rxEntry.showPrismsSelector);
  const pdValues = useAppSelector(rxEntry.pdValuesSelector);

  const { saved: rxSaved, sentToHelios: rxSentToHelios, uid: rxUid } = rxStatus;
  const features = useAppSelector(auth.featuresSelector);

  const {
    connectedGetContactsProductsByCategoryId,
    connectedGetContactLensRx,
    connectedUpdateRxValues,
    connectedShowPrisms,
    connectedVerifyRx,
    connectedUpdateNotes,
    connectedUpdatePdValues,
    connectedToggleDoubleCheckModal,
    connectedSendRxToHelios,
    connectedSendEmailToPatient,
  } = useActions({
    connectedGetContactsProductsByCategoryId: getContactsProductsByCategoryId,
    connectedGetContactLensRx: getContactLensRx,
    connectedUpdateRxValues: updateRxValues,
    connectedShowPrisms: showPrismsAction,
    connectedVerifyRx: verifyRx,
    connectedUpdateNotes: updateNotes,
    connectedUpdatePdValues: updatePdValues,
    connectedToggleDoubleCheckModal: toggleDoubleCheckModal,
    connectedSendRxToHelios: sendRxToHelios,
    connectedSendEmailToPatient: sendEmailToPatient,
  });

  // ------------------- get contacts pc product ID
  useEffect(() => {
    if (rxState.od.contacts.product_line) {
      connectedGetContactsProductsByCategoryId(
        rxState.od.contacts.product_line,
        token,
        OD,
      );
    }

    if (rxState.os.contacts.product_line) {
      connectedGetContactsProductsByCategoryId(
        rxState.os.contacts.product_line,
        token,
        OS,
      );
    }
  }, [
    connectedGetContactsProductsByCategoryId,
    rxState.od.contacts.product_line,
    rxState.os.contacts.product_line,
    token,
  ]);

  // ------------------- get contacts variants
  useEffect(() => {
    if (pcid.od) {
      connectedGetContactLensRx(pcid.od, token, OD);
    }
  }, [connectedGetContactLensRx, pcid.od, token]);

  useEffect(() => {
    if (pcid.os) {
      connectedGetContactLensRx(pcid.os, token, OS);
    }
  }, [connectedGetContactLensRx, pcid.os, token]);

  // -------------------- show double check modal if verified
  useEffect(() => {
    if (rxVerified) {
      connectedToggleDoubleCheckModal(true);
    }
  }, [connectedToggleDoubleCheckModal, rxVerified]);

  /*
   * Post-save flow
   */
  useEffect(() => {
    if (rxSentToHelios) {
      connectedToggleDoubleCheckModal(false);
    }
  }, [connectedToggleDoubleCheckModal, rxSentToHelios]);

  useEffect(() => {
    if (rxSaved) {
      connectedSendRxToHelios(rxUid, token);
      connectedSendEmailToPatient(rxUid, token);
    }
  }, [
    connectedSendEmailToPatient,
    connectedSendRxToHelios,
    rxSaved,
    rxUid,
    token,
  ]);

  const isContacts = rxType.includes(contactsRx);
  const contactsSelected = pcid.os || pcid.od;
  const hasColors = checkIfContactsHaveColors(rxVariants);
  const hasExpirationSelected = expiration || inputExpiration;

  const renderRxTableAndNotes = checkIfTableCanRender(
    hasExpirationSelected,
    isContacts,
    contactsSelected,
    pendingContactsRx,
  );

  const sendButtonClick = () => {
    const rxFrontendValidationResults = validate(getRxForValidation(rxToVerify, features, rxVariants));
    setRxFrontendValidation(rxFrontendValidationResults);
    setRxFrontendValidationMessages(getErrorMessageForRxValidation(rxFrontendValidationResults));

    if (rxFrontendValidationResults.isValid) {
      if (!rxVerificationMsgs) {
        connectedVerifyRx(rxToVerify, token);
      } else {
        const { warnings, errors, pdWarnings, pdErrors } = rxVerificationMsgs;
        const hasErrors =
          (errors && errors.length > 0) || (pdErrors && pdErrors.length > 0);
        const hasWarnings =
          (warnings && warnings.length > 0) ||
          (pdWarnings && pdWarnings.length > 0);
        if (!hasErrors) {
          connectedVerifyRx(rxToVerify, token);
        }
        if (hasWarnings && !hasErrors) {
          connectedToggleDoubleCheckModal(true);
        }
      }
    }
  };

  const showFrontendValidationMessages = canShowRxFrontendValidationMessage(
    rxFrontendValidation.isValid,
    rxVerificationMsgs,
    rxFrontendValidationMessages,
  );

  return (
    <>
      <div css={rxTableStyles.rxTableContainer}>
        <Header rxType={rxType} />

        {isContacts && (error ? <Error error={error} /> : <ContactsSelector />)}

        {hasColors && (
          <ColorSelectors
            variants={rxVariants}
            rxState={rxState}
            connectedUpdateRxValues={connectedUpdateRxValues}
          />
        )}
        {pmpError && <Error error={pmpError} />}
        {renderRxTableAndNotes && (
          <Fragment>
            <Table
              connectedUpdateRxValues={connectedUpdateRxValues}
              rxType={rxType}
              showPrisms={showPrisms}
              rxVariants={rxVariants}
              rxState={rxState}
            />

            {rxVerificationMsgs && (
              <ErrorMsg rxVerificationMsgs={rxVerificationMsgs} />
            )}
            {!isContacts && (
              <div css={rxTableStyles.prismButtonContainer}>
                {showPrisms ? (
                  <PrismDisclaimer />
                ) : (
                  <PrismButton onClick={connectedShowPrisms} />
                )}
              </div>
            )}
            {!isContacts && (
              <PupillaryDistance
                connectedUpdatePdValues={connectedUpdatePdValues}
                pdValues={pdValues}
              />
            )}
            {!isContacts && rxVerificationPdMsgs && (
              <ErrorMsg rxVerificationMsgs={rxVerificationPdMsgs} />
            )}
            {showFrontendValidationMessages && (
              <ErrorMsg
                rxVerificationMsgs={rxFrontendValidationMessages}
              />
            )}
            <PrescriptionNotes
              connectedUpdateNotes={connectedUpdateNotes}
              notes={notes}
            />
            <Disclaimer />
            <div css={rxTableStyles.buttonContainer}>
              <div css={rxTableStyles.readingButton}>
                <Button
                  pending={pendingSaveRx}
                  disabled={pendingSaveRx}
                  onClick={sendButtonClick}
                >
                  {sendButtonText(rxVerificationMsgs)}
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      {showDoubleCheckModal ? <DoubleCheckModalWrapper /> : null}
      {rxSaved ? <PrescriptionSentConfirmationModal /> : null}
    </>
  );
};

export default RxTable;
