/* eslint-disable no-undef */
import { call, put, takeLatest } from 'redux-saga/effects';
import RetailService from '../../service-clients/helios-retail';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';

export const onAuthFailure = error => ({
  type: AUTH_FAILURE,
  payload: error,
});

export const onAuthSuccess = me => ({
  type: AUTH_SUCCESS,
  payload: me,
});

export const onTokenSuccess = token => ({
  type: TOKEN_SUCCESS,
  payload: token,
});

const initialState = {
  me: null,
  jwt: null,
  error: null,
};

export function* fetchTokenOrRedirect() {
  let token;
  try {
    token = yield call(RetailService.getUserJwt);
  } catch (err) {
    yield put(onAuthFailure(err.message));
    return;
  }

  if (token) {
    yield put(onTokenSuccess(token));
    if (window.WP?.POEBridge?.onAuthTokenFetched) {
      window.WP.POEBridge.onAuthTokenFetched(token);
    } else if (window.IS_HERMES) {
      logger.info({}, 'No POE bridge to send auth token');
    }
    return;
  }

  // Redirect to login if we reach this point
  const url = new window.URL(`https://${process.env.HELIOS_RETAIL_DOMAIN}/login`);
  const params = new window.URLSearchParams();
  params.append('next', window.location.href);
  url.search = String(params);
  window.location.replace(String(url));
}

export function* fetchMe({ payload: token }) {
  let me;
  try {
    me = yield call(RetailService.getUserMe, { token });
  } catch (err) {
    yield put(onAuthFailure(err.message));
    return;
  }
  yield put(onAuthSuccess(me));
  if (window.WP?.POEBridge?.currentUserUpdate) {
    window.WP.POEBridge.currentUserUpdate(me);
  } else if (window.IS_HERMES) {
    logger.info({}, 'No POE bridge to send current user info');
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TOKEN_SUCCESS:
    return { ...state, jwt: payload, error: null };
  case AUTH_FAILURE:
    return { ...state, jwt: null, error: payload };
  case AUTH_SUCCESS:
    return { ...state, me: payload };
  default:
    return state;
  }
};

function* saga() {
  yield takeLatest(AUTH_REQUEST, fetchTokenOrRedirect);
  yield takeLatest(TOKEN_SUCCESS, fetchMe);
}

export default {
  reducer,
  saga,
};
