enum ErrorText {
  OdOsSphAreMissing = 'Must add SPH measurement for OD/OS',
  OdSphIsMissing = 'Must add SPH measurement for OD',
  OsSphIsMissing = 'Must add SPH measurement for OS',
  OdOsDiameterAreMissing = 'Must add diameter for OD/OS',
  OdDiameterIsMissing = 'Must add diameter for OD',
  OsDiameterIsMissing = 'Must add diameter for OS',
  OdOsBaseCurveAreMissing = 'Must add base curve for OD/OS',
  OdBaseCurveIsMissing = 'Must add base curve for OD',
  OsBaseCurveIsMissing = 'Must add base curve for OS',
  OdOsContactBrandAreMissing = 'Must select contact brand for OD/OS',
}

export default ErrorText;
