import { css } from '@emotion/react';
import COLORS from './colors';

const accountDetailsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const backButtonContainer = css`
  margin-top: 55px;
  margin-right: 18px;
`;

const setWidthPatientAccount = css`
  width: 50%;
`;

const setWidthPatient = css`
  width: 50%;
  margin-left: 12px;
`;

const pill = css`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  height: 66px;
  padding: 21px 48px 21px 24px;
`;

const disabledPill = css`
  background: ${COLORS.SILK};
  border-radius: 6px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  color: ${COLORS.disabledPillSMOKE};
  height: 66px;
  padding: 11px 48px 21px 24px;
  &:first-of-type {
    margin-right: 18px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default {
  accountDetailsContainer,
  backButtonContainer,
  setWidthPatientAccount,
  pill,
  disabledPill,
  setWidthPatient,
};
