import moment from 'moment';

import {
  COMBINED_SPH_CYL_MAX_LABEL,
  COMBINED_SPH_CYL_MAX_OLD_LABEL,
  COMBINED_SPH_CYL_MAX_OLD_VALUE,
  COMBINED_SPH_CYL_MAX_VALUE,
  COMBINED_SPH_CYL_MIN_LABEL,
  COMBINED_SPH_CYL_MIN_VALUE,
  CONTACTS,
  CYL_MAX_LABEL,
  CYL_MAX_VALUE,
  CYL_MIN_LABEL,
  CYL_MIN_VALUE,
  GLASSES,
  MULTIFOCAL,
  SINGLE_VISION,
  SPH_MAX_LABEL,
  SPH_MAX_OLD_LABEL,
  SPH_MAX_OLD_VALUE,
  SPH_MAX_VALUE,
  SPH_MIN_LABEL,
  SPH_MIN_VALUE,
} from './constants';

import { assertIsZero as isZero, assertNumberInRange, assertParameterIsSet as isSet } from '../../utilities/assertions';
import { EXPANDED_RX_FEATURE_FLAG, MULTIFOCAL_CONTACTS_FEATURE_FLAG } from '../../utilities/features';
import { ValidatorFunction } from './types';
import ErrorText from './error-text';

export const shouldHavePd: ValidatorFunction = ({
  biPd,
  osPd,
  odPd,
  category,
}) => {
  if (category === CONTACTS || biPd) {
    return null;
  }

  return odPd && osPd
    ? null
    : 'Should have either both OD/OS PD measurements or one binocular PD measurement';
};

export const bothMonoPdAdded: ValidatorFunction = ({ biPd, osPd, odPd }) => {
  if (biPd) return null;

  return (osPd && !odPd) || (!osPd && odPd)
    ? 'Both PD measurements must be entered'
    : null;
};

export const monoPdInRange: ValidatorFunction = ({ osPd, odPd }) => {
  if (!odPd || !osPd) return null;

  const inRange = (x: string) => assertNumberInRange({
    value: x,
    min: 24,
    max: 40,
  });

  return inRange(odPd) && inRange(osPd)
    ? null
    : 'PD measurements must be in range ( > 24 < 40 )';
};

export const biPdInRange: ValidatorFunction = ({ biPd }) => {
  if (!biPd) return null;

  const isBiPdInRange = assertNumberInRange({
    value: biPd,
    min: 50,
    max: 75,
  });

  return isBiPdInRange
    ? null
    : 'Binocular PD measurement must be in range ( > 50 < 75 )';
};

export const sphPresent: ValidatorFunction = ({
  category,
  odSphere,
  osSphere,
  odContactProduct,
  osContactProduct,
}): string | null => {
  if (category === GLASSES) {
    return (odSphere && osSphere)
      ? null
      : ErrorText.OdOsSphAreMissing;
  }

  const odMissingSphere = isSet(odContactProduct) && !isSet(odSphere);
  const osMissingSphere = isSet(osContactProduct) && !isSet(osSphere);

  if (odMissingSphere && osMissingSphere) {
    return ErrorText.OdOsSphAreMissing;
  }

  if (odMissingSphere) {
    return ErrorText.OdSphIsMissing;
  }

  if (osMissingSphere) {
    return ErrorText.OsSphIsMissing;
  }

  return null;
};


export const sphFormat: ValidatorFunction = ({ odSphere, osSphere }) => {
  if (!odSphere && !osSphere) {
    return null;
  }

  const pattern = /^([+-])\d+\.\d{2}$/;

  const odOk = !isSet(odSphere) || isZero(odSphere) || pattern.test(<string>odSphere);

  if (!odOk) return 'OD SPH measurements not properly formatted (e.g. -0.25 or +1.00)';

  const osOk = !isSet(osSphere) || isZero(osSphere) || pattern.test(<string>osSphere);

  if (!osOk) return 'OS SPH measurements not properly formatted (e.g. -0.25 or +1.00)';

  return null;
};

export const cylFormat: ValidatorFunction = ({ odCylinder, osCylinder }) => {
  if (!odCylinder && !osCylinder) {
    return null;
  }

  const pattern = /^([+-])\d+\.\d{2}$/;

  const odOk = !isSet(odCylinder) || isZero(odCylinder) || pattern.test(<string>odCylinder);

  if (!odOk) return 'OD CYL measurements not properly formatted (e.g. -0.25 or +1.00)';

  const osOk = !isSet(osCylinder) || isZero(osCylinder) || pattern.test(<string>osCylinder);

  if (!osOk) return 'OS CYL measurements not properly formatted (e.g. -0.25 or +1.00)';

  return null;
};

export const cylMinorIsQuarted: ValidatorFunction = ({ odCylinder, osCylinder }) => {
  const pattern = /^.*\.(\d{2})$/;
  const validDecimals = ['00', '25', '50', '75'];
  const validFormat = (x: string) => pattern.exec(x) && validDecimals.includes(<string>pattern.exec(x)?.[1]);

  const odOk = !isSet(odCylinder) || isZero(odCylinder) || validFormat(<string>odCylinder);

  if (!odOk) return 'OD Cyl must end in either .00, .25, .50, or .75';

  const osOk = !isSet(osCylinder) || isZero(osCylinder) || validFormat(<string>osCylinder);

  if (!osOk) return 'OS Cyl must end in either .00, .25, .50, or .75';

  return null;
};

export const cylOpposing: ValidatorFunction = ({ odCylinder, osCylinder }) => {
  if (!odCylinder || !osCylinder) {
    return null;
  }

  const eitherZero = Math.sign(parseFloat(odCylinder)) === 0 || Math.sign(parseFloat(osCylinder)) === 0;

  if (eitherZero) return null;

  const ok = Math.sign(parseFloat(odCylinder)) === Math.sign(parseFloat(osCylinder));

  return ok ? null : 'CYLs have opposing positive/negative values';
};

export const cylPresentIfAxisPresent: ValidatorFunction = ({
  odCylinder,
  osCylinder,
  odAxis,
  osAxis,
}) => {
  const odOk = isSet(odAxis) ? isSet(odCylinder) : true;
  const osOk = isSet(osAxis) ? isSet(osCylinder) : true;

  return odOk && osOk
    ? null
    : 'Axis measurement present but no corresponding cyl measurement';
};

export const axisPresentIfCylPresent: ValidatorFunction = ({
  odCylinder,
  osCylinder,
  odAxis,
  osAxis,
}) => {
  const odOk = isSet(odCylinder) ? isZero(odCylinder) || isSet(odAxis) : true;

  const osOk = isSet(osCylinder) ? isZero(osCylinder) || isSet(osAxis) : true;

  return odOk && osOk
    ? null
    : 'Cyl measurement present but no corresponding axis measurement';
};

export const axisIsNotGreaterThan180: ValidatorFunction = ({ odAxis, osAxis }) => {
  const lt181 = (x: string) => parseFloat(x) < 181;

  const odOk = isSet(odAxis) ? lt181(<string>odAxis) : true;

  if (!odOk) return 'OD Axis can not be greater than 180';

  const osOk = isSet(osAxis) ? lt181(<string>osAxis) : true;

  if (!osOk) return 'OS Axis can not be greater than 180';

  return null;
};

export const axisIsGreaterThanZeroIfCyl: ValidatorFunction = ({
  odCylinder,
  osCylinder,
  odAxis,
  osAxis,
}) => {
  const gt0 = (x: string | null) => x && parseFloat(x) > 0;

  const odOk = !isSet(odCylinder) || isZero(odCylinder) || gt0(<string>odAxis);

  if (!odOk) return 'OD Axis must be greater than 0';

  const osOk = !isSet(osCylinder) || isZero(osCylinder) || gt0(<string>osAxis);

  if (!osOk) return 'OS Axis must be greater than 0';

  return null;
};

export const addFormat: ValidatorFunction = ({ correctionType, odAdd, osAdd }) => {
  if (correctionType !== MULTIFOCAL || (!odAdd && !osAdd)) {
    return null;
  }

  const pattern = /^.*\.(\d{2})$/;
  const validDecimals = ['00', '25', '50', '75'];
  const validFormat = (x: string) => pattern.exec(x) && validDecimals.includes(pattern.exec(x)?.[1] || '');

  const odOk = isZero(odAdd) || validFormat(<string>odAdd);

  if (!odOk) return 'OD Add must end in either .00, .25, .50, or .75';

  const osOk = isZero(osAdd) || validFormat(<string>osAdd);

  if (!osOk) return 'OS Add must end in either .00, .25, .50, or .75';

  return null;
};

export const cylRange: ValidatorFunction = ({ odCylinder, osCylinder }) => {
  const inRange = (x: string) => assertNumberInRange({
    value: x,
    min: CYL_MIN_VALUE,
    max: CYL_MAX_VALUE,
    canEqual: true,
  });

  return ((odCylinder && !inRange(odCylinder)) || (osCylinder && !inRange(osCylinder)))
    ? `CYL measurement outside of Warby Parker fulfillment range ( > ${CYL_MIN_LABEL} and < ${CYL_MAX_LABEL})`
    : null;
};

export const combinedSphCylRange: ValidatorFunction = ({
  odSphere,
  odCylinder,
  osSphere,
  osCylinder,
  features,
}) => {
  const maxValue = features && features.includes(<string>EXPANDED_RX_FEATURE_FLAG)
    ? COMBINED_SPH_CYL_MAX_VALUE
    : COMBINED_SPH_CYL_MAX_OLD_VALUE;
  const maxLabel = features && features.includes(EXPANDED_RX_FEATURE_FLAG)
    ? COMBINED_SPH_CYL_MAX_LABEL
    : COMBINED_SPH_CYL_MAX_OLD_LABEL;

  const inRange = (x: string) => assertNumberInRange({
    value: x,
    min: COMBINED_SPH_CYL_MIN_VALUE,
    max: maxValue,
    canEqual: true,
  });

  const rightEyeNotInRange = odSphere && odCylinder
    && !inRange(String(parseFloat(odSphere) + parseFloat(odCylinder)));
  const leftEyeNotInRange = osSphere && osCylinder
    && !inRange(String(parseFloat(osSphere) + parseFloat(osCylinder)));

  if (rightEyeNotInRange || leftEyeNotInRange) {
    return (
      'Combined SPH and CYL measurements outside of Warby Parker fulfillment range ' +
      `(>= ${COMBINED_SPH_CYL_MIN_LABEL} and <= ${maxLabel})`
    );
  }

  return null;
};

export const addRange: ValidatorFunction = ({ correctionType, odAdd, osAdd }) => {
  if (correctionType !== MULTIFOCAL || (!odAdd && !osAdd)) {
    return null;
  }
  const inRange = (x: string) => assertNumberInRange({
    value: x,
    min: 0.75,
    max: 3.5,
    canEqual: true,
  });

  return (odAdd && !inRange(odAdd)) || (osAdd && !inRange(osAdd))
    ? 'ADD measurement must be in range ( >= 0.75 and =< 3.50 )'
    : null;
};

export const multiFocalHasAdd: ValidatorFunction = ({ correctionType, odAdd, osAdd }) => (
  correctionType === MULTIFOCAL &&
  (!odAdd || !osAdd)
    ? 'Progressives prescriptions require ADD values'
    : null
);

export const basePresentIfPrismPresent: ValidatorFunction = ({
  odPrismBase,
  osPrismBase,
  odPrism,
  osPrism,
}) =>
  ((odPrism && !odPrismBase) || (osPrism && !osPrismBase)
    ? 'Prism measurement present but no base measurement found'
    : null);


export const base2PresentIfPrism2Present: ValidatorFunction = ({
  odPrismBase2,
  osPrismBase2,
  odPrism2,
  osPrism2,
}) =>
  ((odPrism2 && !odPrismBase2) || (osPrism2 && !osPrismBase2)
    ? 'Prism measurement present but no base measurement found'
    : null);

export const prismPresentIfBasePresent: ValidatorFunction = ({
  odPrismBase,
  osPrismBase,
  odPrism,
  osPrism,
}) => {
  const odOk = isSet(odPrismBase) ? isSet(odPrism) : true;
  const osOk = isSet(osPrismBase) ? isSet(osPrism) : true;

  return odOk && osOk
    ? null
    : 'Base measurement needs a prism measurement';
};

export const prism2PresentIfBase2Present: ValidatorFunction = ({
  odPrismBase2,
  osPrismBase2,
  odPrism2,
  osPrism2,
}) => {
  const odOk = isSet(odPrismBase2) ? isSet(odPrism2) : true;
  const osOk = isSet(osPrismBase2) ? isSet(osPrism2) : true;

  return odOk && osOk
    ? null
    : 'A Base 2 value needs a Prism 2 measurement';
};

export const multifocalDioptersSumLessThanFive: ValidatorFunction = ({
  correctionType,
  odPrism,
  odPrism2,
  osPrism,
  osPrism2,
}) => {
  const hasAllFourValues =
    isSet(odPrism) && isSet(odPrism2) && isSet(osPrism) && isSet(osPrism2);

  if (correctionType !== MULTIFOCAL || !hasAllFourValues) return null;

  const sum = parseFloat(<string>odPrism)
    + parseFloat(<string>odPrism2)
    + parseFloat(<string>osPrism)
    + parseFloat(<string>osPrism2);

  const ok = sum <= 5.00;

  return ok
    ? null
    : 'Sum of all 4 diopters falls outside acceptable range (cannot exceed 5.00)';
};

export const multifocalDioptersSumGreaterThanZero: ValidatorFunction = ({
  correctionType,
  odPrism,
  odPrism2,
  osPrism,
  osPrism2,
}) => {
  const hasAllFourValues =
    isSet(odPrism) && isSet(odPrism2) && isSet(osPrism) && isSet(osPrism2);

  if (correctionType !== MULTIFOCAL || !hasAllFourValues) return null;

  const sum = parseFloat(<string>odPrism)
    + parseFloat(<string>odPrism2)
    + parseFloat(<string>osPrism)
    + parseFloat(<string>osPrism2);

  const ok = sum !== 0;

  return ok ? null : 'Sum of all 4 prism diopters must be greater than zero';
};

export const singleVisionDioptersSumGreaterThanZero: ValidatorFunction = ({
  correctionType,
  odPrism,
  odPrism2,
  osPrism,
  osPrism2,
}) => {
  const hasAllFourValues =
    isSet(odPrism) && isSet(odPrism2) && isSet(osPrism) && isSet(osPrism2);

  if (correctionType !== SINGLE_VISION || !hasAllFourValues) return null;

  const sum = parseFloat(<string>odPrism)
    + parseFloat(<string>odPrism2)
    + parseFloat(<string>osPrism)
    + parseFloat(<string>osPrism2);

  const ok = sum !== 0;

  return ok ? null : 'Sum of all 4 prism diopters must be greater than zero';
};

export const singleVisionDioptersSumLessThanSix: ValidatorFunction = ({
  correctionType,
  odPrism,
  odPrism2,
  osPrism,
  osPrism2,
}) => {
  const hasAllFourValues =
    isSet(odPrism) && isSet(odPrism2) && isSet(osPrism) && isSet(osPrism2);

  if (correctionType !== SINGLE_VISION || !hasAllFourValues) return null;

  const sum = parseFloat(<string>odPrism)
    + parseFloat(<string>odPrism2)
    + parseFloat(<string>osPrism)
    + parseFloat(<string>osPrism2);

  const ok = sum <= 6.00;

  return ok
    ? null
    : 'Sum of all 4 diopters falls outside acceptable range (cannot exceed 6.00)';
};

export const baseInOut: ValidatorFunction = ({
  odPrismBase,
  osPrismBase,
  odPrismBase2,
  osPrismBase2,
}) => {
  const directionFails = ['BIBO', 'BOBI'];

  const odDirection = `${odPrismBase || ''}${odPrismBase2 || ''}`;
  const odOk = !directionFails.includes(odDirection.toUpperCase());

  if (!odOk) return 'OD prism bases can not use BI and BO together';

  const osDirection = `${osPrismBase || ''}${osPrismBase2 || ''}`;
  const osOk = !directionFails.includes(osDirection.toUpperCase());

  if (!osOk) return 'OS prism bases can not use BI and BO together';

  return null;
};

export const baseUpDown: ValidatorFunction = ({
  odPrismBase,
  osPrismBase,
  odPrismBase2,
  osPrismBase2,
}) => {
  const directionFails = ['BUBD', 'BDBU'];

  const odDirection = `${odPrismBase || ''}${odPrismBase2 || ''}`;
  const odOk = !directionFails.includes(odDirection.toUpperCase());

  if (!odOk) return 'OD prism bases can not use BU and BD together';

  const osDirection = `${osPrismBase || ''}${osPrismBase2 || ''}`;
  const osOk = !directionFails.includes(osDirection.toUpperCase());

  if (!osOk) return 'OS prism bases can not use BU and BD together';

  return null;
};

export const baseSameBothEyes: ValidatorFunction = ({
  odPrismBase,
  osPrismBase,
  odPrismBase2,
  osPrismBase2,
}) => {
  const doubleDirectionFail = ['BIBI', 'BUBU', 'BOBO', 'BDBD'];

  const odBases = `${odPrismBase || ''}${odPrismBase2 || ''}`;
  const odOk = !doubleDirectionFail.includes(odBases.toUpperCase());

  if (!odOk) return 'OD prism can not use the same value for Base 1 and Base 2';

  const osBases = `${osPrismBase || ''}${osPrismBase2 || ''}`;
  const osOk = !doubleDirectionFail.includes(osBases.toUpperCase());

  if (!osOk) return 'OS prism can not use the same value for Base 1 and Base 2';

  return null;
};

export const pdVariability: ValidatorFunction = ({ odPd, osPd }) => {
  if (!odPd || !osPd) return null;

  const od = parseFloat(odPd);
  const os = parseFloat(osPd);

  return Math.abs(od - os) > 3
    ? 'PD measurements vary by more than 3mm'
    : null;
};

export const sphOpposing: ValidatorFunction = ({ odSphere, osSphere }) => {
  if (!odSphere || !osSphere) {
    return null;
  }

  const eitherZero = Math.sign(parseFloat(odSphere)) === 0 || Math.sign(parseFloat(osSphere)) === 0;

  if (eitherZero) return null;

  return Math.sign(parseFloat(odSphere)) !== Math.sign(parseFloat(osSphere))
    ? 'SPHs have opposing positive/negative values'
    : null;
};

export const sphVariability: ValidatorFunction = ({ odSphere, osSphere }) => {
  if (!odSphere || !osSphere) {
    return null;
  }

  const sphDifference = parseFloat(odSphere) - parseFloat(osSphere);

  return Math.abs(sphDifference) > 2
    ? 'SPH measurements vary by more than 2 diopters'
    : null;
};

export const cylVariability: ValidatorFunction = ({ odCylinder, osCylinder }) => {
  if (!odCylinder || !osCylinder) return null;

  const cylDifference = parseFloat(odCylinder) - parseFloat(osCylinder);

  return Math.abs(cylDifference) > 2
    ? 'CYL measurements vary by more than 2 diopters'
    : null;
};

export const onlyOneAdd: ValidatorFunction = ({ correctionType, odAdd, osAdd }) => {
  if (correctionType !== MULTIFOCAL || (!odAdd && !osAdd)) {
    return null;
  }

  return (odAdd && !osAdd) || (osAdd && !odAdd)
    ? 'You have just one ADD measurement present'
    : null;
};

export const matchingAdd: ValidatorFunction = ({ correctionType, odAdd, osAdd }) => {
  if (correctionType !== MULTIFOCAL || (!odAdd && !osAdd)) {
    return null;
  }

  return odAdd !== osAdd
    ? 'ADD measurements are not matching'
    : null;
};

export const expirationDateIsNotInThePast: ValidatorFunction = ({ expirationDate }) => {
  if (!isSet(expirationDate)) {
    return null;
  }

  const expDate = moment(expirationDate, 'MM/DD/YYYY', true);
  const formatOk = expDate.isValid();

  if (!formatOk) return 'Expiration date must match format MM/DD/YYYY';

  const today = moment().startOf('day');
  const expired = expDate.isBefore(today);

  if (expired) return 'Prescription is expired (the expiration date can not be in the past)';

  return null;
};

export const hasExpirationDate: ValidatorFunction = ({ expirationDate }) => (
  isSet(expirationDate) ? null : 'An expiration date is required'
);

export const sphereMinThreshold: ValidatorFunction = ({ odSphere, osSphere }) => {
  const minCheck = (v: string) => parseFloat(v) >= SPH_MIN_VALUE;

  const odOk = !isSet(odSphere) || minCheck(<string>odSphere);

  if (!odOk) {
    return `OD SPH outside of Warby Parker fulfillment range, should be >= ${SPH_MIN_LABEL}`;
  }

  const osOk = !isSet(osSphere) || minCheck(<string>osSphere);

  if (!osOk) {
    return `OS SPH outside of Warby Parker fulfillment range, should be >= ${SPH_MIN_LABEL}`;
  }

  return null;
};

export const sphereMaxThreshold: ValidatorFunction = ({ odSphere, osSphere, features }) => {
  const maxValue = features && features.includes(EXPANDED_RX_FEATURE_FLAG)
    ? SPH_MAX_VALUE
    : SPH_MAX_OLD_VALUE;

  const maxLabel = features && features.includes(EXPANDED_RX_FEATURE_FLAG)
    ? SPH_MAX_LABEL
    : SPH_MAX_OLD_LABEL;

  const maxCheck = (v: string) => parseFloat(v) <= maxValue;

  const odOk = !isSet(odSphere) || maxCheck(<string>odSphere);

  if (!odOk) {
    return `OD SPH outside of Warby Parker fulfillment range, should be <= ${maxLabel}`;
  }

  const osOk = !isSet(osSphere) || maxCheck(<string>osSphere);

  if (!osOk) {
    return `OS SPH outside of Warby Parker fulfillment range, should be <= ${maxLabel}`;
  }

  return null;
};

export const contactsBrandPresent: ValidatorFunction = ({
  category,
  odContactProduct,
  osContactProduct,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  return (odContactProduct || osContactProduct)
    ? null
    : ErrorText.OdOsContactBrandAreMissing;
};

export const contactsDiameterPresent: ValidatorFunction = ({
  category,
  odContactDiameter,
  osContactDiameter,
  odContactProduct,
  osContactProduct,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odMissingDiameter = isSet(odContactProduct) && !isSet(odContactDiameter);
  const osMissingDiameter = isSet(osContactProduct) && !isSet(osContactDiameter);

  if (odMissingDiameter && osMissingDiameter) {
    return ErrorText.OdOsDiameterAreMissing;
  }

  if (odMissingDiameter) {
    return ErrorText.OdDiameterIsMissing;
  }

  if (osMissingDiameter) {
    return ErrorText.OsDiameterIsMissing;
  }

  return null;
};


export const contactsBaseCurvePresent: ValidatorFunction = ({
  category,
  odContactBaseCurve,
  osContactBaseCurve,
  odContactProduct,
  osContactProduct,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odMissingBaseCurve = isSet(odContactProduct) && !isSet(odContactBaseCurve);
  const osMissingBaseCurve = isSet(osContactProduct) && !isSet(osContactBaseCurve);

  if (odMissingBaseCurve && osMissingBaseCurve) {
    return ErrorText.OdOsBaseCurveAreMissing;
  }

  if (odMissingBaseCurve) {
    return ErrorText.OdBaseCurveIsMissing;
  }

  if (osMissingBaseCurve) {
    return ErrorText.OsBaseCurveIsMissing;
  }

  return null;
};

export const cylinderRequiredIfManyVariants: ValidatorFunction = ({
  odCylinder,
  osCylinder,
  rxVariants,
  category,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odHasMoreThanOneCylVariant = rxVariants && rxVariants.od?.cylinder?.length > 1;
  const osHasMoreThanOneCylVariant = rxVariants && rxVariants.os?.cylinder?.length > 1;
  return (odHasMoreThanOneCylVariant && !odCylinder) || (osHasMoreThanOneCylVariant && !osCylinder)
    ? 'Must have CYL measurements for OD/OS'
    : null;
};

export const axisRequiredIfManyVariants: ValidatorFunction = ({
  odAxis,
  osAxis,
  rxVariants,
  category,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odHasMoreThanOneAxisVariant = rxVariants && rxVariants.od?.axis?.length > 1;
  const osHasMoreThanOneAxisVariant = rxVariants && rxVariants.os?.axis?.length > 1;
  return (odHasMoreThanOneAxisVariant && !odAxis) || (osHasMoreThanOneAxisVariant && !osAxis)
    ? 'Must have Axis measurements for OD/OS'
    : null;
};

export const addPowerRequiredIfManyVariants: ValidatorFunction = ({
  odAdd,
  osAdd,
  rxVariants,
  category,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odHasMoreThanOneAddPowerVariant = rxVariants && rxVariants.od?.addPower?.length > 1;
  const osHasMoreThanOneAddPowerVariant = rxVariants && rxVariants.os?.addPower?.length > 1;
  return (odHasMoreThanOneAddPowerVariant && !odAdd) || (osHasMoreThanOneAddPowerVariant && !osAdd)
    ? 'Must have ADD measurements for OD/OS'
    : null;
};

export const colorRequiredIfManyVariants: ValidatorFunction = ({
  odContactColor,
  osContactColor,
  rxVariants,
  category,
}) => {
  if (category !== CONTACTS) {
    return null;
  }

  const odHasMoreThanOneColorVariant = rxVariants && rxVariants.od?.color?.length > 1;
  const osHasMoreThanOneColorVariant = rxVariants && rxVariants.os?.color?.length > 1;
  return (odHasMoreThanOneColorVariant && !odContactColor) || (osHasMoreThanOneColorVariant && !osContactColor)
    ? 'Must have color selected for OD/OS'
    : null;
};

export const dnRequiredIfManyVariants: ValidatorFunction = ({
  odContactDn,
  osContactDn,
  rxVariants,
  category,
  features,
}) => {
  if (features && !features.includes(MULTIFOCAL_CONTACTS_FEATURE_FLAG)) {
    return null;
  }

  if (category !== CONTACTS) {
    return null;
  }

  const odHasMoreThanOneDnVariant = rxVariants && rxVariants.od?.dn?.length > 1;
  const osHasMoreThanOneDnVariant = rxVariants && rxVariants.os?.dn?.length > 1;
  return (odHasMoreThanOneDnVariant && !odContactDn) || (osHasMoreThanOneDnVariant && !osContactDn)
    ? 'Missing any DN value'
    : null;
};
