import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { AppState } from './redux/types';

/**
 * This is shorthand for useSelector<RootState>(...);
 * Use this instead of useSelector() to get automatic type inference.
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default {
  useAppSelector,
};
