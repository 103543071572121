import { ERROR } from './index';
import { ValidationMessage, RxToValidate, ValidationResult, Rule } from './types';

const reportInfraction = (validation: string, level: string = ERROR): ValidationMessage => ({
  type: level,
  msg: validation,
});

/*
  Accepts any number of validators (functions that act on data)
  and accumulates the results
*/
export default function createValidator(...validators: Rule[]) {
  return {
    validate(data: Partial<RxToValidate>): ValidationResult {
      return validators.reduce((acc: ValidationResult, [level, validator]) => {
        if (!level) return acc;
        const validation: string | null = validator(data);
        return validation
          ? {
            isValid: level === ERROR ? false : acc.isValid,
            messages: [...acc.messages, reportInfraction(validation, level)],
          }
          : acc;
      }, { isValid: true, messages: [] });
    },
  };
}
