import {
  ADD_ANOTHER_RX,
  CLEAR_SESSION,
  SELECT_CONTACTS_BRAND,
  SELECT_CONTACTS_PRODUCTLINE,
  SELECT_PATIENT,
  SHOW_PRISMS,
  TOGGLE_CORRECTION_TYPE,
  TOGGLE_DISCARD_MODAL,
  TOGGLE_DOUBLE_CHECK_MODAL,
  TOGGLE_EXPIRATION,
  TOGGLE_CONFIRMATION_MODAL,
  TOGGLE_PRESCRIPTION_TYPE,
  TOGGLE_PRESCRIPTION_USAGE,
  TOGGLE_PROGRESSIVE_RANGES,
  UPDATE_ADD,
  UPDATE_AXIS,
  UPDATE_BASE_CURVE,
  UPDATE_COLOR,
  UPDATE_CYLINDER,
  UPDATE_DIAMETER,
  UPDATE_NOTES,
  UPDATE_PRISMS,
  UPDATE_SPHERE,
  UPDATE_PD,
  TOGGLE_INPUT_EXPIRATION,
  UPDATE_EXPIRATION_INPUT,
  UPDATE_DN,
} from '../action-types';

import { underscore } from '../utils/actions.utils';

export function selectPatient(payload) {
  return function action(dispatch) {
    dispatch({
      type: SELECT_PATIENT,
      payload,
    });
  };
}

export function showPrisms() {
  return function action(dispatch) {
    dispatch({
      type: SHOW_PRISMS,
    });
  };
}

export function clearSession() {
  return function action(dispatch) {
    dispatch({
      type: CLEAR_SESSION,
    });
  };
}

export function selectContactsBrand(brand, eye) {
  return function action(dispatch) {
    dispatch({
      type: SELECT_CONTACTS_BRAND,
      payload: { brand, eye },
    });
  };
}

export function selectContactsProductline(productLine, eye) {
  return function action(dispatch) {
    dispatch({
      type: SELECT_CONTACTS_PRODUCTLINE,
      payload: { productLine, eye },
    });
  };
}

export function toggleDiscardModal(payload) {
  return function action(dispatch) {
    dispatch({
      type: TOGGLE_DISCARD_MODAL,
      payload,
    });
  };
}

export function toggleDoubleCheckModal(payload) {
  return function action(dispatch) {
    dispatch({
      type: TOGGLE_DOUBLE_CHECK_MODAL,
      payload,
    });
  };
}

export function updateNotes(payload) {
  return function action(dispatch) {
    dispatch({
      type: UPDATE_NOTES,
      payload,
    });
  };
}

export function addAnotherRx() {
  return function action(dispatch) {
    dispatch({
      type: TOGGLE_CONFIRMATION_MODAL,
      payload: false,
    });
    dispatch({
      type: ADD_ANOTHER_RX,
    });
  };
}

export function toggleInputExpiration() {
  return function action(dispatch) {
    dispatch({
      type: TOGGLE_INPUT_EXPIRATION,
    });
  };
}

export function updateExpirationInput(payload) {
  return function action(dispatch) {
    dispatch({
      type: UPDATE_EXPIRATION_INPUT,
      payload,
    });
  };
}

export function updateRxTypes(payload, type) {
  switch (type) {
  case 'prescriptionType':
    return function action(dispatch) {
      dispatch({
        type: TOGGLE_PRESCRIPTION_TYPE,
        payload,
      });
    };
  case 'distance':
    return function action(dispatch) {
      dispatch({
        type: TOGGLE_PRESCRIPTION_USAGE,
        payload,
      });
    };
  case 'expiration':
    return function action(dispatch) {
      dispatch({
        type: TOGGLE_EXPIRATION,
        payload,
      });
    };
  case 'correctionType':
    return function action(dispatch) {
      dispatch({
        type: TOGGLE_CORRECTION_TYPE,
        payload,
      });
    };
  case 'progressiveRanges':
    return function action(dispatch) {
      dispatch({
        type: TOGGLE_PROGRESSIVE_RANGES,
        payload,
      });
    };
  default:
    return () => {};
  }
}

export function updatePdValues(value, type) {
  return function action(dispatch) {
    dispatch({
      type: UPDATE_PD,
      payload: { val: value, type },
    });
  };
}

export function updateRxValues(value, type) {
  const [eye, valueType] = type.split('-');
  const valType = underscore(valueType.toLowerCase());
  const val = value.toString();
  switch (valType) {
  case 'sphere':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_SPHERE,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'cylinder':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_CYLINDER,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'axis':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_AXIS,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'add_power':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_ADD,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'prism_1':
  case 'prism_2':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_PRISMS,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'base_curve':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_BASE_CURVE,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'diameter':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_DIAMETER,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'color':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_COLOR,
        payload: { val, eye, valueType: valType },
      });
    };
  case 'dn':
    return function action(dispatch) {
      dispatch({
        type: UPDATE_DN,
        payload: { val, eye, valueType: valType },
      });
    };
  default:
    return () => {};
  }
}
