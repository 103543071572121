import React from 'react';
import { prescriptionNotes } from '../../utilities/rxTable';
import Label from '../../shared-components/Label';
import { unCamelCase } from '../../utilities/formatters.util';
import rxTableStyles from '../../assets/styles/rxTable';

export class PrescriptionNotes extends React.PureComponent {
  render() {
    const { connectedUpdateNotes, notes } = this.props;
    return Object.entries(prescriptionNotes).map((set) => {
      const label = set[0];
      const copy = set[1];
      return (
        <div key={label}>
          <Label text={unCamelCase(label)} />
          <input
            defaultValue={notes}
            onChange={e => connectedUpdateNotes(e.target.value)}
            css={rxTableStyles.notes}
            placeholder={copy}
          />
        </div>
      );
    });
  }
}
