import {
  FETCH_DOCTOR_APPOINTMENTS_PENDING,
  FETCH_DOCTOR_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_APPOINTMENTS_FAIL,
  FETCH_APPOINTMENT_TYPES_PENDING,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  FETCH_APPOINTMENT_TYPES_FAIL,
  GET_DOCTOR_UID_PENDING,
  GET_DOCTOR_UID_SUCCESS,
  GET_DOCTOR_UID_FAIL,
  SAVE_RX_PENDING,
  SAVE_RX_SUCCESS,
  SAVE_RX_FAIL,
  SEND_TO_HELIOS_PENDING,
  SEND_TO_HELIOS_SUCCESS,
  SEND_TO_HELIOS_FAIL,
  SEND_EMAIL_TO_PATIENT_PENDING,
  SEND_EMAIL_TO_PATIENT_SUCCESS,
  SEND_EMAIL_TO_PATIENT_FAIL,
  UPDATE_DATE,
  SAVE_READING_SUCCESS,
  SAVE_READING_FAIL,
  GENERATE_RX_PAD_PDF_PENDING,
  GENERATE_RX_PAD_PDF_SUCCESS,
  GENERATE_RX_PAD_PDF_FAIL,
} from '../action-types';

import fetchAndParse from './response-parser';

import { nounderscore } from '../utils/actions.utils';

export function getDoctorAppointments(token, doctorUID, date = 'today') {
  return async function action(dispatch) {
    dispatch({
      type: FETCH_DOCTOR_APPOINTMENTS_PENDING,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/doctors/${doctorUID}/appointments?date=${date}&show_patients`,
        method: 'GET',
        headers: {
          // work around underscore header value bug; ohm accepts "underscore"
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });
      dispatch({
        type: FETCH_DOCTOR_APPOINTMENTS_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: FETCH_DOCTOR_APPOINTMENTS_FAIL,
        payload: error.message,
      });
    }
  };
}

export function getAppointmentTypes(shortName) {
  return async function action(dispatch) {
    dispatch({
      type: FETCH_APPOINTMENT_TYPES_PENDING,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/offices/${shortName}?show_appointment_profiles`,
        method: 'GET',
      });
      dispatch({
        type: FETCH_APPOINTMENT_TYPES_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: FETCH_APPOINTMENT_TYPES_FAIL,
        payload: error.message,
      });
    }
  };
}

export function getDoctorUID(userID, token) {
  return async function action(dispatch) {
    dispatch({
      type: GET_DOCTOR_UID_PENDING,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/doctors?helios_user=${userID}&id_only`,
        method: 'GET',
        headers: {
          // work around underscore header value bug; pmp accepts "underscore"
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });
      dispatch({
        type: GET_DOCTOR_UID_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: GET_DOCTOR_UID_FAIL,
        payload: error.message,
      });
    }
  };
}

// save topcon reading to patient measurements
export function saveReading(token, doctorUID, appointmentID, measurement) {
  return async function action(dispatch) {
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/doctors/${doctorUID}/appointments/${appointmentID}/exam-measurements`,
        method: 'POST',
        body: measurement,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });
      dispatch({
        type: SAVE_READING_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: SAVE_READING_FAIL,
        payload: error.message,
      });
    }
  };
}

export function savePrescription(saveRx, officeID, token) {
  return async function action(dispatch) {
    const { doctorID, appointmentID, patientID } = saveRx;
    dispatch({
      type: SAVE_RX_PENDING,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/offices/${officeID}/doctors/${doctorID}/patients/${patientID}/appointments/${appointmentID}/prescriptions`,
        method: 'POST',
        body: saveRx.payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });
      dispatch({
        type: SAVE_RX_SUCCESS,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: SAVE_RX_FAIL,
        payload: error.message,
      });
    }
  };
}

export const updateDate = payload => ({
  type: UPDATE_DATE,
  payload,
});

const rxActionToReduxActions = {
  send_to_helios: {
    pending: SEND_TO_HELIOS_PENDING,
    success: SEND_TO_HELIOS_SUCCESS,
    error: SEND_TO_HELIOS_FAIL,
  },
  send_email_to_patient: {
    pending: SEND_EMAIL_TO_PATIENT_PENDING,
    success: SEND_EMAIL_TO_PATIENT_SUCCESS,
    error: SEND_EMAIL_TO_PATIENT_FAIL,
  },
  generate_rx_pad_pdf: {
    pending: GENERATE_RX_PAD_PDF_PENDING,
    success: GENERATE_RX_PAD_PDF_SUCCESS,
    error: GENERATE_RX_PAD_PDF_FAIL,
  },
};

const SEND_TO_HELIOS = 'send_to_helios';
const SEND_EMAIL_TO_PATIENT = 'send_email_to_patient';
const GENERATE_RX_PAD_PDF = 'generate_rx_pad_pdf';

function scheduleRxAction(rxUID, rxAction, token) {
  return async function action(dispatch) {
    dispatch({
      type: rxActionToReduxActions[rxAction].pending,
    });
    try {
      const res = await fetchAndParse({
        url: `${process.env.PMP_API}/prescriptions/${rxUID}/action/${rxAction}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${nounderscore(token)}`,
        },
      });
      dispatch({
        type: rxActionToReduxActions[rxAction].success,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: rxActionToReduxActions[rxAction].error,
        payload: error.message,
      });
    }
  };
}

export function sendRxToHelios(rxUID, token) {
  return scheduleRxAction(rxUID, SEND_TO_HELIOS, token);
}

export function sendEmailToPatient(rxUID, token) {
  return scheduleRxAction(rxUID, SEND_EMAIL_TO_PATIENT, token);
}

export async function printPrescription(rxUID, token) {
  await fetch(`${process.env.PMP_API}/prescriptions/${rxUID}/action/${GENERATE_RX_PAD_PDF}`, {
    method: 'POST',
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/json',
      Authorization: `bearer ${nounderscore(token)}`,
    },
  }).then(response => response.blob())
    .then((blob) => {
      window.open(URL.createObjectURL(blob));
    }).catch((e) => {
      // TODO(matthew.snyder): What's a good way to surface this to the UI
      // if we have no access to `dispatch`?
      // eslint-disable-next-line no-console
      console.log(`Failure: ${e}`);
    });
}
