export const SELECT_PATIENT = 'SELECT_PATIENT';

export const SHOW_PRISMS = 'SHOW_PRISMS';

export const CLEAR_SESSION = 'CLEAR_SESSION';

export const ADD_ANOTHER_RX = 'ADD_ANOTHER_RX';

export const TOGGLE_PRESCRIPTION_TYPE = 'TOGGLE_PRESCRIPTION_TYPE';
export const TOGGLE_CORRECTION_TYPE = 'TOGGLE_CORRECTION_TYPE';
export const TOGGLE_PRESCRIPTION_USAGE = 'TOGGLE_PRESCRIPTION_USAGE';
export const TOGGLE_EXPIRATION = 'TOGGLE_EXPIRATION';
export const TOGGLE_PROGRESSIVE_RANGES = 'TOGGLE_PROGRESSIVE_RANGES';
export const TOGGLE_INPUT_EXPIRATION = 'TOGGLE_INPUT_EXPIRATION';
export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_DISCARD_MODAL = 'TOGGLE_DISCARD_MODAL';
export const TOGGLE_DOUBLE_CHECK_MODAL = 'TOGGLE_DOUBLE_CHECK_MODAL';

export const UPDATE_SPHERE = 'UPDATE_SPHERE';
export const UPDATE_CYLINDER = 'UPDATE_CYLINDER';
export const UPDATE_AXIS = 'UPDATE_AXIS';
export const UPDATE_ADD = 'UPDATE_ADD';
export const UPDATE_PRISMS = 'UPDATE_PRISMS';
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const UPDATE_PD = 'UPDATE_PD';
export const UPDATE_BASE_CURVE = 'UPDATE_BASE_CURVE';
export const UPDATE_DIAMETER = 'UPDATE_DIAMETER';
export const UPDATE_COLOR = 'UPDATE_COLOR';
export const UPDATE_DN = 'UPDATE_DN';
export const UPDATE_DATE = 'UPDATE_DATE';
export const UPDATE_EXPIRATION_INPUT = 'UPDATE_EXPIRATION_INPUT';

export const VERIFY_RX_PENDING = 'VERIFY_RX_PENDING';
export const VERIFY_RX_SUCCESS = 'VERIFY_RX_SUCCESS';
export const VERIFY_RX_FAIL = 'VERIFY_RX_FAIL';
export const VERIFY_RX_API_FAIL = 'VERIFY_RX_API_FAIL';

export const GENERATE_RX_PAD_PDF_PENDING = 'GENERATE_RX_PAD_PDF_PENDING';
export const GENERATE_RX_PAD_PDF_SUCCESS = 'GENERATE_RX_PAD_PDF_SUCCESS';
export const GENERATE_RX_PAD_PDF_FAIL = 'GENERATE_RX_PAD_PDF_FAIL';

export const FETCH_CONTACTS_RX_PENDING = 'FETCH_CONTACTS_RX_PENDING';
export const FETCH_CONTACTS_RX_SUCCESS = 'FETCH_CONTACTS_RX_SUCCESS';
export const FETCH_CONTACTS_RX_FAIL = 'FETCH_CONTACTS_RX_FAIL';

export const FETCH_CONTACTS_PRODUCTS_PENDING =
  'FETCH_CONTACTS_PRODUCTS_PENDING';
export const FETCH_CONTACTS_PRODUCTS_SUCCESS =
  'FETCH_CONTACTS_PRODUCTS_SUCCESS';
export const FETCH_CONTACTS_PRODUCTS_FAIL = 'FETCH_CONTACTS_PRODUCTS_FAIL';

export const FETCH_DOCTOR_APPOINTMENTS_PENDING =
  'FETCH_DOCTOR_APPOINTMENTS_PENDING';
export const FETCH_DOCTOR_APPOINTMENTS_SUCCESS =
  'FETCH_DOCTOR_APPOINTMENTS_SUCCESS';
export const FETCH_DOCTOR_APPOINTMENTS_FAIL = 'FETCH_DOCTOR_APPOINTMENTS_FAIL';

export const FETCH_APPOINTMENT_TYPES_PENDING =
  'FETCH_APPOINTMENT_TYPES_PENDING';
export const FETCH_APPOINTMENT_TYPES_SUCCESS =
  'FETCH_APPOINTMENT_TYPES_SUCCESS';
export const FETCH_APPOINTMENT_TYPES_FAIL = 'FETCH_APPOINTMENT_TYPES_FAIL';

export const FETCH_CONTACTS_DATA_PENDING = 'FETCH_CONTACTS_DATA_PENDING';
export const FETCH_CONTACTS_DATA_SUCCESS = 'FETCH_CONTACTS_DATA_SUCCESS';
export const FETCH_CONTACTS_DATA_FAIL = 'FETCH_CONTACTS_DATA_FAIL';

export const GET_DOCTOR_UID_PENDING = 'GET_DOCTOR_UID_PENDING';
export const GET_DOCTOR_UID_SUCCESS = 'GET_DOCTOR_UID_SUCCESS';
export const GET_DOCTOR_UID_FAIL = 'GET_DOCTOR_UID_FAIL';

export const SELECT_CONTACTS_BRAND = 'SELECT_CONTACTS_BRAND';
export const SELECT_CONTACTS_PRODUCTLINE = 'SELECT_CONTACTS_PRODUCTLINE';

export const SAVE_RX_PENDING = 'SAVE_RX_PENDING';
export const SAVE_RX_SUCCESS = 'SAVE_RX_SUCCESS';
export const SAVE_RX_FAIL = 'SAVE_RX_FAIL';

export const SEND_EMAIL_TO_PATIENT_PENDING = 'SEND_EMAIL_TO_PATIENT_PENDING';
export const SEND_EMAIL_TO_PATIENT_SUCCESS = 'SEND_EMAIL_TO_PATIENT_SUCCESS';
export const SEND_EMAIL_TO_PATIENT_FAIL = 'SEND_EMAIL_TO_PATIENT_FAIL';

export const SEND_TO_HELIOS_PENDING = 'SEND_TO_HELIOS_PENDING';
export const SEND_TO_HELIOS_SUCCESS = 'SEND_TO_HELIOS_SUCCESS';
export const SEND_TO_HELIOS_FAIL = 'SEND_TO_HELIOS_FAIL';

// topcon readings & upload
export const SELECT_ADD_READING = 'SELECT_ADD_READING';
export const FETCH_ALL_READINGS_PENDING = 'FETCH_READING_PENDING';
export const FETCH_ALL_READINGS_SUCCESS = 'FETCH_ALL_READINGS_SUCCESS';
export const FETCH_ALL_READINGS_FAIL = 'FETCH_ALL_READINGS_FAIL';

export const SELECT_READING = 'SELECT_READING';
export const ADD_ANOTHER_READING = 'ADD_ANOTHER_READING';

export const FETCH_READING_SUCCESS = 'FETCH_READING_SUCCESS';
export const FETCH_READING_FAIL = 'FETCH_READING_FAIL';
// TODO(RETAILAPPS-67): Remove after feature launch
export const FETCH_DETAILED_READING_SUCCESS = 'FETCH_DETAILED_READING_SUCCESS';
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';

export const SAVE_READING_SUCCESS = 'SAVE_READING_SUCCESS';
export const SAVE_READING_FAIL = 'SAVE_READING_FAIL';
