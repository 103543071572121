import { BrowserAgent } from '@newrelic/browser-agent/src/loaders/browser-agent';
// @ts-expect-error Import types mislead
import { PageAction } from '@newrelic/browser-agent/src/features/page_action';
// @ts-expect-error Import types mislead
import { JSErrors } from '@newrelic/browser-agent/src/features/jserrors';

let agent: BrowserAgent | null = null;

export type Action = 'info' | 'warn' | 'debug';

interface CustomMessage {
  [key: string]: any;
}

export type Message = string | number | CustomMessage;

const initNewRelic = () => {
  const accountID = process.env.NR_ACCOUNT_ID || '';
  const agentID = process.env.NR_AGENT_ID || '';
  const applicationID = process.env.NR_APP_ID || '';
  const licenseKey = process.env.NR_LICENSE_KEY || '';
  const trustKey = process.env.NR_TRUST_KEY || '';

  const options = {
    init: {
      ssl: true,
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      licenseKey,
      applicationID,
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      sa: 1,
    },
    loader_config: {
      accountID,
      agentID,
      applicationID,
      licenseKey,
      trustKey,
    },
  };

  const features = [PageAction, JSErrors];

  agent = new BrowserAgent({
    ...options,
    features: [...features],
  });

  agent.run();
};

const getNewRelicAgent = () => {
  if (!agent) initNewRelic();

  return globalThis.newrelic as BrowserAgent;
};

export { getNewRelicAgent, initNewRelic };
