import React from 'react';

import { contactsRx } from '../../utilities/rxTypes';
import rxTableStyles from '../../assets/styles/rxTable';
import { contactsHeaders } from '../../utilities/rxTable';

export class Header extends React.PureComponent {
  render() {
    const { rxType } = this.props;
    if (rxType.includes(contactsRx)) {
      return (
        <div css={rxTableStyles.headerContainer}>
          {contactsHeaders.map(header => (
            <h2 key={header} css={rxTableStyles.rxTableHeader}>
              {header}
            </h2>
          ))}
        </div>
      );
    }
    return (
      <div css={rxTableStyles.headerContainer}>
        <h2 css={rxTableStyles.rxTableHeader}>{rxType}</h2>
      </div>
    );
  }
}
