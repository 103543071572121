import {
  CLEAR_SESSION,
  TOGGLE_PRESCRIPTION_TYPE,
  FETCH_CONTACTS_DATA_SUCCESS,
  FETCH_CONTACTS_DATA_PENDING,
  FETCH_CONTACTS_DATA_FAIL,
  FETCH_CONTACTS_RX_PENDING,
  FETCH_CONTACTS_RX_SUCCESS,
  FETCH_CONTACTS_RX_FAIL,
  FETCH_CONTACTS_PRODUCTS_PENDING,
  FETCH_CONTACTS_PRODUCTS_SUCCESS,
  FETCH_CONTACTS_PRODUCTS_FAIL,
  SELECT_CONTACTS_BRAND,
  SELECT_CONTACTS_PRODUCTLINE,
  VERIFY_RX_PENDING,
  VERIFY_RX_SUCCESS,
  VERIFY_RX_FAIL,
  VERIFY_RX_API_FAIL,
  UPDATE_ADD,
  UPDATE_AXIS,
  UPDATE_BASE_CURVE,
  UPDATE_COLOR,
  UPDATE_CYLINDER,
  UPDATE_DIAMETER,
  UPDATE_PRISMS,
  UPDATE_SPHERE,
  UPDATE_PD,
  SAVE_RX_FAIL,
  SEND_TO_HELIOS_FAIL,
  SEND_EMAIL_TO_PATIENT_FAIL,
  TOGGLE_DOUBLE_CHECK_MODAL,
  GENERATE_RX_PAD_PDF_FAIL,
  UPDATE_DN,
} from '../action-types';

import { arrToObj, getPCIDs, groupByBrand } from '../utils/reducer.utils';

import { OD } from '../../utilities/rxTable';

const initialState = {
  error: null,
  contactsData: null,
  contactsDataPending: false,
  pending: false,
  pendingContactsRx: false,
  osPCIDs: null,
  odPCIDs: null,
  osRxVariants: null,
  odRxVariants: null,
  errorRxVerification: null,
  pendingRxVerify: false,
  rxVerified: false,
  errorRxVerificationAPI: null,
};

function ohmReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_PRISMS:
  case UPDATE_BASE_CURVE:
  case UPDATE_DIAMETER:
  case UPDATE_DN:
  case UPDATE_COLOR:
  case UPDATE_SPHERE:
  case UPDATE_CYLINDER:
  case UPDATE_AXIS:
  case UPDATE_ADD:
  case UPDATE_PD:
    return {
      ...state,
      errorRxVerification: null,
      pendingRxVerify: false,
      rxVerified: false,
    };
  case FETCH_CONTACTS_DATA_PENDING:
    return {
      ...state,
      contactsDataPending: true,
    };
  case FETCH_CONTACTS_DATA_SUCCESS:
    return {
      ...state,
      contactsDataPending: false,
      contactsData: groupByBrand(payload),
    };
  case FETCH_CONTACTS_DATA_FAIL:
    return {
      ...state,
      error: payload,
      contactsDataPending: false,
    };

  case FETCH_CONTACTS_PRODUCTS_PENDING:
    return {
      ...state,
      pending: true,
    };
  case FETCH_CONTACTS_PRODUCTS_SUCCESS:
    if (payload.eye === OD) {
      return {
        ...state,
        pending: false,
        odPCIDs: getPCIDs(payload.res),
      };
    }
    return {
      ...state,
      pending: false,
      osPCIDs: getPCIDs(payload.res),
    };
  case FETCH_CONTACTS_PRODUCTS_FAIL:
    return {
      ...state,
      error: payload,
      pending: false,
    };

  case FETCH_CONTACTS_RX_PENDING:
    return {
      ...state,
      pendingContactsRx: true,
    };
  case FETCH_CONTACTS_RX_SUCCESS: {
    const variants = arrToObj(payload.res.filter(arr => arr.length > 0));
    if (payload.eye === OD) {
      return {
        ...state,
        pendingContactsRx: false,
        odRxVariants: variants,
      };
    }
    return {
      ...state,
      pendingContactsRx: false,
      osRxVariants: variants,
    };
  }
  case FETCH_CONTACTS_RX_FAIL:
    return {
      ...state,
      error: payload,
      pendingContactsRx: false,
    };
  case SELECT_CONTACTS_BRAND:
    if (payload.eye === OD) {
      return {
        ...state,
        odPCIDs: null,
        odRxVariants: null,
      };
    }
    return {
      ...state,
      osPCIDs: null,
      osRxVariants: null,
    };
  case SELECT_CONTACTS_PRODUCTLINE:
    if (payload.eye === OD) {
      return {
        ...state,
        odRxVariants: null,
      };
    }
    return {
      ...state,
      osRxVariants: null,
    };
  case VERIFY_RX_PENDING:
    return {
      ...state,
      rxVerified: false,
      pendingRxVerify: true,
      errorRxVerification: null,
    };
  case VERIFY_RX_SUCCESS:
    return {
      ...state,
      pendingRxVerify: false,
      rxVerified: true,
      errorRxVerification: null,
    };
  case VERIFY_RX_FAIL:
    return {
      ...state,
      pendingRxVerify: false,
      rxVerified: false,
      errorRxVerification: payload,
    };
  case VERIFY_RX_API_FAIL:
    return {
      ...state,
      pendingRxVerify: false,
      rxVerified: false,
      errorRxVerification: null,
      errorRxVerificationAPI: payload,
    };

  case SAVE_RX_FAIL:
  case SEND_TO_HELIOS_FAIL:
  case SEND_EMAIL_TO_PATIENT_FAIL:
  case GENERATE_RX_PAD_PDF_FAIL:
    return {
      ...state,
      osPCIDs: null,
      odPCIDs: null,
      osRxVariants: null,
      odRxVariants: null,
    };
  case TOGGLE_DOUBLE_CHECK_MODAL:
    if (payload === false) {
      return {
        ...state,
        rxVerified: payload,
      };
    }
    return { ...state };
  case TOGGLE_PRESCRIPTION_TYPE:
  case CLEAR_SESSION:
    return initialState;
  default:
    return state;
  }
}

export default ohmReducer;
