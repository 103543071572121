import { css } from '@emotion/react';

export const backButton = css`
  background: transparent;
  border-radius: 100px;
  border: 1px solid #e1e5e6;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 0;
`;
