import React from 'react';
import { func, shape, string } from 'prop-types';

import BackButton from './BackButton';
import { DiscardModal } from './DiscardModal';
import { Label } from '../shared-components/Label';

import accountDetailStyles from '../assets/styles/accountDetails';
import useActions from '../hooks/useActions';
import { clearSession, toggleDiscardModal } from '../redux/actions/rxEntry';
import { useAppSelector } from '../hooks';
import { componentStatesSelector } from '../redux/selectors/AppContainerSelectors';

const PatientName = ({ name }) => (
  <div css={accountDetailStyles.setWidthPatient}>
    <Label text="Patient" />
    <div css={accountDetailStyles.pill}>{name}</div>
  </div>
);
PatientName.propTypes = {
  name: string.isRequired,
};

const PatientAccount = ({ name, email }) => (
  <div css={accountDetailStyles.setWidthPatientAccount}>
    <Label text="Email" />
    <div css={accountDetailStyles.disabledPill}>
      <div>{name}</div>
      <span>{email}</span>
    </div>
  </div>
);
PatientAccount.propTypes = {
  name: string.isRequired,
  email: string.isRequired,
};

const toPatientFullName = patient => `${patient.first_name} ${patient.last_name}`;
const toPatientEmail = patient => patient.email;

const AccountDetails = (props) => {
  const { patient } = props;
  const componentStates = useAppSelector(componentStatesSelector);

  const patientFullName = toPatientFullName(patient);
  const patientEmail = toPatientEmail(patient);
  const {
    connectedClearSession,
    connectedToggleDiscardModal,
  } = useActions({
    connectedClearSession: clearSession,
    connectedToggleDiscardModal: toggleDiscardModal,
  });

  return (
    <>
      <div css={accountDetailStyles.accountDetailsContainer}>
        <PatientAccount name={patientFullName} email={patientEmail} />
        <PatientName name={patientFullName} />
      </div>
      {componentStates.showDiscardModal ? (
        <DiscardModal
          connectedToggleDiscardModal={connectedToggleDiscardModal}
          connectedClearSession={connectedClearSession}
        />
      ) : null}
    </>
  );
};

AccountDetails.propTypes = {
  patient: shape({
    first_name: string,
    last_name: string,
    email: string,
  }),
};

export default AccountDetails;
