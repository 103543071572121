import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

const readingCardTable = css`
  width: 95%;
  margin: 0 auto;
  th {
    font-size: 120%;
    vertical-align: top;
    padding: 0.5em;
  }
  .lensometry {
    text-align: left;
  }
  .autorefraction {
    text-align: center;
  }
  .manifest {
    text-align: right;
  }
  td {
    vertical-align: top;
    padding: 0.5em;
  }
`;

const lensometryLabel = 'Lensometry';
const autoRefractionLabel = 'Autorefraction';
const manifestLabel = 'Manifest';

const formatDefaultMeasurements = (reading) => {
  const addPower = reading.add && parseFloat(reading.add) !== 0 ? reading.add : '';
  return `${reading.sphere} ${reading.cylinder} x ${reading.axis} ${addPower}`;
};

const measurementToTabular = (measurement, name) => (
  <div className={name.toLowerCase()}>
    {measurement.od && (
      <div>
        <strong>OD:</strong>
        {` ${formatDefaultMeasurements(measurement.od)} `}
      </div>
    )}
    {measurement.os && (
      <div>
        <strong>OS:</strong>
        {` ${formatDefaultMeasurements(measurement.os)} `}
      </div>
    )}
  </div>
);

const manifestToTabular = (manifest, name) => (
  <div className={name.toLowerCase()}>
    {measurementToTabular(manifest, name)}
    {manifest.pd?.near && (
      <div>
        <strong>PD Near OD:</strong>
        {` ${manifest.pd.near.od} `}
        <strong>OS:</strong>
        {` ${manifest.pd.near.os} `}
        <strong>BI:</strong>
        {` ${manifest.pd.near.bi} `}
        <br />
      </div>
    )}
    {manifest.pd?.far && (
      <div>
        <strong>PD Far OD:</strong>
        {` ${manifest.pd.far.od} `}
        <strong>OS:</strong>
        {` ${manifest.pd.far.os} `}
        <strong>BI:</strong>
        {` ${manifest.pd.far.bi} `}
        <br />
      </div>
    )}
  </div>
);


const ReadingData = ({ data }) => {
  const [headers, setHeaders] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data.lensmeter) {
      setHeaders(h => [...h, lensometryLabel]);
      setColumns(c => [...c, measurementToTabular(data.lensmeter, lensometryLabel)]);
    }
    if (data.autorefraction) {
      setHeaders(h => [...h, autoRefractionLabel]);
      setColumns(c => [...c, measurementToTabular(data.autorefraction, autoRefractionLabel)]);
    }
    if (data.manifest) {
      setHeaders(h => [...h, manifestLabel]);
      setColumns(c => [...c, manifestToTabular(data.manifest, manifestLabel)]);
    }
  }, []);

  return (
    <table css={readingCardTable}>
      <thead>
        <tr>
          {headers.map(th => <th className={th.toLowerCase()} key={th}>{th}</th>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          {columns.map((c, i) => <td key={i}>{c}</td>)}
        </tr>
      </tbody>
    </table>
  );
};

export default ReadingData;
