import { PropTypes } from 'prop-types';
import React from 'react';
import { createPortal } from 'react-dom';
import discardModalStyles from '../assets/styles/discardModal';
import PillButton from '../shared-components/PillButton';
import RedButton from '../shared-components/RedButton';
import {
  cancelCopy,
  discardModalCopy,
  discardModalHeader,
  discardRxCopy,
} from '../utilities/discardModal';
import toModalRoot from '../utilities/toModalRoot';

export const InlineDiscardModal = ({
  connectedToggleDiscardModal,
  connectedClearSession,
}) => {
  const DiscardRxButton = () => (
    <RedButton text={discardRxCopy} onClick={connectedClearSession} />
  );
  const CancelButton = () => (
    <PillButton
      text={cancelCopy}
      onClick={() => connectedToggleDiscardModal(false)}
    />
  );

  return (
    <div css={discardModalStyles.modalContainer}>
      <div css={discardModalStyles.modal}>
        <h1>{discardModalHeader}</h1>
        <p>{discardModalCopy}</p>
        <div css={discardModalStyles.buttonContainer}>
          <div css={discardModalStyles.widthSetter}>
            <CancelButton />
            <DiscardRxButton />
          </div>
        </div>
      </div>
    </div>
  );
};

InlineDiscardModal.propTypes = {
  connectedToggleDiscardModal: PropTypes.func,
  connectedClearSession: PropTypes.func,
};

export const DiscardModal = props =>
  createPortal(<InlineDiscardModal {...props} />, toModalRoot());

export default DiscardModal;
