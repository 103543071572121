import { createSelector } from 'reselect';
import { addYears, format } from 'date-fns';
import pmp from './PmpSelectors';
import ohm from './OhmSelectors';
import rxEntry from './RxEntrySelectors';
import { clean, convertPrismBase, stripRxString } from '../../utilities/createRx';
import { underscore } from '../utils/actions.utils';
import { SINGLE_VISION } from '../../middleware/validation/constants';


export const pendingSaveRxSelector = createSelector(
  [pmp.pmpStateSelector, ohm.ohmStateSelector],
  (pmp, ohm) => pmp.sendingRxToHelios || pmp.sendingRxEmailToPatient || pmp.savingRx || ohm.pendingRxVerify,
);

export const rxToVerifySelector = createSelector(
  [rxEntry.rxEntryStateSelector, pmp.currentParsedDateSelector],
  (rxEntry, date) => {
    const odADD = rxEntry.rx_od.add_power;
    const osADD = rxEntry.rx_os.add_power;
    const strippedExpiration = rxEntry.expiration.replace(/years|year/gi, '');
    const examDate = format(date, 'MM/dd/yyyy');
    const expirationDate = rxEntry.useInputExpiration
      ? format(
        new Date(`${rxEntry.inputExpiration}T00:00:00`),
        'MM/dd/yyyy',
      )
      : format(addYears(date, strippedExpiration), 'MM/dd/yyyy');

    const payload = {
      expiration_date: expirationDate,
      exam_date: examDate,
      category: stripRxString(rxEntry.prescriptionType),
      correction_type: rxEntry.correctionType
        ? underscore(rxEntry.correctionType.toLowerCase())
        : SINGLE_VISION,
      od_pd: rxEntry.pd.od || null,
      os_pd: rxEntry.pd.os || null,
      bi_pd: rxEntry.pd.bi || null,
      od_sphere: rxEntry.rx_od.sphere,
      od_add: odADD === 0 ? null : odADD,
      od_cylinder: rxEntry.rx_od.cylinder,
      od_axis: rxEntry.rx_od.axis,
      od_prism_value: rxEntry.rx_od.prism_1 && rxEntry.rx_od.prism_1.prism,
      od_prism_direction: rxEntry.rx_od.prism_1 && convertPrismBase(rxEntry.rx_od.prism_1.base, true),
      od_prism_co_value: rxEntry.rx_od.prism_2 && rxEntry.rx_od.prism_2.prism,
      od_prism_co_direction: rxEntry.rx_od.prism_2 && convertPrismBase(rxEntry.rx_od.prism_2.base, true),
      od_contact_diameter: rxEntry.rx_od.diameter,
      od_contact_base_curve: rxEntry.rx_od.base_curve,
      od_contact_product: rxEntry.rx_od.contacts.brand_name,
      od_contact_color: rxEntry.rx_od.color,
      od_contact_dn: rxEntry.rx_od.contacts.dn,
      os_sphere: rxEntry.rx_os.sphere,
      os_add: osADD === 0 ? null : osADD,
      os_cylinder: rxEntry.rx_os.cylinder,
      os_axis: rxEntry.rx_os.axis,
      os_prism_value: rxEntry.rx_os.prism_1 && rxEntry.rx_os.prism_1.prism,
      os_prism_direction: rxEntry.rx_os.prism_1 && convertPrismBase(rxEntry.rx_os.prism_1.base, true),
      os_prism_co_value: rxEntry.rx_os.prism_2 && rxEntry.rx_os.prism_2.prism,
      os_prism_co_direction: rxEntry.rx_os.prism_2 && convertPrismBase(rxEntry.rx_os.prism_2.base, true),
      os_contact_diameter: rxEntry.rx_os.diameter,
      os_contact_base_curve: rxEntry.rx_os.base_curve,
      os_contact_product: rxEntry.rx_os.contacts.brand_name,
      os_contact_color: rxEntry.rx_os.color,
      os_contact_dn: rxEntry.rx_os.contacts.dn,
    };

    return clean(payload);
  },
);
