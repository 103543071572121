import {
  FETCH_ALL_READINGS_PENDING,
  FETCH_ALL_READINGS_SUCCESS,
  FETCH_ALL_READINGS_FAIL,
  FETCH_READING_SUCCESS,
  FETCH_READING_FAIL,
  SAVE_READING_SUCCESS,
  SAVE_READING_FAIL,
  SELECT_ADD_READING,
  CLEAR_SESSION,
  ADD_ANOTHER_READING,
  FETCH_DETAILED_READING_SUCCESS,
  SET_SELECTED_CARD,
} from '../action-types';

const LoadReadingsStatus = Object.freeze({
  Unknown: 0,
  Pending: 1,
  Success: 2,
  Failure: 3,
});

const initialState = {
  patient: null,
  readings: null,
  // TODO(RETAILAPPS-67): Remove singular measurement after feature launch
  measurement: null,
  measurements: {},
  selected: '',
  error: null,
  pending: false,
  saveReadingSuccess: false,
  loadReadingsStatus: LoadReadingsStatus.Unknown,
};

function topconReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
  // add patient info when user selects to upload reading
  case SELECT_ADD_READING:
    return {
      ...state,
      patient: payload,
    };
  case FETCH_ALL_READINGS_PENDING:
    return {
      ...state,
      pending: true,
    };
  case FETCH_ALL_READINGS_SUCCESS:
    return {
      ...state,
      loadReadingsStatus: LoadReadingsStatus.Success,
      readings: payload,
    };
  case FETCH_ALL_READINGS_FAIL:
    return {
      ...state,
      loadReadingsStatus: LoadReadingsStatus.Failure,
      error: payload,
    };
  case FETCH_READING_SUCCESS:
    return {
      ...state,
      measurement: payload.measurement,
      selected: payload.measurementName,
    };
  case FETCH_DETAILED_READING_SUCCESS:
    return {
      ...state,
      measurements: { ...state.measurements, [payload.measurementName]: payload.measurement },
    };
  case SET_SELECTED_CARD:
    return {
      ...state,
      selected: payload,
    };
  case FETCH_READING_FAIL:
    return {
      ...state,
      error: payload,
    };
  case SAVE_READING_SUCCESS:
    return {
      ...state,
      measurement: payload.measurement,
      saveReadingSuccess: true,
    };
  case SAVE_READING_FAIL:
    return {
      ...state,
      error: payload,
    };
  case ADD_ANOTHER_READING:
    return {
      ...state,
      measurement: null,
      selected: '',
      saveReadingSuccess: false,
    };
  case CLEAR_SESSION:
    return initialState;
  default:
    return state;
  }
}

export default topconReducer;
export { LoadReadingsStatus };
