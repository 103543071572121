import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  buttonContainer,
  expirationCheckbox,
  expirationCheckboxContainer,
  expirationInput,
  expirationInputContainer,
  rxTypeContainer,
  rxTypeDiv,
} from '../assets/styles/rxType';
import { useAppSelector } from '../hooks';
import useActions from '../hooks/useActions';
import {
  toggleInputExpiration,
  updateExpirationInput,
  updateRxTypes,
} from '../redux/actions/rxEntry';
import { componentStatesSelector } from '../redux/selectors/AppContainerSelectors';
import rxEntry from '../redux/selectors/RxEntrySelectors';
import { ROUTE_ROOT } from '../routes';
import Card from '../shared-components/Card';
import { Label } from '../shared-components/Label';
import { unCamelCase } from '../utilities/formatters.util';
import {
  contactsRx,
  correctionType,
  expiration,
  getRxUse,
  prescriptionType,
  verifyStringDate,
} from '../utilities/rxTypes';
import AccountDetails from './AccountDetails';
import RxTable from './RxTable/RxTable';

const Row = ({ types, connectedUpdateRxTypes, selectedRxTypes }) =>
  Object.entries(types).map((set) => {
    const label = set[0];
    const options = set[1];
    return (
      <div css={rxTypeDiv} key={`${label}`}>
        <Label text={unCamelCase(label)} />
        <div css={buttonContainer}>
          {options.map((option) => {
            const isSelected = selectedRxTypes[label] === option;
            const isDisabled = !!selectedRxTypes[label] && !isSelected;
            return (
              <Card
                key={option}
                text={option}
                disabled={isDisabled}
                isSelected={isSelected}
                onClick={() => {
                  const selectedOption = isSelected ? null : option;
                  const isExpirationLabel = label === 'expiration';
                  connectedUpdateRxTypes(
                    isExpirationLabel && !selectedOption ? '' : selectedOption,
                    label,
                  );
                }}
              />
            );
          })}
        </div>
      </div>
    );
  });

// Previous implementation to detect safari was a little tough to read.
// This should identify safari with less strain.
const toIsSafari = () => {
  const { userAgent } = navigator;

  const isChromeAgent = userAgent.indexOf('Chrome') > -1;
  const isSafariAgent = userAgent.indexOf('Safari') > -1;

  // If we are both agents, we are Chrome
  if (isChromeAgent && isSafariAgent) return false;

  return isSafariAgent;
};

const ExpirationDateInput = (props) => {
  const { onChange } = props;
  const isSafari = toIsSafari();

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ !!document.documentMode;
  const isSafariOrIE = isSafari || isIE;

  return (
    <div>
      <div css={expirationInputContainer}>
        {/*
          Safari/IE browsers do not have native date picker functionality.
          It acts as a normal text field instead of showing a native date picker.
          After speaking with designs, we decided to add an input label "YYYY-MM-DD"
          as a visual cue for users if they are on Safari/IE.
        */}
        <Label
          text="Expiration Date"
          secondaryText={isSafariOrIE ? 'YYYY-MM-DD' : ''}
        />
        <div>
          <input
            type="date"
            css={expirationInput}
            onChange={(e) => {
              if (
                !isSafariOrIE ||
                (isSafariOrIE && verifyStringDate(e.target.value))
              ) {
                onChange(e.target.value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

ExpirationDateInput.propTypes = {
  onChange: func.isRequired,
};

const RxType = () => {
  const {
    connectedUpdateRxTypes,
    connectedToggleInputExpiration,
    connectedUpdateExpirationInput,
  } = useActions({
    connectedUpdateRxTypes: updateRxTypes,
    connectedToggleInputExpiration: toggleInputExpiration,
    connectedUpdateExpirationInput: updateExpirationInput,
  });

  const selectedRxTypes = useAppSelector(rxEntry.rxTypesSelector);
  const useInputExpiration = useAppSelector(rxEntry.useInputExpirationSelector);
  const patient = useAppSelector(rxEntry.patientSelector);
  const { showRxTable } = useAppSelector(componentStatesSelector);

  const showCorrectionTypes = selectedRxTypes.prescriptionType;
  const showRxUses = showCorrectionTypes && selectedRxTypes.correctionType;
  const isContacts = selectedRxTypes.prescriptionType
    ? selectedRxTypes.prescriptionType.includes(contactsRx)
    : null;

  const showGlassesExp =
    !isContacts &&
    showRxUses &&
    (selectedRxTypes.progressiveRanges || selectedRxTypes.distance);

  const showExpirationCardsForContacts =
    showCorrectionTypes && isContacts && !useInputExpiration;
  const showCorrectionTypesForGlasses = showCorrectionTypes && !isContacts;
  const showGlassesRxUsage = !isContacts && showRxUses;
  const showExpirationInputForContacts = isContacts && useInputExpiration;
  const showExpirationCardsForGlasses = showGlassesExp && !useInputExpiration;
  const showExpirationInputForGlasses = showGlassesExp && useInputExpiration;

  // added to radio input to be controlled; as we already control the value using onClick handler
  const emptyOnChangeHandler = () => {};

  return (
    <div css={rxTypeContainer}>
      <AccountDetails patient={patient} />
      {/* Always show rx types (e.g. contacts vs. glasses) */}
      <Row
        types={prescriptionType}
        connectedUpdateRxTypes={connectedUpdateRxTypes}
        selectedRxTypes={selectedRxTypes}
      />

      {/* if contacts, show expiration else show correction types */}
      {showCorrectionTypesForGlasses && (
        <Row
          types={correctionType}
          connectedUpdateRxTypes={connectedUpdateRxTypes}
          selectedRxTypes={selectedRxTypes}
        />
      )}

      {showExpirationCardsForContacts && (
        <Row
          types={expiration}
          connectedUpdateRxTypes={connectedUpdateRxTypes}
          selectedRxTypes={selectedRxTypes}
        />
      )}

      {showExpirationInputForContacts && (
        <ExpirationDateInput onChange={connectedUpdateExpirationInput} />
      )}

      {isContacts && (
        <div css={expirationCheckboxContainer}>
          <input
            disabled={false}
            css={expirationCheckbox}
            type="radio"
            checked={useInputExpiration}
            onClick={connectedToggleInputExpiration}
            onChange={emptyOnChangeHandler}
          />
          <span>Use expiration date instead</span>
        </div>
      )}

      {/* if glasses, show glasses usage (progressive ranges vs. distance) */}
      {showGlassesRxUsage && (
        <Row
          types={getRxUse(selectedRxTypes.correctionType)}
          connectedUpdateRxTypes={connectedUpdateRxTypes}
          selectedRxTypes={selectedRxTypes}
        />
      )}

      {/* if glasses and usage type selected, show expiration as last options */}
      {showExpirationCardsForGlasses && (
        <Row
          types={expiration}
          connectedUpdateRxTypes={connectedUpdateRxTypes}
          selectedRxTypes={selectedRxTypes}
        />
      )}

      {showExpirationInputForGlasses && (
        <ExpirationDateInput onChange={connectedUpdateExpirationInput} />
      )}

      {showGlassesExp && (
        <div css={expirationCheckboxContainer}>
          <input
            disabled={false}
            css={expirationCheckbox}
            type="radio"
            checked={useInputExpiration}
            onClick={connectedToggleInputExpiration}
            onChange={emptyOnChangeHandler}
          />
          <span>Use expiration date instead</span>
        </div>
      )}

      {showRxTable ? <RxTable /> : null}
    </div>
  );
};

const RxTypeInterim = () => {
  const selectedPatient = useAppSelector(state => state?.rxEntry?.patient);
  const history = useHistory();

  useEffect(() => {
    if (!selectedPatient) {
      history.push(ROUTE_ROOT);
    }

    return () => {};
  }, [history, selectedPatient]);

  return selectedPatient ? <RxType /> : null;
};

export default RxTypeInterim;
