import moment from 'moment';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { noAppointments } from '../assets/styles/appointmentList';
import { button } from '../assets/styles/button';
import { loading } from '../assets/styles/loading';
import rxTableStyles from '../assets/styles/rxTable';
import { measurementContainer, rxTypeContainer } from '../assets/styles/rxType';
import { useAppSelector } from '../hooks';
import useActions from '../hooks/useActions';
import { saveReading } from '../redux/actions/pmp';
import { clearSession } from '../redux/actions/rxEntry';
import {
  addAnotherReading,
  deleteReading,
  getOneReading,
  getTopconReadings,
  setSelected,
} from '../redux/actions/topcon';
import { LoadReadingsStatus } from '../redux/reducers/topcon';
import { ROUTE_ROOT } from '../routes';
import Button from '../shared-components/Button';
import Card from '../shared-components/Card';
import Error from '../shared-components/Error';
import {
  FEATURE_DETAILED_READINGCARDS,
  FEATURE_MEASUREMENT_DATEFILTER,
} from '../utilities/features';
import AccountDetails from './AccountDetails';
import ConfirmationModal from './ConfirmationModal';
import ReadingCard from './ReadingCard';
import ReadingData from './ReadingData';

const CardContents = ({ reading, measurements }) => {
  if (reading?.measurement_name && measurements[reading.measurement_name]) {
    return <ReadingData data={measurements[reading.measurement_name]} />;
  }
  return <p>Loading&hellip;</p>;
};

const readingPropType = shape({
  add: oneOfType([number, string]),
  axis: oneOfType([number, string]),
  sphere: oneOfType([number, string]),
  cylinder: oneOfType([number, string]),
});

const measurementPropType = shape({
  od: readingPropType,
  os: readingPropType,
});

CardContents.propTypes = {
  reading: shape({
    measurement_name: string,
  }),
  measurements: arrayOf(shape({
    lensmeter: measurementPropType,
    autorefraction: measurementPropType,
    manifest: measurementPropType,
  })),
};

const toMeasurementReadings = (readings, features) => {
  if (readings) {
    if (features.includes(FEATURE_MEASUREMENT_DATEFILTER)) {
      // TODO(msnyder): There is probably a better way to do this date fiddling.
      const apptDate = new Date(readings.date);

      // temporary fix to account for difference to UTC that's pulled from timestamp
      apptDate.setHours(5);
      apptDate.setMinutes(0);
      apptDate.setSeconds(0);
      const nextDay = new Date(apptDate);
      nextDay.setDate(nextDay.getDate() + 1);

      return readings.measurements.filter((r) => {
        // this sets the UTC timestamp as local time
        const dt = new Date(r.timestamp);
        return dt >= apptDate && dt < nextDay;
      });
    }

    return readings.measurements;
  }

  return [];
};


const UploadReading = () => {
  const [pending, togglePending] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [refreshPending, setRefreshPending] = useState(false);

  const {
    connectedGetTopconReadings,
    connectedSetSelected,
    connectedGetOneReading,
    connectedSaveReading,
    connectedAddAnotherReading,
    connectedClearSession,
  } = useActions({
    connectedGetTopconReadings: getTopconReadings,
    connectedSetSelected: setSelected,
    connectedGetOneReading: getOneReading,
    connectedSaveReading: saveReading,
    connectedAddAnotherReading: addAnotherReading,
    connectedClearSession: clearSession,
  });

  const readings = useAppSelector(({ topcon }) => topcon.readings);
  const measurement = useAppSelector(({ topcon }) => topcon.measurement);
  const measurements = useAppSelector(({ topcon }) => topcon.measurements);
  const selected = useAppSelector(({ topcon }) => topcon.selected);
  const examRoom = useAppSelector(({ topcon }) => topcon.patient?.examRoom);
  const error = useAppSelector(({ topcon }) => topcon.error);
  const patient = useAppSelector(({ topcon }) => topcon.patient);
  const doctorUID = useAppSelector(({ pmp }) => pmp?.doctorUID);
  const loadReadingsStatus = useAppSelector(({ topcon }) => topcon.loadReadingsStatus);
  const saveReadingSuccess = useAppSelector(({ topcon }) => topcon.saveReadingSuccess);

  const date = useAppSelector(state => state?.pmp.date);
  const token = useAppSelector(state => state.auth?.jwt);
  const shortName = useAppSelector(
    state => state.auth?.me?.facility?.short_name,
  );
  const features = useAppSelector(state => state.auth?.me?.features);

  useEffect(() => {
    if (token && shortName && examRoom && date) {
      setShowSpinner(true);
      setRefreshPending(true);
      connectedGetTopconReadings(token, shortName, examRoom, date);
    }
  }, [connectedGetTopconReadings, date, examRoom, shortName, token]);

  useEffect(() => {
    if (
      loadReadingsStatus === LoadReadingsStatus.Success ||
      loadReadingsStatus === LoadReadingsStatus.Failure
    ) {
      setShowSpinner(false);
    }
  }, [loadReadingsStatus]);

  // if reading was successfully uploaded
  useEffect(() => {
    if (saveReadingSuccess) {
      // delete that reading
      deleteReading(token, shortName, examRoom, selected);
      togglePending(false);

      // refetch listings
      setRefreshPending(true);
      connectedGetTopconReadings(token, shortName, examRoom, date);
    }
  }, [connectedGetTopconReadings, date, examRoom, saveReadingSuccess, selected, shortName, token]);

  useEffect(() => {
    setRefreshPending(false);
  }, [readings]);

  if (error) {
    return <Error error={error} />;
  }

  if (showSpinner) {
    return (
      <div css={loading}>
        <img
          alt="spinner"
          src="https://www.warbyparker.com/assets/img/icons/actions/spinner.png"
        />
        <p>Loading&hellip;</p>
      </div>
    );
  }
  if (loadReadingsStatus === LoadReadingsStatus.Success && !readings) {
    return (
      <>
        <div css={noAppointments}>
          <p>No available readings</p>
        </div>
      </>
    );
  }

  const onSelect = (measurementName) => {
    if (features.includes(FEATURE_DETAILED_READINGCARDS)) {
      connectedSetSelected(selected === measurementName ? '' : measurementName);
    } else {
      connectedGetOneReading(
        token,
        shortName,
        examRoom,
        measurementName,
        features,
      );
    }
  };

  const submitMeasurement = () => {
    togglePending(true);
    const m = features.includes(FEATURE_DETAILED_READINGCARDS)
      ? measurements[selected]
      : measurement;
    connectedSaveReading(token, doctorUID, patient.appointmentID, m);
  };

  const getCard = (reading, i) => {
    if (features.includes(FEATURE_DETAILED_READINGCARDS)) {
      return (
        <ReadingCard
          key={`${i}`}
          measurementName={reading.measurement_name}
          shortName={shortName}
          examRoom={examRoom}
          token={token}
          text={moment
            .utc(reading.timestamp)
            .local()
            .format('dddd, MMMM Do, h:mm:ss A')}
          isDisabled={selected !== reading.measurement_name}
          isSelected={selected === reading.measurement_name}
          onClick={() => onSelect(reading.measurement_name)}
          contents={<CardContents reading={reading} measurements={measurements} />}
          features={features}
        />
      );
    }
    return (
      <Card
        key={`${i}`}
        text={moment
          .utc(reading.timestamp)
          .local()
          .format('dddd, MMMM Do, h:mm:ss A')}
        isDisabled={selected !== reading.measurement_name}
        isSelected={selected === reading.measurement_name}
        onClick={() => onSelect(reading.measurement_name)}
      />
    );
  };

  const refreshReadings = () => {
    if (shortName) {
      setRefreshPending(true);
      connectedGetTopconReadings(token, shortName, examRoom);
    }
  };

  const measurementReadings = toMeasurementReadings(readings, features);

  return (
    <div css={rxTypeContainer}>
      <AccountDetails patient={patient} />
      <div css={measurementContainer}>
        {measurementReadings.map((reading, i) => getCard(reading, i))}
      </div>
      <div css={rxTableStyles.buttonContainer}>
        <Button
          css={[button, rxTableStyles.readingButton]}
          onClick={() => refreshReadings()}
          disabled={refreshPending}
          pending={refreshPending}
        >
          Refresh Readings
        </Button>
        <Button
          css={[button, rxTableStyles.readingButton]}
          onClick={() => submitMeasurement()}
          disabled={!selected}
          pending={pending}
        >
          Submit File
        </Button>
      </div>
      {saveReadingSuccess && (
        <ConfirmationModal
          connectedAddAnother={connectedAddAnotherReading}
          connectedClearSession={connectedClearSession}
          patientName={patient.first_name}
        />
      )}
    </div>
  );
};

const UploadReadingInterim = (props) => {
  const selectedPatient = useAppSelector(state => state?.topcon?.patient);
  const history = useHistory();

  useEffect(() => {
    if (!selectedPatient) {
      history.push(ROUTE_ROOT);
    }

    return () => {};
  }, [history, selectedPatient]);

  return selectedPatient ? <UploadReading {...props} /> : null;
};

export default UploadReadingInterim;
